// WorkOrdeRepo -작업지시서 출력
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function WorkOrdeRepo(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/report/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/report/detail/search`;  // 서브 조회 api
    const mainReportApi = `${acce}/${gubu}/${cata}/${catb}/report/creat/pdf`;      // 보고 출력 api  
    // 시스템
    const [loading, setLoading] = useState(true);    
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'work_numb';
    const subsKey = ['work_numb', 'item_code'];        
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb, clieName: clieName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, workNumb, clieName, mainSearchApi, mainGridDataUpdate, mainGridRef, subsGridRef]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { workNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsSearchApi, subsGridDataUpdate, subsGridRef]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const onReportClick = useCallback(async() => {
        const seleKey = fwUtil.grid.get.mKey(mainGridRef);
        if (!seleKey) {
            fwUtil.aler.toast.nsWarn();
            return;
        }
        try {
            setLoading(true);
            const sendData = { indeKeys: seleKey };
            const url = await fwUtil.data.report(mainReportApi, sendData);
            if (!url) {
                return;
            }
            window.open(url, '_blank');
        } catch (error) {
            console.log('reportData.error === ', error);
            fwUtil.aler.toast.s1Erro();
        } finally {
            setLoading(false);
        }
        return;
    }, [mainReportApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '작업지시번호', value: workNumb, valueChange: workNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
        { type: 'btns', locate: 'after', icon: 'textdocument', text: '작업지시서', onClick: onReportClick, disabled: authList.auth_dnlo === 0 }
    ], [workNumb, clieName, workNumbChange, clieNameChange, mainHandleSearch, onReportClick, authList]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DateCol({ dataField: "issu_date", caption: "발행일" })}
                {BoolCol({ dataField: "work_clos", caption: "생산완료여부" })}
                {DateCol({ dataField: "clos_date", caption: "생산완료일" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "orde_qtys", caption: "지시수량" })}
                {DateCol({ dataField: "star_date", caption: "시작일자" })}
                {DateCol({ dataField: "clos_date", caption: "완료일자" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}
            </SubsGrid>
        </div>
    );
};