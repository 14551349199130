import React from "react";
import { LineGauge } from "../../../../../../script/components/gauge/gauge-item";
import { ValueIndicator, SubvalueIndicator, Tooltip } from "devextreme-react/cjs/linear-gauge";

export const MixeGaugeItem = (props) => {
    const { type, scale, color, value } = props;

    return (
        <div className={`mixer-gauge-item ${type.en}`}>
            <div className="mixer-gauge-detail">
                {value && value.length > 0 && value.map((item, index) => (
                    <div className="minser-gauge-detail-wrapper" key={index} >
                        <LineGauge
                            value={item?.acts}
                            subValue={item?.sets}                            
                            scale={scale}
                        >
                            <ValueIndicator type="rangeBar" color={color} size={25} backgroundColor="#696969de" />
                            <SubvalueIndicator type="textCloud" color={color} />
                            <Tooltip />
                        </LineGauge>
                        <div className="mixer-gauge-value-text">{item?.acts}</div>
                    </div>
                ))}
            </div>
            <div className="mixer-gauge-title">
                {type.kr}
            </div>
        </div>
    )
}