// AserCompRegi - A/S 운영/점검
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { AserCompInsert } from './InfoRegi-insert';
import { AserCompUpdate } from './InfoRegi-update';
import { fwUtil } from '../../../../script/util';

const tempMainData = [
    {
        "mana_numb": "CSAR20240001",
        "clie_code": "CLIE1001",
        "clie_name": "홍길동",
        "item_code": "ITEM0001",
        "item_name": "제품A",
        "mana_date": "2024-06-01",
        "aser_memo": "첫 번째 계약"
    },
    {
        "mana_numb": "CSAR20240002",
        "clie_code": "CLIE1002",
        "clie_name": "김철수",
        "item_code": "ITEM0002",
        "item_name": "제품B",
        "mana_date": "2024-06-02",
        "aser_memo": "두 번째 계약"
    },
    {
        "mana_numb": "CSAR20240003",
        "clie_code": "CLIE1003",
        "clie_name": "이영희",
        "item_code": "ITEM0003",
        "item_name": "제품C",
        "mana_date": "2024-06-03",
        "aser_memo": "세 번째 계약"
    },
];

const tempSubsData = [
    {
        "mana_numb": "CSAR20240001",
        "comp_seri": "01",
        "rece_date": "2024-06-01",
        "insp_date": "2024-06-02",
        "insp_name": "김철수",
        "insp_kwon": 10000.00,
        "matl_kwon": 5000.00,
        "matl_name": "부품A"
    },
    {
        "mana_numb": "CSAR20240001",
        "comp_seri": "02",
        "rece_date": "2024-06-02",
        "insp_date": "2024-06-03",
        "insp_name": "이영희",
        "insp_kwon": 20000.00,
        "matl_kwon": 10000.00,
        "matl_name": "부품B"
    },
    {
        "mana_numb": "CSAR20240001",
        "comp_seri": "03",
        "rece_date": "2024-06-03",
        "insp_date": "2024-06-04",
        "insp_name": "박민수",
        "insp_kwon": 15000.00,
        "matl_kwon": 7500.00,
        "matl_name": "부품C"
    },
    {
        "mana_numb": "CSAR20240002",
        "comp_seri": "01",
        "rece_date": "2024-06-04",
        "insp_date": "2024-06-05",
        "insp_name": "최유리",
        "insp_kwon": 30000.00,
        "matl_kwon": 15000.00,
        "matl_name": "부품D"
    },
    {
        "mana_numb": "CSAR20240003",
        "comp_seri": "01",
        "rece_date": "2024-06-05",
        "insp_date": "2024-06-06",
        "insp_name": "홍길동",
        "insp_kwon": 25000.00,
        "matl_kwon": 12500.00,
        "matl_name": "부품E"
    }
];

export default function AserCompRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/detail/search`;  // 서브 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/detail/insert`;  // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/detail/update`;  // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/detail/delete`;  // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'mana_numb';
    const subsKey = ['mana_numb', 'comp_seri'];
    const deleKey = (data) => {
        return { 
            manaNumb: data.mana_numb, 
            manaSeri: data.comp_seri 
        }
    };       
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { manaNumb: manaNumb, clieName: clieName };
        //await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
        mainGridDataUpdate(tempMainData);
    }, [manaNumb, clieName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { manaNumb: key };
            //await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
            const filteredData = tempSubsData.filter(item => item.mana_numb === key);
            subsGridDataUpdate(filteredData);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.apis.delete(subsDeleteApi, subsGridRef, deleKey, subsHandleSearch);
        }
        deleteAlertHide();
    }, [subsGridRef, subsHandleSearch, deleteAlertHide, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [manaNumb, manaNumbChange, clieName, clieNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const subsHandleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        if (mainSelectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
            if (subsSelectedRowKey) {
                if (act === 'upd') {
                    setUpdatePopupVisible(true);
                    return;
                }
                if (act === 'del') {
                    setDeleteAlertVisible(true);
                    return;
                }                
            } else {
                fwUtil.aler.toast.warn('차량을 선택 후 시도해주세요.');
            }
        } else {
            fwUtil.aler.toast.warn('거래처를 선택 후 시도해주세요.');
        };

    }, [mainGridRef, subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "mana_date", caption: "계약일자" })}
                {CommCol({ dataField: "aser_memo", caption: "비고" })}                                                    
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => subsHandleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => subsHandleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => subsHandleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "comp_seri", caption: "점검순번" })}
                {DateCol({ dataField: "rece_date", caption: "접수일자" })}
                {DateCol({ dataField: "insp_date", caption: "점검일자" })}
                {CommCol({ dataField: "insp_name", caption: "점검자" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "matl_kwon", caption: "부품비" })}
                {CommCol({ dataField: "matl_name", caption: "교체부품" })}
                {CommCol({ dataField: "comp_memo", caption: "비고" })}
            </SubsGrid>         
            <AserCompInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                insertApi={subsInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <AserCompUpdate
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                updateApi={subsUpdateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />                 
        </div>
    );
};