import React from "react";
import { ScrollGrid } from '../../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, NumbCol } from '../../../../../script/components/data-grid/data-grid-column/grid-column';

export default function YardInfoStatPanelGrid(props) {
    const { data, yardName } = props;

    const yardData = data && data.length > 0 ?  data.filter(item => item.stoc_loca === yardName) : [];

    return (
        <div className="yard-panel-grid-container fw-flex-box col">
            <div className={`yard-panel-grid-title yard-${yardName[0]}`}>{yardName}</div>
            <div className={'yard-panel-grid-detail'}>
                <ScrollGrid
                    gridDataSource={yardData}
                    width={'100%'}
                    height={'100%'}
                    alter={false}
                >
                    {CommCol({ dataField: "item_name", caption: "제품명", width: 160 })}
                    {CommCol({ dataField: "item_gubu", caption: "구분" })}
                    {NumbCol({ dataField: "stoc_qtys", caption: "수량" })}
                    {CommCol({ dataField: "stoc_unit", caption: "단위" })}
                    {CommCol({ dataField: "stoc_qpri", caption: "단가" })}
                    {CommCol({ dataField: "stoc_pric", caption: "금액" })}
                </ScrollGrid>                         
            </div>
        </div>
    )
}