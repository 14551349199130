import React, { useCallback } from "react";
import { Button, DateBox, SelectBox, TextBox, Tooltip } from "devextreme-react"
import { fwUtil } from "../../util";

const ToolbarButton = (props) => {
    const {
        icon, text,
        hidden, disabled,
        onClick
    } = props;

    const buttonId = `fw-${text}-button-wrapper`;

    return (
        <div id={buttonId} className={'fw-tooltip-button-wrapper'}>
            <Button
                icon={icon}
                text={text}
                type='normal'
                stylingMode='contained'
                visible={!hidden}
                disabled={disabled}
                onClick={onClick}
                elementAttr={{ title: text }}
            />
            {disabled && (
                <Tooltip
                    target={`#${buttonId}`}
                    position={'top'}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <span>{text} 권한이 없습니다.</span>
                </Tooltip>
            )}
        </div>
    )
};

const SearchButton = (props) => {
    const {
        search
    } = props;

    return (
        <ToolbarButton
            icon="search"
            text="조회"
            onClick={search}

        />
    )
}

const ToolbarTextBox = (props) => {
    const {
        caption, width, value, valueChange, search
    } = props;

    return (
        <TextBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            onEnterKey={search}
        />
    )
}

const ToolbarSelcBox = (props) => {
    const {
        caption, items, width, value, valueChange, search
    } = props;

    return (
        <SelectBox
            placeholder={caption}
            items={items}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            onEnterKey={search}
        />
    )
}

const ToolbarYearBox = (props) => {
    const {
        caption, width, value, valueChange, search
    } = props;

    return (
        <DateBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            stylingMode="outlined"
            displayFormat={'yyyy-MM'}
            dateSerializationFormat={"yyyy-MM"}
            type="date"
            calendarOptions={{
                maxZoomLevel: 'year'
            }}
            onEnterKey={search}
        />
    )
}

const ToolbarSearchBtn = React.memo(function ToolbarSearchBtn({ search }) {
    if (!search) {
        return;
    }

    return (
        <ToolbarButton
            icon="search"
            text="조회"
            onClick={search}
        />
    )
});

const ToolbarExportBtn = React.memo(function ToolbarSearchBtn({ element, fileName, disabled }) {
    const onExportClick = useCallback(() => {
        const targetElement = element?.current;
        if (!targetElement) {
            fwUtil.aler.toast.erro('다운로드에 실패하였습니다.');
            return;
        }
        fwUtil.data.screen(targetElement, 'l', fileName);
    }, [element, fileName]);
    
    if (!element || !fileName) {
        return;
    }

    return (
        <Button
            icon='export'
            text='다운로드'
            onClick={onExportClick}
            disabled={disabled}
        />
    )
});

const ToolbarItem = ({ type, caption, value, valueChange, items, search, icon, text, hidden, disabled, onClick }) => {
    if (type === 'text') {
        return (
            <ToolbarTextBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
            />
        )
    }
    if (type === 'selc') {
        return (
            <ToolbarSelcBox
                caption={caption}
                items={items}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
            />
        )
    }
    if (type === 'btns') {
        return (
            <ToolbarButton
                icon={icon}
                text={text}
                hidden={hidden}
                disabled={disabled}
                onClick={onClick}
            />
        )
    }
    if (type === 'year') {
        return (
            <ToolbarYearBox
                caption={caption}
                value={value}
                valueChange={valueChange}
                onEnterKey={search}
            />
        )
    }
}

export {
    ToolbarItem,
    ToolbarSearchBtn,
    ToolbarExportBtn,
}

export {
    ToolbarButton,
    SearchButton,
    ToolbarTextBox,
    ToolbarSelcBox,
    ToolbarYearBox,
};