import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormSelc } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, SelcCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    insp_damd: '',
    insp_date: fwUtil.date.getsText.date(),
};

const inspDataSource = fwUtil.grid.get.dataSource();

const setQcis = set.mes.qci;

export function ProsQcisChanUpdate(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 수주 그리드
    const inspRef = useRef(null);
    // 선택 데이터
    const [inspDamdList, setInspDamdList] = useState([]);
    const inspDamdChange = useCallback((e) => setInspDamdList(e), []);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    const fetchData = useCallback(async () => {
        try {
            const [damdListResult] = await Promise.all([setQcis.ppro.damd.list()]);
            inspDamdChange(damdListResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [inspDamdChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            inspDataSource.store().insert({ init: true }).then(() => inspDataSource.reload());
            const inspSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const insp of inspSelected) {
                const newData = {
                    insp_numb: insp.insp_numb || '',
                    clie_name: insp.clie_name || '',
                    work_numb: insp.work_numb || '',
                    item_name: insp.item_name || '',
                    rout_name: insp.rout_name || '',
                    insp_qtys: insp.insp_qtys || 0.00,
                    judg_qtys: insp.judg_qtys || 0.00,
                    judg_yesn: insp.judg_yesn || "합격",
                    insp_memo: insp.insp_memo || '',
                };
                inspDataSource.store().insert(newData);
            }
            
            inspDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate, fetchData]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const inspData = inspRef?.current?.instance?.getDataSource()?.items();
        const postInspData = inspData.map((item) => ({
            inspDate: data.insp_date || fwUtil.date.getsText.date(),
            inspDamd: data.insp_damd || '',
            inspNumb: item.insp_numb || '',
            workNumb: item.work_numb || '',
            itemName: item.item_name || '',
            routName: item.rout_name || '',
            inspQtys: item.insp_qtys || 0.00,
            judgQtys: item.judg_qtys || 0.00,
            judgYesn: item.judg_yesn === "합격" ? true : false,
            inspMemo: item.insp_memo || '',            
        }));        
        const postData = {
            setaMaps: postInspData || [],
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        mainGrid?.current?.instance?.deselectAll();
        alertHide();
        hiding();
    }, [data, insertApi, refresh, hiding, alertHide, inspRef, mainGrid]);    

    return (
        <>           
        <PopupForm
            title={'수입검사 변경'}       
            type={'변경'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'검사목록'}
                        gridRef={inspRef} 
                        gridDataSource={inspDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "insp_numb", caption: "검사번호", allowEditing: false })}
                        {CommCol({ dataField: "work_numb", caption: "작업지시번호", allowEditing: false })}
                        {CommCol({ dataField: "item_name", caption: "품목명", allowEditing: false })}
                        {CommCol({ dataField: "rout_name", caption: "공정명", allowEditing: false })}
                        {DeciCol({ dataField: "insp_qtys", caption: "검사수량", check: true, maxLength: 12 })}
                        {DeciCol({ dataField: "judg_qtys", caption: "불량수량", check: true, maxLength: 12 })}
                        {SelcCol({ dataField: "judg_yesn", caption: "합격여부", items: ["합격", "불합격"], allowFiltering: false, allowSorting: false, check: true })}
                        {CommCol({ dataField: "insp_memo", caption: "불합격사유", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormSelc({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: '검사자', items: inspDamdList, required: true })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자' })}
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 건들을 변경 하시겠습니까?'}
        />  
        </>
    );
}