// AserAccoRegi - A/S 정산 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { AserAccoRegiInsert } from './InfoRegi-insert';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { fwUtil } from '../../../../script/util';

const tempData = [
    {
        "mana_numb": "MN001",
        "clie_code": "C0001",
        "clie_name": "ABC Corp",
        "insp_date": "2024-06-10",
        "insp_kwon": 50000,
        "matl_kwon": 15000,
        "comp_memo": "정기 점검"
    },
    {
        "mana_numb": "MN002",
        "clie_name": "XYZ Ltd",
        "clie_code": "C0002",
        "insp_date": "2024-06-11",
        "insp_kwon": 60000,
        "matl_kwon": 20000,
        "comp_memo": "부품 교체 필요"
    },
    {
        "mana_numb": "MN003",
        "clie_name": "123 Industries",
        "clie_code": "C0003",
        "insp_date": "2024-06-12",
        "insp_kwon": 55000,
        "matl_kwon": 10000,
        "comp_memo": "추가 점검 예정"
    },
    {
        "mana_numb": "MN004",
        "clie_name": "XYZ Services",
        "clie_code": "C0004",
        "insp_date": "2024-06-13",
        "insp_kwon": 70000,
        "matl_kwon": 25000,
        "comp_memo": "문제 없음"
    }
];

export default function AserAccoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/insert`;  // 등록 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'mana_numb';           
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [manaNumb, setManaNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const manaNumbChange = useCallback((e) => { setManaNumb(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);   

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { manaNumb: manaNumb, clieName: clieName };
        //await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
        gridDataUpdate(tempData);
    }, [manaNumb, clieName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);    
    
    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [manaNumb, manaNumbChange, clieName, clieNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}         
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DateCol({ dataField: "insp_date", caption: "점검일자" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "matl_kwon", caption: "부품비" })}
                {CommCol({ dataField: "comp_memo", caption: "비고" })}                       
            </PagingGrid>
            <AserAccoRegiInsert
                refresh={handleSearch}
                mainGrid={gridRef}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />       
        </div>
    );
};