// ProsQcisStat - 수입검사 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        insp_numb: "IN24090001",
        insp_gubu: "수입",
        work_numb: "RQ24090001",
        item_code: "A649-18001-00",
        item_name: "하우징 조립체",
        rout_numb: "010",
        rout_name: "마킹",
        insp_qtys: 100,
        damd_name: "김진구",
        insp_date: "2024-09-10",
        judg_yesn: false,
        judg_cali: "정상",
    },
    {
        insp_numb: "IN24090002",
        insp_gubu: "수입",
        work_numb: "RQ24090002",
        item_code: "B20032583",
        item_name: "포가, 대포용",
        rout_numb: "020",
        rout_name: "포장공정",
        insp_qtys: 200,
        damd_name: "김진구",
        insp_date: "2024-09-12",
        judg_yesn: true,
        judg_cali: "치수 불량",
    }
];

export default function ProsQcisStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props; 

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'insp_numb';
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [inspNumb, setInspNumb] = useState("");
    const [pproNumb, setPproNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const inspNumbChange = useCallback((e) => { setInspNumb(e) }, []);
    const pproNumbChange = useCallback((e) => { setPproNumb(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, inspNumb: inspNumb, pproNumb: pproNumb };        
        await fwUtil.apis.search(searchApi, sendData, mainGridDataUpdate);
        mainGridDataUpdate(tempData);
    }, [dateValue, duraYesn, inspNumb, pproNumb, mainGridDataUpdate, searchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '검사번호', value: inspNumb, valueChange: inspNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '발주번호', value: pproNumb, valueChange: pproNumbChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [inspNumb, inspNumbChange, pproNumb, pproNumbChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "insp_gubu", caption: "검사구분" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "item_code", caption: "품번" })}
                {CommCol({ dataField: "item_name", caption: "품명" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {DeciCol({ dataField: "insp_qtys", caption: "검사수량" })}
                {DeciCol({ dataField: "judg_qtys", caption: "판정수량" })}
                {CommCol({ dataField: "damd_name", caption: "검사자" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {CommCol({ dataField: "judg_yesn", caption: "불합격여부" })}
                {CommCol({ dataField: "judg_cali", caption: "불합격사유" })}
            </PagingGrid>              
        </div>
    );
};