// AserAccoChan - A/S 정산 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { AserAccoChanUpdate } from './InfoChan-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        "mana_numb": "MN001",
        "comp_seri": "01",
        "clie_name": "ABC Corp",
        "bill_date": "2024-06-10",
        "bill_kwon": 50000,
        "pays_date": "2024-06-15",
        "pays_kwon": 50000,
        "asac_memo": "정기 점검 완료"
    },
    {
        "mana_numb": "MN001",
        "comp_seri": "02",
        "clie_name": "ABC Corp",
        "bill_date": "2024-06-11",
        "bill_kwon": 60000,
        "pays_date": "2024-06-16",
        "pays_kwon": 60000,
        "asac_memo": "부품 교체 필요"
    },
    {
        "mana_numb": "MN001",
        "comp_seri": "03",
        "clie_name": "ABC Corp",
        "bill_date": "2024-06-12",
        "bill_kwon": 55000,
        "pays_date": "2024-06-17",
        "pays_kwon": 55000,
        "asac_memo": "추가 점검 예정"
    },
    {
        "mana_numb": "MN002",
        "comp_seri": "01",
        "clie_name": "XYZ Services",
        "bill_date": "2024-06-13",
        "bill_kwon": 70000,
        "pays_date": "2024-06-18",
        "pays_kwon": 70000,
        "asac_memo": "문제 없음"
    }
];

export default function AserAccoChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/change/search`;  // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/change/target`;  // 타겟 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/change/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/change/delete`;  // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['mana_numb', 'comp_seri'];
    const deleKey = (data) => {
        return { 
            manaNumb: data.mana_numb,
            compSeri: data.comp_seri
        }
    };      
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [manaNumb, setManaNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const manaNumbChange = useCallback((e) => { setManaNumb(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { manaNumb: manaNumb, clieName: clieName };
        //await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
        gridDataUpdate(tempData);
    }, [manaNumb, clieName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [manaNumb, manaNumbChange, clieName, clieNameChange, handleSearch]);

    // 변경/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "comp_seri", caption: "순번" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DateCol({ dataField: "bill_date", caption: "청구일자" })}
                {DeciCol({ dataField: "bill_kwon", caption: "청구금액" })}
                {DateCol({ dataField: "pays_date", caption: "정산일자" })}
                {DeciCol({ dataField: "pays_kwon", caption: "정산금액" })}
                {CommCol({ dataField: "asac_memo", caption: "비고" })}                     
            </PagingGrid>
            <AserAccoChanUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 A/S정산 항목을 삭제하시겠습니까?'}
            />             
        </div>
    );
};