// MproWareCanc - 입고 취소
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancelAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { fwUtil } from '../../../../script/util';
                                        
export default function MproWareCanc(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/cancel/search`;  // 조회 api
    const cancelApi = `${acce}/${gubu}/${cata}/${catb}/cancel/delete`;  // 취소 api
    // 시스템    
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";    
    const mainKey = 'ware_numb';    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [seleGubu, setSeleGubu] = useState("구매");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 취소 알림창
    const [cancelAlertVisible, setCancelAlertVisible] = useState(false);
    const cancelAlertHide = useCallback(() => { setCancelAlertVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, seleGubu: seleGubu, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, seleGubu, wareNumb, prosNumb, matlName, clieName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, seleGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 취소 경고 확인 클릭
    const onCancelClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            wareNumb: item.ware_numb,
            prosNumb: item.pros_numb,
            matlCode: item.matl_code,
            lotsNumb: item.lots_numb,
            wareQtys: item.ware_qtys,
            warePric: item.ware_pric,
            wareDate: item.ware_date,
        }));
        const postData = { seleGubu: seleGubu, setaMaps: convertdData }
        await fwUtil.apis.submit(cancelApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        cancelAlertHide();
    }, [seleGubu, gridRef, handleSearch, cancelApi, cancelAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: ['구매', '외주'] })}
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };
    const toolbarAfterItems = () => {
        return (
            <Item location='after' locateInMenu='auto'>
                {ToolbarButton({ icon: 'trash', text: '취소', hidden: false, disabled: authList.auth_dele === 0, onClick: handleCancelClick })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 취소 버튼 클릭
    const handleCancelClick = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);
        if (selectedRowKey) {
            setCancelAlertVisible(true);
            return;
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);    

    

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}             
                toolbar={{ default: true, before: toolbarBeforeItems, after: toolbarAfterItems } }
                selection={'multiple'}
            >
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {DateCol({ dataField: "ware_date", caption: "입고일" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "lots_numb", caption: "Lot No." })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "stoc-qtys", caption: "현재고량" })}
                {CommCol({ dataField: "ware_memo", caption: "비고" })}
            </PagingGrid>        
            <CancelAlert 
                grid={gridRef}
                visible={cancelAlertVisible}
                hiding={cancelAlertHide}
                confirm={onCancelClick}
                message={'해당 입고를 취소하시겠습니까?'}
            />               
        </div>
    );
};