import ShipReqeStat from "./shipreqe/InfoStat";
import ShipReqeRegi from "./shipreqe/InfoRegi";
import ShipReqeChan from "./shipreqe/InfoChan";
import PlanInfoRegi from "./plan/InfoRegi";
import PlanInfoStat from "./plan/InfoStat";
import ProductsRegi from "./product/InfoRegi";
import ProductsStat from "./product/InfoStat";
import WorkOrdeRegi from "./work-order/InfoRegi";
import WorkOrdeRepo from "./work-order/InfoRepo";
import OrdeItemRegi from "./work-order/ItemRegi";
import YardInfoStat from "./yard/InfoStat";
import PopsMoniDash from './monitor/InfoDash';

const catbShip = ({ acce, gubu, cata }) => {
    const catb = "shipreqe";
    const comp = {
        infoStat: () => (<ShipReqeStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D11" mainTitle="출하지시" mainRole="현황" subsTitle="출하지시" subsRole="상세" />),
        infoRegi: () => (<ShipReqeRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D12" mainTitle="출하지시" mainRole="등록" />),
        infoChan: () => (<ShipReqeChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="D13" mainTitle="출하지시" mainRole="변경" />),
    };

    return comp;
};

const catbPlan = ({ acce, gubu, cata }) => {
    const catb = "plan";
    const comp = {
        infoStat: () => (<PlanInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D21" mainTitle="생산계획" mainRole="현황" subsTitle="월별 생산게획" subsRole="현황" />),
        infoRegi: () => (<PlanInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D22" mainTitle="생산계획" mainRole="현황" subsTitle="월별 생산게획" subsRole="수정" />),
    };

    return comp;
};

const catbWork = ({ acce, gubu, cata }) => {
    const catb = "work";
    const comp = {
        infoRegi: () => (<WorkOrdeRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D31" mainTitle="작업지시" mainRole="등록" />),
        itemRegi: () => (<OrdeItemRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D32" mainTitle="작업지시" mainRole="현황" subsTitle="작업지시" subsRole="품목 관리" />),
        infoRepo: () => (<WorkOrdeRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="D33" mainTitle="지시서" mainRole="출력" subsTitle="작업" subsRole="상세" />),
    };

    return comp;
};

const catbProd = ({ acce, gubu, cata }) => {
    const catb = "prod";
    const comp = {
        infoStat: () => (<ProductsStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D41" mainTitle="작업일보" mainRole="현황" subsTitle="공정별 작업일보" subsRole="현황" />),
        infoRegi: () => (<ProductsRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D42" mainTitle="작업일보" mainRole="관리" subsTitle="공정별 작업일보" subsRole="관리" />),
    };

    return comp;
};

const catbYard = ({ acce, gubu, cata }) => {
    const catb = "yard";
    const comp = {
        infoStat: () => (<YardInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D5" mainTitle="야적" mainRole="현황" />),
    };

    return comp;
};

const catbMoni = ({ acce, gubu, cata }) => {
    const catb = "plc";
    const comp = {
        infoDash: () => (<PopsMoniDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="D6" mainTitle="POP" mainRole="모니터링" />),
    }

    return comp;
};

export const cataMfg = ({ acce, gubu }) => {
    const cata = "mfg"
    const comp = {
        catbShip: catbShip({ acce: acce, gubu: gubu, cata: cata }),
        catbPlan: catbPlan({ acce: acce, gubu: gubu, cata: cata }),
        catbWork: catbWork({ acce: acce, gubu: gubu, cata: cata }),
        catbProd: catbProd({ acce: acce, gubu: gubu, cata: cata }),
        catbYard: catbYard({ acce: acce, gubu: gubu, cata: cata }),
        catbMoni: catbMoni({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}