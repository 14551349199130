import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollView } from "devextreme-react";
import { HomeCardItem } from "./item/Home-card";
import { fwUtil } from "../../../script/util";

function getTickIcon(task) {
  if (!task) { return 'errorcircle'; };

  if (task === '대기 중 수주') { return 'taskhelpneeded'; }; 
  if (task === '대기 중 납품') { return 'box'; }; 
  if (task === '대기 중 구매요청') { return 'cart'; }; 
}

function getTickStat(task) {
  if (fwUtil.conv.check.nun(task)) {
    return 'none';
  }
  if (task >= 0 && task < 10) {
    return 'row';
  }
  if (task >= 10 && task < 20) {
    return 'mid';
  }
  if (task >= 20) {
    return 'high';
  }    
}

export function HomeTaskList(props) {
  const { title, data } = props; 
  const navigate = useNavigate();

  function handleCardClick(task) {
    let url = '';

    switch (task) {
      case '대기 중 수주':
        url = '/mes/sal/ship/regist';
        break;
      case '대기 중 납품':
        url = '/mes/sal/sale/regist';
        break;
      case '대기 중 구매요청':
        url = '/mes/pro/ppro/regist';
        break;
      default:
        fwUtil.aler.toast.erro('해당 경로를 찾을 수 없습니다!');
        return;
    }

    navigate(url);
  }
  return (
    <HomeCardItem title={title}>
      <ScrollView>
        <div className="fw-tick-body">
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <div
                className="fw-tick-card fw-flex-box row a-center allow-click"
                key={index}
                onClick={() => handleCardClick(item.task_list)}   
              >
                <div className={`tick-icon-frame fw-flex-box a-center j-center ${getTickStat(item.task_qtys)}`}>
                  <i className={`dx-icon-${getTickIcon(item.task_list)}`}></i>
                </div>
                <div className="tick-body fw-flex-box col">
                  <div className="tick-title">
                    <span className={`title-status ${getTickStat(item.task_qtys)}`}>●</span>
                    <span className="title-content">{item.task_list}</span>
                  </div>
                  <div className="tick-detail">
                    {item.task_qtys} 건
                  </div>
                </div>
              </div>
            ))}
        </div>
      </ScrollView>
    </HomeCardItem>
  );
}
