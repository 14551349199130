import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupFile } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';


const allowedFileExtensions = ['.doc', '.docx', '.xlx', '.xlsx', '.ppt', '.pptx', '.pdf'];

const fileItems = [
    {
        name: '설계PDF.pdf',
        isDirectory: false,
        size: 43,
    },
    {
        name: '설계엑셀.xlsx',
        isDirectory: false,
        size: 84,
    }, 
    {
        name: '설계문서.doc',
        isDirectory: false,
        size: 35,
    },        
];

export function DesiInfoRegiFileMn(props) {
    const {
        mainGrid,
        searchApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    const [rootFileName, setRootFileName] = useState("");
    const rootFileNameUpdate = useCallback((e) => setRootFileName(e), [setRootFileName]);
    const fileManagerRef = useRef(null);

    // 열릴 때
    const onShown = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.sKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            setLoading(true);
            const sendData = { itemCode: selectedRowKey };
            const res = await fwUtil.apis.search(sendData, searchApi);

            dataUpdate(res);            
            rootFileNameUpdate(selectedRowKey);
            dataUpdate(fileItems);
        }
    }, [hiding, mainGrid, searchApi, dataUpdate, rootFileNameUpdate]);

    const onFileUploading = useCallback((e) => {
        console.log(e);
    }, []);

    const onItemDeleting = useCallback((e) => {
        console.log(e);
    }, []);
    
    const onItemRenaming = useCallback((e) => {
        console.log(e);
    }, []);    

    return (         
        <PopupFile
            title={'설계 파일관리'}       
            loading={loading}
            setLoading={setLoading}
            visible={visible}
            shown={onShown}
            hiding={hiding}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1240 } 
            fileManagerRef={fileManagerRef}
            fileSystemProvider={data}
            allowedFileExtensions={allowedFileExtensions}
            rootFileName={rootFileName}
            onFileUploading={onFileUploading} 
            onItemDeleting={onItemDeleting}
            onItemRenaming={onItemRenaming}         
        />     
    );
}