import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormNumb } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    lots_qtys: 0.00,
    insp_date: fwUtil.date.getsText.date(),
};

const inspDataSource = fwUtil.grid.get.dataSource();

export function ShipQcisChanUpdate(props) {
    const {
        mainGrid, subsGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 검사품목 그리드
    const inspRef = useRef(null);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedMainRowData = fwUtil.grid.get.sData(mainGrid);
        const selectedSubsRowData = fwUtil.grid.get.mData(subsGrid);
        if (selectedMainRowData && selectedSubsRowData) {
            const newData = {
                lots_qtys: selectedMainRowData.lots_qtys || 0.00,
                insp_date: selectedMainRowData.insp_date || fwUtil.date.getsText.date(),
            }
            inspDataSource.store().insert({ init: true }).then(() => inspDataSource.reload());
            for (const item of selectedSubsRowData) {
                const newData = {
                    insp_numb: item.insp_numb || '',
                    insp_seri: item.insp_seri || '',
                    insp_cate: item.insp_cate || '',
                    insp_spec: item.insp_spec || '',
                    insp_math: item.insp_math || '',
                    insp_resu: item.insp_resu || '',
                    work_name: item.work_name || '',
                    item_remk: item.item_remk || '',
                    item_memo: item.item_memo || '',                        
                };
                inspDataSource.store().insert(newData);
            }
            
            inspDataSource.reload();
            dataUpdate(newData);
        } else {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }

    }, [dataUpdate, hiding, mainGrid, subsGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const inspData = inspRef?.current?.instance?.getDataSource()?.items();
        const mainKey = fwUtil.grid.get.sKey(mainGrid);
        const postMatlData = inspData.map((item) => ({
            inspSeri: item.insp_seri || '',
            inspCate: item.insp_cate || '',
            inspSpec: item.insp_spec || '',
            inspMath: item.insp_math || '',
            inspResu: item.insp_resu || '',
            itemRemk: item.item_remk || '',
            workName: item.work_name || '',
            itemMemo: item.item_memo || '',  
            lotsQtys: data.lots_qtys || 0.00,
            inspDate: data.insp_date ? fwUtil.date.convTime.fmDate(data.insp_date) : fwUtil.date.getsText.date(),                        
        }));        
        const postData = {
            inspNumb: mainKey || '',
            setaMaps: postMatlData || [],
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, mainGrid, hiding]);

    return (
        <PopupForm
            title={'출하검사 변경'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : isMedium ? 600 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'검사 품목'}
                        gridRef={inspRef} 
                        gridDataSource={inspDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "insp_numb", caption: "검사번호", allowEditing: false })}
                        {CommCol({ dataField: "insp_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "insp_cate", caption: "검사항목", check: true })}
                        {CommCol({ dataField: "insp_spec", caption: "기준(규격)", check: true })}
                        {CommCol({ dataField: "insp_math", caption: "검사방법", check: true })}
                        {CommCol({ dataField: "insp_resu", caption: "검사결과", check: true })}
                        {CommCol({ dataField: "item_remk", caption: "특기사항", check: true })}
                        {CommCol({ dataField: "work_idno", caption: "작업자ID", check: true })}
                        {CommCol({ dataField: "work_name", caption: "작업자명", check: true })}
                        {CommCol({ dataField: "item_memo", caption: "비고", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4 }>
                {FormNumb({ value: data.lots_qtys, onValueChange: updateField('lots_qtys'), label: '검사수량', required: true, length: [15, 2] })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자', required: true })}
            </GroupItem>
        </PopupForm>   
    );
}
