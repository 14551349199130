import React from "react";

export const MixerDetailItem = (props) => {
    const { label, value } = props;

    return (
        <div className="detail-item">
            <div className="detail-label">{label}</div>
            <div className="detail-input">{value}</div>
        </div>
    )
}