import { registerGradient } from "devextreme/common/charts";
import { CircleGauge } from "../../../../../../script/components/gauge/gauge-item";
import { CenterTemplate } from "./Mort-detail-curi-center";
import { ValueIndicator } from "devextreme-react/cjs/circular-gauge";

function customizeTooltip({ valueText }) {
    return {
        text: `${valueText} °C`,
    };
}

const indicatorColor = {
    base: "rgb(252,255,130)",
    fillId: registerGradient("linear", {
        colors: [{
            offset: "0%",
            color: "rgba(252,255,130,1)"
        }, {
            offset: "30%",
            color: "rgba(255,191,107,1)"
        }, {
            offset: "100%",
            color: "rgba(255,90,90,1)"
        }]
    })
};

const rangeColor = {
    base: "#00BCFF",
    fillId: registerGradient("linear", {
        colors: [{
            offset: "0%",
            color: "#00BCFF"
        }, {
            offset: "33%",
            color: "#97C95C"
        }, {
            offset: "66%",
            color: "#FFAE16"
        }, {
            offset: "100%",
            color: "#FE3901"
        }]
    })
};

export function MortDetailCuriItem(props) {
    const { data, number } = props;

    return (
        <div className="mort-detail-curing-wrapper">
            <CircleGauge
                id={`curi-temp-${number}`}
                value={data.curi_temp}
                width={220}
                height={140}
                start={0}
                end={100}
                label={false}
                weight={15}
                angle={[225, 315]}
                rangeColor={rangeColor}
                customizeTooltip={customizeTooltip}
                customCenter={CenterTemplate}
                indicator={
                    <ValueIndicator
                        type="triangleNeedle"
                        width={10}
                        color={indicatorColor}
                        offset={-15}
                        spindleSize={0}
                        spindleGapSize={0}
                        indentFromCenter={42}
                    />
                }
            />
        </div>
    )
}