import React from "react";
import { MortDetailItem } from "./item/Mort-detail";
import { ScrollView } from "devextreme-react";
import './infoDash-mortal.scss';

export function EquiMonitorDashMortal(dashData) {

    if (!dashData) {
        return (
            <div className="fw-page-tab-body">
                <div className="erro-message">
                    표시할 데이터가 없습니다
                </div>
            </div>
        )
    }

    const mortDate = dashData.main?.mort_date || false;
    const emerStat = dashData.main?.erro_stat || false;
    const mortQtys = dashData.main?.mort_qtys || 0;

    return (
        <div className="fw-page-tab-body">
            <ScrollView>
                <div id="fw-equ-moni-page">
                    <div className="fw-tab-body-top">{mortDate || '0000-00-00 00:00'}</div>
                    <div className="fw-tab-body-header fw-flex-box row">
                        <div className={`mortal-emergency-container  fw-flex-box row a-center ${emerStat ? 'active' : 'passive'}`}>
                            <div className="mortal-emergency-button" title={emerStat ? '비상정지 활성화' : '비상정지 비활성화'}>
                            </div>
                        </div>
                        <div className="mortal-tran-qtys-container fw-flex-box row a-center">
                            <div className="mortal-tran-qtys-label"><i className="dx-icon-cart"></i></div>
                            <div className="mortal-tran-qtys-input"><span>몰탈이송횟수</span><span>{mortQtys}</span></div>
                        </div>
                    </div>
                    <div className="fw-tab-body-detail">
                        {dashData && dashData.subs && dashData.subs.length > 0 && dashData.subs.map((item, index) => (
                            <MortDetailItem data={item} key={index} number={index} />
                        ))}
                    </div>
                </div>
            </ScrollView>
        </div>
    )
}