import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    item_code: '',
    item_name: '',
    matl_code: '',
    matl_name: '',
    matl_upri: 0.00,
    enab_yesn: true,
    part_memo: '',    
}

export function AserPartRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                item_code: seleData.item_code || '',
                item_name: seleData.item_name || '',
                matl_code: seleData.matl_code || '',
                matl_name: seleData.matl_name || '',
                matl_upri: seleData.matl_upri || 0.00,
                enab_yesn: seleData.enab_yesn || true,
                part_memo: seleData.part_memo || '',    
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            matlUpri: data.matl_upri || 0.00,
            enabYesn: data.enab_yesn || false,
            partMemo: data.part_memo || '',            
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>        
        <PopupForm
            title={'소모부품 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', required: true, disabled: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', readOnly: true })}                
                {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '부품번호', required: true, maxLength: 20, disabled: true })}
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '부품명', required: true })}    
                {FormNumb({ value: data.matl_upri, onValueChange: updateField('matl_upri'), label: '부품단가', length: [15, 2] })}     
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}     
            </GroupItem>
            {FormMemo({ value: data.part_memo, onValueChange: updateField('part_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}