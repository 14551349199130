import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { fwUtil } from "../../../../script/util";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { YardInfoStatPanel } from "./panel/InfoStat-panel";
import { YardInfoStatMap } from "./InfoStat-map";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import { set } from "../../../../../api/set";
import './infoStat.scss';

const setMfg = set.mes.mfg.yard;

export default function YardInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [mapLoading, setMapLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 데이터
    const title = `${mainTitle} ${mainRole}`
    const yardRef = useRef(null);         
    const [yardData, setYardData] = useState([]);
    const yardDataUpdate = useCallback((e) => setYardData(e), []);    
    // 검색 조건
    const [itemGubu, setItemGubu] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const [gubuList, setGubuList] = useState("");
    const itemGubuChange = useCallback((e) => { setItemGubu(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const gubuListChange = useCallback((e) => { setGubuList(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { itemGubu: itemGubu, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, yardDataUpdate, null, null);
    }, [itemGubu, itemCode, itemName, searchApi, yardDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);    

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult] = await Promise.all([setMfg.item.gubu()]);
                if (gubuResult && gubuResult.length > 0) {
                    gubuListChange(gubuResult);
                    itemGubuChange(gubuResult[0]);
                }                                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuListChange, itemGubuChange]);    
    
    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'selc', caption: '품목구분', value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: gubuList },
        { locate: 'before', type: 'text', caption: '품목번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '품목명', value: itemName, valueChange: itemNameChange, search: handleSearch },
    ], [gubuList, itemGubu, itemCode, itemName, itemGubuChange, itemCodeChange, itemNameChange, handleSearch]);
    
    return (
        <div className="fw-dash-page fw-flex-box col yard">
            <FwAppLoader loading={loading} />
            <CommDash
                title={title}
                dashRef={yardRef}
                search={handleSearch}
                exp={{ fileName: "야적현황", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                { !mapLoading && <YardInfoStatPanel data={yardData} /> }
                <YardInfoStatMap setLoading={setMapLoading} />
            </CommDash>
        </div>
    )
}