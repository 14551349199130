import { ArgumentAxis as CommArgumentAxis, Series as CommSeries, Title as CommTitle, ValueAxis as CommValueAxis, VisualRange as CommVisualRange } from "devextreme-react/cjs/chart";
import { Connector as PiesConnector, Label as PiesLabel, Series as PiesSeries } from "devextreme-react/cjs/pie-chart";
import { fwUtil } from "../../util";

const CommAxis = (props) => {
    const {
        name, caption, position, tick, showZero,
        start, end, margin, maxMargin, valueType
    } = props;

    const axisMarginEnabled = fwUtil.conv.tern(true, margin);
    const valueMaxMargin = fwUtil.conv.tern(0.1, maxMargin)
    const valueTypes = fwUtil.conv.tern('numeric' || valueType);

    return (
        <CommValueAxis
            name={name}
            position={position}
            tickInterval={tick}
            showZero={showZero}
            valueMarginsEnabled={axisMarginEnabled}
            maxValueMargin={valueMaxMargin}
            valueType={valueTypes}
        >
            <CommTitle text={caption} />
            <CommVisualRange
                startValue={start}
                endValue={end}
            />
        </CommValueAxis>
    )
};

const CommArgs = (props) => {
    const {
        margin, categories, field
    } = props;

    const axisMarginEnabled = fwUtil.conv.tern(true, margin);

    return (
        <CommArgumentAxis
            valueMarginsEnabled={axisMarginEnabled}
            categories={categories}
            argumentField={field}
        />
    )
};

const BarsSeri = (props) => {
    const {
        valueField, caption, color, axis, args, group, visible
    } = props;

    return (
        <CommSeries
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}
            type="bar"
            color={color}
            barOverlapGroup={group}
            visible={visible}
        />
    )
};

const SBarSeri = (props) => {
    const {
        valueField, caption, color, axis, args, group, visible
    } = props;

    return (
        <CommSeries
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}
            type="stackedbar"
            color={color}
            barOverlapGroup={group}
            visible={visible}
        />
    )
};


const SpinSeri = (props) => {
    const {
        valueField, caption, color, axis, args
    } = props;

    return (
        <CommSeries
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}
            type="line"
            color={color}
        />
    )
};

const AreaSeri = (props) => {
    const {
        valueField, caption, color, axis, args
    } = props;

    return (
        <CommSeries
            name={caption}
            valueField={valueField}
            axis={axis}
            argumentField={args}
            type="splinearea"
            color={color}
        />
    )
};

const PiesSeri = (props) => {
    const {
        name, valueField, label, args
    } = props;

    return (
        <PiesSeries argumentField={valueField} valueField={args} name={name} >
            {label &&
                <PiesLabel
                    visible={!label.hide}
                    format={label.format}
                    customizeText={label.text}
                >
                    <PiesConnector visible={true} width={1} />
                </PiesLabel>
            }
        </PiesSeries>
    )
};

export {
    CommAxis,
    CommArgs,
    BarsSeri,
    SBarSeri,
    SpinSeri,
    AreaSeri,
    PiesSeri
};