import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    work_numb: '',
    item_code: '',
    item_name: '',
    orde_qtys: 0,
    star_date: fwUtil.date.getsText.date(),
    clos_date: fwUtil.date.getsText.pDate(-30),
    item_memo: '',    
};

export function OrdeItemRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        if (!selectedRowData) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                work_numb: selectedRowData.work_numb || '',
                item_code: selectedRowData.item_code || '',
                item_name: selectedRowData.item_name || '',
                orde_qtys: selectedRowData.orde_qtys || 0,
                star_date: selectedRowData.star_date || fwUtil.date.getsText.date(),
                clos_date: selectedRowData.clos_date || fwUtil.date.getsText.pDate(-30),
                item_memo: selectedRowData.item_memo || '',                    
            };
            
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        console.log(data)
        const postData = {
            workNumb: data.work_numb || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            ordeQtys: data.orde_qtys || 0,
            starDate: data.star_date ? fwUtil.date.convTime.fmDate(data.star_date) : fwUtil.date.getsText.date(),
            closDate: data.clos_date ? fwUtil.date.convTime.fmDate(data.clos_date) : fwUtil.date.getsText.pDate(-30),
            itemMemo: data.item_memo || '',                 
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'작업지시 품목 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: "작업지시번호", disabled: true })}
                    </GroupItem>
                    {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: "제품번호", required: true, disabled: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: "제품명", disabled: true })}
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormNumb({ value: data.orde_qtys, onValueChange: updateField('orde_qtys'), label: "지시수량", length: [12, 2] })}
                    </GroupItem>        
                    {FormDate({ value: data.star_date, onValueChange: updateField('star_date'), label: "시작일자" })}
                    {FormDate({ value: data.clos_date, onValueChange: updateField('clos_date'), label: "완료일자" })}
                </GroupItem>
                {FormMemo({ value: data.item_memo, onValueChange: updateField('item_memo'), label: "비고" })}
            </PopupForm>
        </>
    );
}