import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid } from '../../../../script/components/popup/popup';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { Item } from 'devextreme-react/cjs/data-grid';
import { ToolbarTextBox, SearchButton } from '../../../../script/components/toolbar/toolbar-items';
import { fwUtil } from '../../../../script/util';


const keyExpr = ['purs_numb', 'matl_code'];  // 데이터 키

export function MproInfoRegiSubsSelect(props) {
    const {
        mainGrid,
        refresh,
        selectApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const workGridRef = useRef(null);
    // 검색 조건
    const [workNumb, setWorkNumb] = useState('');
    const workNumbChange = useCallback((e) => setWorkNumb(e), []);
    
    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { workNumb: workNumb };
        await fwUtil.apis.search(selectApi, sendData , dataUpdate);
    }, [workNumb, dataUpdate, selectApi]);    

    // 열릴 때
    const onShowing = () => {
        setLoading(true);
    };

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        await handleSearch();
        setLoading(false);
    }, [handleSearch, setLoading]);

    // 제출
    const onSubmit = useCallback(async () => {
        const selectMproKey = fwUtil.grid.get.sKey(mainGrid);
        const selectedRowKey = fwUtil.grid.get.sKey(workGridRef);
        if (selectedRowKey) {
            setLoading(true);
            const selectedData = workGridRef.current.instance.getSelectedRowsData() || [];
            const convertdData = selectedData.map((item) => ({
                workNumb: item.work_numb || '',
                routNumb: item.rout_numb || '',
                routName: item.rout_name || '',
                itemGubu: item.item_gubu || '',
                matlCode: item.matl_code || '',
                matlName: item.matl_name || '',
                puroUnit: item.puro_unit || 0.000,
                mproPric: item.mpro_pric || 0.000,  
            }));
            const postData = { mproNumb: selectMproKey, setaMaps: convertdData }
            await fwUtil.apis.submit(insertApi, postData, refresh);
            workGridRef.current.instance.deselectAll();
            setLoading(false);
            hiding();
        } else {
            fwUtil.aler.toast.s1Erro();
        }
                
    }, [insertApi, hiding, mainGrid, workGridRef, refresh]);

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(workGridRef, key, isSelected);
    }, [workGridRef]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업지시번호", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        );
    }, [workNumb, workNumbChange, handleSearch]);

    const gridBody = useMemo(() => (
        <ScrollGrid
            title="작업지시"
            role="목록"
            gridDataSource={data}
            gridRef={workGridRef}
            mainKey={keyExpr}
            width={isXSmall ? 300 : isSmall ? 550 : isMedium ? 860 : 1240 }
            height={600}
            onRowClick={onRowClick}
            selection={'multiple'}
            toolbar={{ default: false, before: toolbarBeforeItems }}
        >
            {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
            {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
            {CommCol({ dataField: "rout_name", caption: "공정명" })}
            {CommCol({ dataField: "item_gubu", caption: "구분(자작/외주)" })}
            {CommCol({ dataField: "matl_code", caption: "제품번호" })}
            {CommCol({ dataField: "matl_name", caption: "제품명" })}
            {CommCol({ dataField: "matl_spec", caption: "재질" })}
            {CommCol({ dataField: "puro_unit", caption: "발주단위" })}
            {DeciCol({ dataField: "work_qtys", caption: "지시 수량" })}
            {DeciCol({ dataField: "orde_qtys", caption: "수주 수량" })}
            {DeciCol({ dataField: "mpro_pric", caption: "최근 입고단가" })} 
        </ScrollGrid>
    ), [data, workGridRef, onRowClick, toolbarBeforeItems, isXSmall, isSmall, isMedium]);

    return ( 
        <PopupGrid
            title={'발주 상세품목'}   
            type={'추가'}
            loading={loading}
            visible={visible}  
            showing={onShowing}
            shown={onShown}  
            hiding={hiding}
            onSubmit={onSubmit}
        >
            {gridBody}
        </PopupGrid>
    );
}