import '../../../../../../style/focuswin.variables.base.scss';

const gaugeColor = (value) => {
    if (value > 80) {
        return '#FE3901';
    } else if (value > 60) {
        return '#FFAE16';
    } else if (value > 30) {
        return '#97C95C';
    } else {
        return '#00BCFF';
    }
}

export function CenterTemplate(gauge) {
    const centerColor = gaugeColor(gauge.value());

    return (

        <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_141_13)">
                <circle cx="47" cy="45" r="43" fill="var(--primary-depth-color)" />
            </g>
            <defs>
                <filter id="filter0_d_141_13" x="0" y="0" width="94" height="94" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_141_13" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_141_13" result="shape" />
                </filter>
            </defs>
            <text
                textAnchor="middle"
                alignmentBaseline="middle"
                y="47"
                x="47"
                fontSize="35"
                fill={centerColor}
            >
                {gauge.value()}
            </text>
        </svg>

    );
}
