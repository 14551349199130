import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    work_numb: '',
    clie_code: '',
    clie_name: '',
    issu_date: fwUtil.date.getsText.date(),
    clos_date: fwUtil.date.getsText.pDate(-30),
    work_memo: '',
};

const setWork = set.mes.mfg.work;

export function WorkOrdeRegiInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';
    // 그리드 조회 조건
    const [clieName, setClieName] = useState('');
    const clieNameChange = useCallback((e) => setClieName(e), []);
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);
    // 팝업 조회
    const clieHandleSearch = useCallback(async () => {
        const sendData = { clieName: clieName };
        const res = await setWork.clie(sendData);
        clieGridUpdate(res);
    }, [clieName, clieGridUpdate]);

    // 그리드 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '거래처명', value: clieName, valueChange: clieNameChange, search: clieHandleSearch },
        { type: 'btns', locate: 'after', icon: 'search', text: '검색', onClick: clieHandleSearch }
    ], [clieName, clieNameChange, clieHandleSearch]);    

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            issuDate: data.issu_date || fwUtil.date.getsText.date(),
            closDate: data.clos_date || fwUtil.date.getsText.pDate(-30),
            workMemo: data.work_memo || '',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const clieGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "고객구분" })}
                {CommCol({ dataField: "clie_name", caption: "고객명[한글]" })}
                {CommCol({ dataField: "clie_enam", caption: "고객명[영문]" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
            </ScrollGrid>
        )
    }, [clieData, clieGridRef, clieKeyExpr, clieOnRowDblClick, toolbarItems, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'고객 선택'}
                visible={cliePopupVisible}
                shown={cliePopupOnShowing}
                hiding={cliePopupHiding}
            >
                {clieGrid}
            </PopupGrid>
            <PopupForm
                title={'작업지시 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: "작업지시번호[자동입력]", disabled: true })}
                    </GroupItem>
                    {FormGrid({ value: data.clie_code, onValueChange: updateField('clie_code'), onClick: () => setCliePopupVisible(true), label: "고객번호" })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "고객명" })}
                    {FormDate({ value: data.issu_date, onValueChange: updateField('issu_date'), label: "발행일", required: true })}
                    {FormDate({ value: data.clos_date, onValueChange: updateField('clos_date'), label: "생산완료일", required: true })}
                </GroupItem>
                {FormMemo({ value: data.work_memo, onValueChange: updateField('work_memo'), label: "비고" })}
            </PopupForm>
        </>
    );
}