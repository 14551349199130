// MproWareList - 외주 입고 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LoadPanel } from 'devextreme-react';
import { Item, Summary } from 'devextreme-react/data-grid';
import { TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { fwUtil } from '../../../../script/util';

export default function MproWareList(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainKey = 'ware_numb';    
    const gridSize = "single";    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlCode, setMatlCode] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb, wareNumb: wareNumb, prosNumb: prosNumb, matlCode: matlCode, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, workNumb, wareNumb, prosNumb, matlCode, clieName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재번호", value: matlCode, valueChange: matlCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "지시번호", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(gridRef, key, isSelected);
    }, [gridRef]);

    

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {DateCol({ dataField: "ware_date", caption: "입고일" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                {DeciCol({ dataField: "ware_kwon", caption: "입고금액" })}
                {CommCol({ dataField: "ware_memo", caption: "비고" })}
                <Summary >
                    {TotalSumItem({ dataField: 'ware_qtys', caption: "입고 총수량" })}
                    {TotalSumItem({ dataField: 'ware_kwon', caption: "입고 총금액" })}
                </Summary>
            </PagingGrid>        
        </div>
    );
};