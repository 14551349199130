import { fwUtil } from "../util";

// 시작-종료 날짜 조회
function getDefaultDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const formatDate = (e) => {
        const year = e.getFullYear();
        const month = String(e.getMonth() + 1).padStart(2, '0'); 
        const date = String(e.getDate()).padStart(2, '0');
        return `${year}-${month}-${date}`;
    };

    return [formatDate(firstDayOfMonth), formatDate(lastDayOfMonth)];
};

// 날짜 텍스트 받아오기
function getNowDateText(type) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const date = String(today.getDate()).padStart(2, '0');
    const sDate = "01";
    const eDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate().toString().padStart(2, '0');;
    const hour = String(today.getHours()).padStart(2, '0');
    const minute = String(today.getMinutes()).padStart(2, '0');
    let dateText = "";

    switch (type) {
        case "year":
            dateText = `${year}`;
            break;
    
        case "month":
            dateText = `${year}-${month}`;
            break;    

        case "date":
            dateText = `${year}-${month}-${date}`;
            break;     

        case "dtme":
            dateText = `${year}-${month}-${date} ${hour}:${minute}`;
            break;     
            
        case "time":
            dateText = `${hour}:${minute}`;
            break;

        case "sDtme":
            dateText = `${year}-${month}-${sDate} 00:00`;
            break;            

        case "eDtme":
            dateText = `${year}-${month}-${eDate} 23:59`;
            break;       

        default:
            break;
    }

    return dateText;
};

function getPreDateText(e) {
    const preDate = new Date();
    preDate.setDate(preDate.getDate() - e);
    const year = preDate.getFullYear();
    const month = (preDate.getMonth() + 1).toString().padStart(2, '0');
    const date = preDate.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${date}`;
};

function getDayOfWeek(dateString) {
    const date = new Date(dateString);
    const days = ['일', '월', '화', '수', '목', '금', '토'];
    return days[date.getDay()];
}

export const getsText = {
    year: () => getNowDateText('year'),
    month: () => getNowDateText('month'),
    date: () => getNowDateText('date'),
    pDate: (n) => getPreDateText(n),
    sDtme: () => getNowDateText('sDtme'),
    eDtme: () => getNowDateText('eDtme'),
    dtme: () => getNowDateText('dtme'),
    time: () => getNowDateText('time'),
    range: () => getDefaultDateRange(),
    sWeek: (e) => getDayOfWeek(e),
};


function formatMont(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
};

function formatDate(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

function formatTime(isoString) {
    const date = new Date(isoString);
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${hour}:${minute}:${second}`;
};

function timeToSecs(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
}

function secsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function dtmeToLoca(e) {
    const date = new Date(e);
    date.setHours(date.getHours() + 9);
    const formattedDate = date.toISOString().replace("T", " ").replace("Z", "");
    return formattedDate;
};

function unixToTime(unixTime) {
    if (!unixTime) {
        return '알수없음';
    }
    const now = Math.floor(Date.now() / 1000);
    
    const secondsRemaining = unixTime - now;

    if (secondsRemaining <= 0) {
        return '세션 만료';
    }

    const days = Math.floor(secondsRemaining / (24 * 3600));
    const hours = Math.floor((secondsRemaining % (24 * 3600)) / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = secondsRemaining % 60;

    let result = '';
    if (days > 0) result += `${days}일 `;
    if (hours > 0 || days > 0) result += `${hours}시간 `;
    if (minutes > 0 || hours > 0 || days > 0) result += `${minutes}분 `;
    result += `${seconds}초`;

    return result;
}

export const convTime = {
    toSecs: timeToSecs,
    toTime: secsToTime,
    toLoca: dtmeToLoca,
    fmMont: formatMont,
    fmDate: formatDate,
    fmTime: formatTime,
    unTime: unixToTime,
};

function getYearList(s, e) {
    const start = fwUtil.conv.tern(5, s);
    const end = fwUtil.conv.tern(-5, e);
    const currentYear = new Date().getFullYear();
    const pastYears = [];

    for (let j = start; j >= end; j--) {
        pastYears.push((currentYear + j).toString());
    }

    return pastYears;
}

export const getsList = {
    year: getYearList,
}
