import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashItemGridCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"제품 현황"}
            addClass={'item-grid'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={["item_code", "stoc_loca"]}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "item_name", caption: "자재코드" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {CommCol({ dataField: "item_quli", caption: "재질" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량" })}
                {DeciCol({ dataField: "stoc_qpri", caption: "단가" })}
                {DeciCol({ dataField: "stoc_kwon", caption: "금액" })}
                {CommCol({ dataField: "stoc_unit", caption: "재고단위" })}
            </ScrollGrid>
        </DashCard>
    )
}