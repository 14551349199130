// PlanInfoStat - 생산계획 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid, } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PlanInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/header/search`; // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/search`; // 서브 조회 api
    // 시스템    
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";         
    const subsKey = ['ypla_year', 'ypla_mont'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);    
    // 검색 조건
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);  

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { itemName: itemName, itemCode: itemCode };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate);
    }, [itemName, itemCode, mainSearchApi, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.sData(mainGridRef)?.item_code;
        if (keyData) {
            const sendData = { itemCode: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(() => {
        subsHandleSearch();
    }, [subsHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객명', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {DeciCol({ dataField: "deli_qtys", caption: "납품수량" })}
                {DeciCol({ dataField: "mode_qtys", caption: "미납수량" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
            </MainGrid>   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: 'ypla_year', caption: '생산년도' })}
                {CommCol({ dataField: 'ypla_mont', caption: '생산월' })}
                {DeciCol({ dataField: 'ypla_qtys', caption: '계획수량' })}
                {CommCol({ dataField: 'ypla_remk', caption: '특기사항' })}
                {CommCol({ dataField: 'ypla_memo', caption: '비고' })}
            </SubsGrid>                                    
        </div>
    );
};