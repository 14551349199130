import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormSelc } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    ware_date: fwUtil.date.getsText.date(),
    stoc_loca: '',
    insp_okey: true,
};

const setPwar = set.mes.mat.stoc;

const pproDataSource = fwUtil.grid.get.dataSource();

export function PproPartRegiInsert(props) {
    const {
        mainGrid, subsGrid,
        subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 발주 그리드
    const pproRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 저장 위치 리스트
    const [locaList, setLocaList] = useState([]);
    const locaChange = useCallback((e) => setLocaList(e), []);    

    const fetchData = useCallback(async () => {
        try {
            const [locaResult] = await Promise.all([setPwar.loca()]);
            locaChange(locaResult);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [locaChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const selectedMainData = fwUtil.grid.get.sData(mainGrid);
        const selectedSubsData = fwUtil.grid.get.mData(subsGrid);

        if (selectedMainData && selectedSubsData && selectedSubsData.length > 0)  {
            pproDataSource.store().insert({ init: true }).then(() => pproDataSource.reload());
            for (const ppro of selectedSubsData) {
                const newData = {
                    ppro_numb: ppro.ppro_numb || '',
                    ppro_seri: ppro.ppro_seri || '',
                    work_numb: ppro.work_numb || '',
                    rout_numb: ppro.rout_numb || '',
                    rout_name: ppro.rout_name || '',
                    item_gubu: ppro.item_gubu || '',
                    matl_code: ppro.matl_code || '',
                    matl_name: ppro.matl_name || '',
                    matl_spec: ppro.matl_spec || '',
                    puro_unit: ppro.puro_unit || '',
                    ppro_qtys: ppro.ppro_qtys || 0.000,
                    ppro_pric: ppro.ppro_pric || 0.00,
                    ppro_cwon: ppro.ppro_cwon || 0.00,
                    rece_qtys: ppro.rece_qtys || 0.000,
                    lots_numb: ppro.lots_numb || '',
                    ware_qtys: ppro.ware_qtys || 0.000,
                    ware_pric: ppro.ware_pric || 0.00,
                    ware_cwon: ppro.ware_cwon || 0.00,
                    ppro_memo: ppro.ppro_memo || '',
                    clie_code: selectedMainData.clie_code,
                    clie_name: selectedMainData.clie_name,
                };
                pproDataSource.store().insert(newData);
            }
            pproDataSource.reload();
            dataUpdate(defaultData);
        } else {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [fetchData, hiding, dataUpdate, mainGrid, subsGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const pproData = pproRef?.current?.instance?.getDataSource()?.items();
        const postPproData = pproData.map((item) => ({    
            pproNumb: item.ppro_numb || "",
            pproSeri: item.ppro_seri || "",
            clieCode: item.clie_code || "",
            clieName: item.clie_name || "",
            matlCode: item.matl_code || "",
            itemGubu: item.item_gubu || "",
            matlName: item.matl_name || "",
            matlSpec: item.matl_spec || "",
            lotsNumb: item.lots_numb || "",
            wareQtys: item.ware_qtys || 0.000,
            warePric: item.ware_pric || 0.00, 
            wareCwon: item.ware_cwon || 0.00, 
            wareMemo: item.ware_memo || "", 
            wareDate: data.ware_date || fwUtil.date.getsText.date(),
            inspOkey: data.insp_okey || false,
            stocLoca: data.stoc_loca || '',
        }));        
        const postData = {
            setaMaps: postPproData || [],
        };

        const allHiding = () => {
            alertHide();
            hiding();
        }
        await fwUtil.apis.action(insertApi, postData, subsRefresh, allHiding);
        setLoading(false);
    }, [data, insertApi, subsRefresh, hiding, alertHide, pproRef]);

    return (
        <>
        <PopupForm
            title={'부분입고'}       
            type={'입고'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : '90%' } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'발주상세품목'}
                        gridRef={pproRef} 
                        gridDataSource={pproDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "ppro_seri", caption: "순번" , allowEditing: false})}
                        {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
                        {CommCol({ dataField: "puro_unit", caption: "구매단위", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_qtys", caption: "발주수량", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_pric", caption: "발주단가", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_cwon", caption: "발주금액", allowEditing: false })}
                        {DeciCol({ dataField: "rece_qtys", caption: "기입고량", allowEditing: false })}
                        {DeciCol({ dataField: "ware_qtys", caption: "입고예정", check: true })}
                        {DeciCol({ dataField: "ware_pric", caption: "입고단가", check: true })}
                        {DeciCol({ dataField: "ware_cwon", caption: "입고금액", check: true })}
                        {CommCol({ dataField: "ware_memo", caption: "비고", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                {FormDate({ value: data.ware_date, onValueChange: updateField('ware_date'), label: '등록일자' })}
                {FormSelc({ value: data.stoc_loca, onValueChange: updateField('stoc_loca'), label: '저장위치', items: locaList, required: true })}
                <GroupItem colCount={2}>
                    {FormBool({ value: data.insp_okey, onValueChange: updateField('insp_okey'), label: '입고검사 확인' })}
                </GroupItem>
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            warning={"수정된 내용을 바탕으로 해당 품목들이 입고처리 됩니다."}
            message={'해당 품목을 부분입고 하시겠습니까?'}
        />  
        </>
    );
}