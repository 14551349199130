// StocAdjuRepo - 재고조사표
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { set } from '../../../../../api/set';

const setMat = set.mes.mat;

export default function StocAdjuRepo(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/survey/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['matl_code', 'stoc_loca'];    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [itemGubu, setItemGubu] = useState("");
    const [stocLoca, setStocLoca] = useState("");
    const [matlCode, setMatlCode] = useState("");
    const [matlName, setMatlName] = useState("");  
    const [gubuList, setGubuList] = useState([]);
    const [stocList, setStocList] = useState([]);
    const itemGubuChange = useCallback((e) => { setItemGubu(e) }, []);    
    const stocLocaChange = useCallback((e) => { setStocLoca(e) }, []);    
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []); 
    const gubuListChange = useCallback((e) => { setGubuList(e) }, []); 
    const stocListChange = useCallback((e) => { setStocList(e) }, []); 

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult, stocResult] = await Promise.all([setMat.item.gubu(), setMat.stoc.list()]);
                if (gubuResult && gubuResult.length > 0) {
                    gubuListChange(gubuResult);
                    itemGubuChange(gubuResult[0]);
                }                    
                if (stocResult && stocResult.length > 0) {
                    stocListChange(stocResult);
                    stocLocaChange(stocResult[0]);
                }                                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuListChange, itemGubuChange, stocListChange, stocLocaChange]);  

    // 조회
    const handleSearch = useCallback(async () => {
        if (itemGubu && stocLoca) {
            const sendData = { itemGubu: itemGubu, stocLoca: stocLoca, matlCode: matlCode, matlName: matlName };
            await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
        }
    }, [itemGubu, stocLoca, matlCode, matlName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '자재분류', value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: gubuList },
        { type: 'selc', locate: 'before', caption: '저장위치', value: stocLoca, valueChange: stocLocaChange, search: handleSearch, items: stocList },
        { type: 'text', locate: 'before', caption: '자재번호', value: matlCode, valueChange: matlCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [gubuList, stocList, itemGubu, itemGubuChange, stocLoca, stocLocaChange, matlCode, matlCodeChange, matlName, matlNameChange, handleSearch]);
    
    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />            
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
                hideColumnChooser
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "item_gubu", caption: "구분" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장 위치" })}
                {CommCol({ dataField: "stoc_unit", caption: "재고단위" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
                {DeciCol({ dataField: "stoc_weig", caption: "재고중량" })}
                {DeciCol({ dataField: "stoc_qpri", caption: "재고단가" })}
                {CommCol({ dataField: "stoc_memo", caption: "재고비고" })}
                {DeciCol({ dataField: "orig_qtys", caption: "조정 전 수량" })}
                {DeciCol({ dataField: "adju_qtys", caption: "조정 후 수량", check: true })}
                {DeciCol({ dataField: "orig_weig", caption: "조정 전 중량" })}
                {DeciCol({ dataField: "adju_weig", caption: "조정 후 중량", check: true })}                
                {DeciCol({ dataField: "orig_pric", caption: "조정 전 단가" })}
                {DeciCol({ dataField: "adju_pric", caption: "조정 후 단가", check: true })}
                {CommCol({ dataField: "adju_memo", caption: "조정사유" })}
                {DateCol({ dataField: "adju_date", caption: "조정일자" })}
            </PagingGrid>          
        </div>
    );
};