export function convChartData(data) {
    const aggrData = data.reduce((acc, item) => {
        const { stoc_loca, stoc_qtys, stoc_kwon } = item;

        const qtys = parseFloat(stoc_qtys);
        const kwon = parseFloat(stoc_kwon);

        if (!acc[stoc_loca]) {
            acc[stoc_loca] = {
                stoc_loca: stoc_loca,
                stoc_qtys: 0,
                stoc_kwon: 0
            };
        }

        acc[stoc_loca].stoc_qtys += qtys;
        acc[stoc_loca].stoc_kwon += kwon;    
        
        return acc;
    }, {});

    const resultArray = Object.values(aggrData);

    return resultArray;
}