import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PageTabPanel } from '../../../../script/components/tab-panel/tab-panel-body/panel-body';
import { moniFunc, moniData } from './infoUtil';
import { EquiMonitorDashMortal } from './mortal/InfoDash-mortal';
import { EquiMonitorDashMixers } from './mixers/InfoDash-mixers';
import { CommTabs } from '../../../../script/components/tab-panel/tab-panel-item/panel-item';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const defaultMolt = { main: moniData.defaultMainMort, subs: moniData.defaultSubsMort }

export default function PopsMoniDash(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole,
    } = props;

    // api
    const mortSearchApi = `${acce}/${gubu}/${cata}/${catb}/mortal/search`;  // 몰탈 조회 api
    const mixeSearchApi = `${acce}/${gubu}/${cata}/${catb}/mixer/search`;   // 믹서 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 탭
    const [tabIndex, setTabIndex] = useState(0);
    // 데이터
    const [mortData, setMortData] = useState(defaultMolt);
    const mortDataUpdate = useCallback((e) => { setMortData(e) }, []);
    const [mixeData, setMixeData] = useState([]);
    const mixeDataUpdate = useCallback((e) => { setMixeData(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const res = await fwUtil.apis.search(mortSearchApi);
        const conv = moniFunc.convertMortData(res);
        mortDataUpdate(conv);
        await fwUtil.apis.search(mixeSearchApi, null, mixeDataUpdate);
        setPageLoading(false);
    }, [setPageLoading, mortSearchApi, mixeSearchApi, mortDataUpdate, mixeDataUpdate]);

    // 조회
    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const exportDashPage = useCallback(() => {
        const dashPage = document.getElementById('fw-equ-moni-page');
        fwUtil.data.screen(dashPage, 'l', '설비모니터링');
    }, []);

    return (
        <>
            <FwAppLoader loading={loading} />
            <PageTabPanel
                title={mainTitle}
                role={mainRole}
                loading={pageLoading}
                refresh={handleSearch}
                index={tabIndex}
                indexChange={setTabIndex}
                exp={{ disabled: authList.auth_dnlo === 0, onClick: exportDashPage }}
            >
                {CommTabs({ title: '몰탈', icon: 'cart', render: () => EquiMonitorDashMortal(mortData) })}
                {CommTabs({ title: '믹서', icon: 'product', render: () => EquiMonitorDashMixers(mixeData) })}
            </PageTabPanel>
        </>
    );
};