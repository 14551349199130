// ShipReqeStat - 출하 지시 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ShipReqeStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/detail/search`;  // 서브 조회 api   
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'reqe_numb';
    const subsKey = ['reqe_numb', 'reqe_seri'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };    
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.sKey(mainGridRef);
        if (keyData) {
            const sendData = { reqeNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, clieNameChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "reqe_numb", caption: "지시번호" })}
                {DateCol({ dataField: "reqe_date", caption: "지시일자" })}
                {DeciCol({ dataField: "reqe_qtys", caption: "품목수" })}
                {CommCol({ dataField: "user_name", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_name", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}                  
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}               
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "reqe_numb", caption: "지시번호" })}
                {CommCol({ dataField: "reqe_seri", caption: "순번" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "reqe_date", caption: "지시일자" })}
                {DeciCol({ dataField: "reqe_qtys", caption: "지시수량" })}
            </SubsGrid>         
        </div>
    );
};