import { fwUtil } from "../util";

// api 생성
export function creatApi(level, cate, mids, role) {
    const convCate = cate ? `/${cate}` : null;
    return `${level}${convCate}/${mids}/${role}`;
};

// 데이터 조회
export async function searchData (api, sendData, setData, mainGrid, subsGrid) {
    const handleLoading = (action) => {
        mainGrid?.current?.instance?.[`${action}CustomLoading`]();
        subsGrid?.current?.instance?.[`${action}CustomLoading`]();
    };

    const handleDeselect = () => {
        mainGrid?.current?.instance?.deselectAll();
        subsGrid?.current?.instance?.deselectAll();
    };
    
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        handleLoading('begin');
        const response = await axiosInstance.post(`${api}`, sendData);
        if (response.data.status === true) {
            const preData = mainGrid?.current?.instance?.getDataSource()?.items();
            const resData = response.data.dataSet;

            if (!fwUtil.conv.deepObj(preData, resData)) {
                if (setData) {
                    setData(resData);
                    handleDeselect();
                }
            }
            
            handleLoading('end');            
            return resData;
        } else {
            handleLoading('end');   
            fwUtil.aler.toast.s1Erro();
            return [];
        }
    }
    catch (error) {
        console.log('searchData.error === ', error);
        handleLoading('end');  
        fwUtil.aler.toast.s1Erro();
    }
};

// 데이터 발송
export async function submitData(api, sendData, refresh, returnData) {
    try {
        console.log('submit start');
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(`${api}`, sendData);
        fwUtil.aler.toast.res(response.data);
        refresh && refresh();
        console.log('response === ', response);
        return returnData && response.data[returnData];
    }
    catch (error) {
        console.log('error === ', error);
        fwUtil.aler.toast.a1Erro();
    }
};

// 데이터 발송 후 확인
export async function actionData(api, sendData, refresh, hiding, returnData) {
    try {
        console.log('action start');
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(`${api}`, sendData);
        const responStatus = response?.data?.status;
        fwUtil.aler.toast.res(response.data);
        if (responStatus) {
            refresh && refresh();
            hiding && hiding();
        }
        return returnData && response.data[returnData];
    } 
    catch (error) {
        console.log('error === ', error);
        fwUtil.aler.toast.a1Erro();
    }
};

// 데이터 삭제
const deleteRow = async (api, sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(api, sendData);
        fwUtil.aler.toast.res(response.data);
        console.log('delete response === ', response);
    } catch (error) {
        console.log('error === ', error);
        throw new Error('Failed to delete data');
    }
};

export async function deleteData(api, grid, deleKey, refresh) {
    try {
        const selectedRowKeys = grid?.current?.instance?.getSelectedRowKeys();
        if (selectedRowKeys) {
            grid.current.instance.deselectAll();
            await Promise.all(selectedRowKeys.map(async (item) => {
                const postData = deleKey(item);
                await deleteRow(api, postData);
            }));
        } else {
            fwUtil.aler.toast.nsWarn();
        }
    } catch (error) {
        console.log('error === ', error);
    } finally {
        refresh();
    }
};

// 데이터 발송
export async function touchData(api, setSessStat) {
    try {
        console.log('touch start');
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post(`${api}`);
        const sessStatus = response.data.status;
        setSessStat && setSessStat(sessStatus);
        console.log('response === ', response);
    }
    catch (error) {
        console.log('error === ', error);
        setSessStat && setSessStat(false);        
    }
};