import { cataCom } from './com';
import { cataTec } from './tec';
import { cataPro } from './pro';
import { cataMfg } from './mfg';
import { cataQci } from './qci';
import { cataSal } from './sal';
import { cataEqu } from './equ';
import { cataBiz } from './biz';
import { cataSys } from './sys';
import { cataEls } from './els';
import { cataMat } from './mat';

const acce = 'privat';
const gubu = 'mes';

// com
const cataComInstance = cataCom({ acce: acce, gubu: gubu });
export const CodeInfoStat = cataComInstance.catbCode.infoStat;
export const ClieInfoStat = cataComInstance.catbClie.infoStat;
export const MatlInfoStat = cataComInstance.catbMatl.infoStat;
export const MatlInfoRegi = cataComInstance.catbMatl.infoRegi;
export const MatlInfoCrea = cataComInstance.catbMatl.infoCrea;
export const MatlCataStat = cataComInstance.catbMatl.cataStat;
export const MatlCatbStat = cataComInstance.catbMatl.catbStat;
export const MatlCatcStat = cataComInstance.catbMatl.catcStat;
export const MatlCatdStat = cataComInstance.catbMatl.catdStat;
export const ItemInfoStat = cataComInstance.catbItem.infoStat;
export const ItemInfoRegi = cataComInstance.catbItem.infoRegi;
export const ItemBomsStat = cataComInstance.catbBoms.bomsStat;
export const ItemBomsRegi = cataComInstance.catbBoms.bomsRegi;
export const CommWorkStat = cataComInstance.catbWork.infoStat;

// tec
const cataTecInstance = cataTec({ acce: acce, gubu: gubu });
export const BluePrinRegi = cataTecInstance.catbBlue.infoRegi;
export const DesiInfoRegi = cataTecInstance.catbDesi.infoRegi;

// sal
const cataSalInstance = cataSal({ acce: acce, gubu: gubu });
export const OrdeInfoUplo = cataSalInstance.catbOrde.infoUplo;
export const OrdeInfoStat = cataSalInstance.catbOrde.infoStat;
export const OrdeInfoRegi = cataSalInstance.catbOrde.infoRegi;
export const OrdeInfoHist = cataSalInstance.catbOrde.infoHist;
export const ShipInfoStat = cataSalInstance.catbShip.infoStat;
export const ShipInfoRegi = cataSalInstance.catbShip.infoRegi;
export const ShipInfoChan = cataSalInstance.catbShip.infoChan;
export const ShipInfoRepo = cataSalInstance.catbShip.infoRepo;
export const ShipInfoHist = cataSalInstance.catbShip.infoHist;
export const SaleInfoStat = cataSalInstance.catbSale.infoStat;
export const SaleInfoRegi = cataSalInstance.catbSale.infoRegi;
export const SaleInfoCanc = cataSalInstance.catbSale.infoCanc;

// pro
const cataProInstance = cataPro({ acce: acce, gubu: gubu });
export const PursInfoStat = cataProInstance.catbPurs.infoStat;
export const PursInfoRegi = cataProInstance.catbPurs.InfoRegi;
export const PursInfoChan = cataProInstance.catbPurs.InfoChan;
export const PproInfoStat = cataProInstance.catbPpro.infoStat;
export const PproInfoRegi = cataProInstance.catbPpro.InfoRegi;
export const PproInfoRepo = cataProInstance.catbPpro.InfoRepo;
export const MproInfoStat = cataProInstance.catbMpro.infoStat;
export const MproInfoRegi = cataProInstance.catbMpro.InfoRegi;
export const MproInfoRepo = cataProInstance.catbMpro.InfoRepo;

// mat
const cataMatInstance = cataMat({ acce: acce, gubu: gubu });
export const PproWareList = cataMatInstance.catbPwar.wareList;
export const PproFullRegi = cataMatInstance.catbPwar.fullRegi;
export const PproPartRegi = cataMatInstance.catbPwar.partRegi;
export const PproWareCanc = cataMatInstance.catbPwar.wareCanc;
export const PproWareNist = cataMatInstance.catbPwar.wareNist;
export const MproWareList = cataMatInstance.catbMwar.wareList;
export const MproFullRegi = cataMatInstance.catbMwar.fullRegi;
export const MproPartRegi = cataMatInstance.catbMwar.partRegi;
export const MproWareCanc = cataMatInstance.catbMwar.wareCanc;
export const MproWareNist = cataMatInstance.catbMwar.wareNist;
export const ForwInfoStat = cataMatInstance.catbForw.infoStat;
export const ForwInfoRegi = cataMatInstance.catbForw.infoRegi;
export const ForwInfoCanc = cataMatInstance.catbForw.infoCanc;
export const StocMatlStat = cataMatInstance.catbStoc.matlStat;
export const StocAdjuStat = cataMatInstance.catbAdju.infoStat;
export const StocAdjuRegi = cataMatInstance.catbAdju.infoRegi;
export const StocAdjuRepo = cataMatInstance.catbAdju.infoRepo;
export const ProcloseStat = cataMatInstance.catbProc.infoStat;
export const ProcloseRegi = cataMatInstance.catbProc.infoRegi;
export const ProcloseCanc = cataMatInstance.catbProc.infoCanc;

// mfg
const cataMfgInstance = cataMfg({ acce: acce, gubu: gubu });
export const PlanInfoStat = cataMfgInstance.catbPlan.infoStat;  
export const PlanInfoRegi = cataMfgInstance.catbPlan.infoRegi;  
export const WorkOrdeRegi = cataMfgInstance.catbWork.infoRegi;
export const WorkOrdeRepo = cataMfgInstance.catbWork.infoRepo;
export const ProductsStat = cataMfgInstance.catbProd.infoStat;
export const ProductsRegi = cataMfgInstance.catbProd.infoRegi;
export const YardInfoStat = cataMfgInstance.catbYard.infoStat;
export const PopsMoniDash = cataMfgInstance.catbMoni.infoDash;
export const ShipReqeStat = cataMfgInstance.catbShip.infoStat;  
export const ShipReqeRegi = cataMfgInstance.catbShip.infoRegi;  
export const ShipReqeChan = cataMfgInstance.catbShip.infoChan;  

// qci
const cataQciInstance = cataQci({ acce: acce, gubu: gubu });
export const ProsQcisStat = cataQciInstance.catbPros.qcisStat;
export const ProsQcisRegi = cataQciInstance.catbPros.qcisRegi;
export const ProsQcisChan = cataQciInstance.catbPros.qcisChan;
export const RoutQcisStat = cataQciInstance.catbRout.qcisStat;
export const RoutQcisRegi = cataQciInstance.catbRout.qcisRegi;
export const RoutQcisChan = cataQciInstance.catbRout.qcisChan;
export const ShipQcisStat = cataQciInstance.catbShip.qcisStat;
export const ShipQcisRegi = cataQciInstance.catbShip.qcisRegi;
export const ShipQcisChan = cataQciInstance.catbShip.qcisChan;

// equ
const cataEquInstance = cataEqu({ acce: acce, gubu: gubu });
export const EquiInfoStat = cataEquInstance.catbEqui.infoStat;
export const MainInfoRegi = cataEquInstance.catbMain.infoRegi;
export const MainInfoHist = cataEquInstance.catbMain.infoHist;
export const PremListRegi = cataEquInstance.catbPrem.listRegi;
export const PremResuStat = cataEquInstance.catbPrem.resuStat;
export const PremResuRegi = cataEquInstance.catbPrem.resuRegi;
export const PremResuChan = cataEquInstance.catbPrem.resuChan;
export const CaliInfoRegi = cataEquInstance.catbCali.infoRegi;
export const CaliInfoHist = cataEquInstance.catbCali.infoHist;

// biz
const cataBizInstance = cataBiz({ acce: acce, gubu: gubu });
export const AserInfoRegi = cataBizInstance.catbAser.infoRegi;
export const AserCompRegi = cataBizInstance.catbComp.infoRegi;
export const AserPartRegi = cataBizInstance.catbPart.infoRegi;
export const AserAccoStat = cataBizInstance.catbAcco.infoStat;
export const AserAccoRegi = cataBizInstance.catbAcco.infoRegi;
export const AserAccoChan = cataBizInstance.catbAcco.infoChan;

// els
const cataElsInstance = cataEls({ acce: acce, gubu: gubu });
export const SalePursDash = cataElsInstance.catbSalePurs.dashStat;
export const OrdeInfoDash = cataElsInstance.catbOrde.dashStat;
export const ShipInfoDash = cataElsInstance.catbShip.dashStat;
export const ProdInfoDash = cataElsInstance.catbProd.dashStat;
//export const QcisInfoDash = cataElsInstance.catbQcis.dashStat;
//export const EquiInfoDash = cataElsInstance.catbEqui.dashStat;

// sys
const cataSysInstance = cataSys({ acce: acce, gubu: gubu });
export const UserInfoRegi = cataSysInstance.catbUser.infoRegi;
export const UserPassRese = cataSysInstance.catbUser.pwrdRese;
export const UserAuthChan = cataSysInstance.catbUser.authChan;
export const HrmsInfoStat = cataSysInstance.catbHrms.infoStat;
export const KpisInfoStat = cataSysInstance.catbKpis.infoStat;
export const LogsInfoStat = cataSysInstance.catbLogs.infoStat;
export const MenuInfoStat = cataSysInstance.catbMenu.infoRegi;
export const AddrInfoStat = cataSysInstance.catbAddr.infoStat;

export { default as Profile } from '../publ/profile/UserInfo-profile';
export { default as mesHome } from './home/Home';