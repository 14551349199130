import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    item_code: '',
    item_name: '',
    matl_code: '',
    matl_name: '',
    matl_upri: 0.00,
    enab_yesn: true,
    part_memo: '',    
}

const setBiz = set.mes.biz;

export function AserPartRegiInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 제품 그리드
    const itemGridRef = useRef(null);
    const [itemData, setItemData] = useState([]);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const itemKeyExpr = 'item_code';
    // 그리드 팝업
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 그리드 조회 조건
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemGubu, setItemGubu] = useState('');
    const [itemGubuList, setItemGubuList] = useState([]);
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []);
    const itemGubuChange = useCallback((e) => setItemGubu(e), []);
    const itemGubuListChange = useCallback((e) => setItemGubuList(e), []);   

    const fetchData = useCallback(async () => {
        try {
            const [itemGubuResult] = await Promise.all([setBiz.aser.info.item.gubu()]);
            itemGubuListChange(itemGubuResult);
            itemGubuChange(itemGubuResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [itemGubuListChange, itemGubuChange]);

    // 제품 조회
    const itemHandleSearch = useCallback(async() => {
        if (itemGubu) {
            const sendData = { itemCode: itemCode, itemName: itemName, itemGubu: itemGubu };
            const res = await setBiz.aser.info.item.info(sendData);
            itemGridUpdate(res);
        }
    }, [itemCode, itemName, itemGubu, itemGridUpdate]); 

    useEffect(() => {
        itemGubu && itemHandleSearch();
    }, [itemGubu, itemHandleSearch]);

    // 제품 팝업 열릴 때
    const itemPopupOnShowing = useCallback(async() => {
        await fetchData();
        await itemHandleSearch();
    }, [fetchData, itemHandleSearch]);

    // 제품 팝업 더블 클릭
    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    const itemToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '제품구분', value: itemGubu, valueChange: itemGubuChange, search: itemHandleSearch, items: itemGubuList },
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: itemHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: itemHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: itemHandleSearch }
    ], [itemCode, itemCodeChange, itemName, itemNameChange, itemGubu, itemGubuChange, itemGubuList, itemHandleSearch]);
    
    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            matlUpri: data.matl_upri || 0.00,
            enabYesn: data.enab_yesn || false,
            partMemo: data.part_memo || '',            
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'제품 선택'}   
            type={'선택'}
            visible={itemPopupVisible}  
            shown={itemPopupOnShowing}  
            hiding={itemPopupHiding}
        >   
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, items: itemToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
            </ScrollGrid>
        </PopupGrid>                  
        <PopupForm
            title={'소모부품 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormGrid({ value: data.item_code, onClick: () => setItemPopupVisible(true), label: '제품번호', required: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', readOnly: true })}                
                {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '부품번호', required: true, maxLength: 20 })}
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '부품명', required: true })}    
                {FormNumb({ value: data.matl_upri, onValueChange: updateField('matl_upri'), label: '부품단가', length: [15, 2] })}     
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}     
            </GroupItem>
            {FormMemo({ value: data.part_memo, onValueChange: updateField('part_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}