import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    mana_numb: '',
    clie_code: '',
    clie_name: '',
    comp_seri: '',
    bill_date: fwUtil.date.getsText.date(),
    bill_kwon: 0.00,
    pays_date: fwUtil.date.getsText.date(),
    pays_kwon: 0.00,
    asac_memo: '',
};

export function AserAccoChanUpdate(props) {
    const {
        mainGrid, 
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                mana_numb: seleData.mana_numb || '',
                clie_code: seleData.clie_code || '',
                clie_name: seleData.clie_name || '',
                comp_seri: seleData.comp_seri || '',
                bill_date: seleData.bill_date || fwUtil.date.getsText.date(),
                bill_kwon: seleData.bill_kwon || 0.00,
                pays_date: seleData.pays_date || fwUtil.date.getsText.date(),
                pays_kwon: seleData.pays_kwon || 0.00,
                asac_memo: seleData.asac_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            manaNumb: data.mana_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            compSeri: data.comp_seri || '',
            billDate: data.bill_date || fwUtil.date.getsText.date(),
            billKwon: data.bill_kwon || 0.00,
            paysDate: data.pays_date || fwUtil.date.getsText.date(),
            paysKwon: data.pays_kwon || 0.00,
            asacMemo: data.asac_memo || '',            
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>        
        <PopupForm
            title={'A/S 정산 변경'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.mana_numb, onValueChange: updateField('mana_numb'), label: '계약번호', required: true, disabled: true })}
                {FormText({ value: data.comp_seri, onValueChange: updateField('comp_seri'), label: '정산순번[자동입력]', disabled: true })}
                {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객번호', disabled: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', disabled: true })}
                {FormDate({ value: data.bill_date, onValueChange: updateField('bill_date'), label: '청구일자' })}
                {FormNumb({ value: data.bill_kwon, onValueChange: updateField('bill_kwon'), label: '청구금액', length: [15, 2] })}
                {FormDate({ value: data.pays_date, onValueChange: updateField('pays_date'), label: '정산일자' })}
                {FormNumb({ value: data.pays_kwon, onValueChange: updateField('pays_kwon'), label: '정산금액', length: [15, 2] })}
            </GroupItem>
            {FormMemo({ value: data.asac_memo, onValueChange: updateField('asac_memo'), label: '비고' })}
        </PopupForm>     
        </>
    );
}