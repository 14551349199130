import PproFullRegi from "./pware/FullRegi";
import PproWareCanc from "./pware/InfoCanc";
import PproWareList from "./pware/ListStat";
import PproWareNist from "./pware/NistStat";
import PproPartRegi from "./pware/PartRegi";
import MproFullRegi from "./mware/FullRegi";
import MproWareCanc from "./mware/InfoCanc";
import MproWareList from "./mware/ListStat";
import MproWareNist from "./mware/NistStat";
import MproPartRegi from "./mware/PartRegi";
import ForwInfoStat from "./forwarding/InfoStat";
import ForwInfoRegi from "./forwarding/InfoRegi";
import ForwInfoCanc from "./forwarding/InfoCanc";
import StocMatlStat from "./stock/MatlStat";
import StocItemStat from "./stock/ItemStat";
import StocAdjuStat from "./stock/AdjuStat";
import StocAdjuRegi from "./stock/AdjuRegi";
import StocAdjuRepo from "./stock/AdjuRepo";
import ProcloseStat from "./proclose/InfoStat";
import ProcloseRegi from "./proclose/InfoRegi";
import ProcloseCanc from "./proclose/InfoCanc";

const catbPwar = ({ acce, gubu, cata }) => {
    const catb = "ware";
    const comp = {
        wareList: () => (<PproWareList acce={acce} gubu={gubu} cata={cata} catb={catb} code="C21" mainTitle="입고" mainRole="현황" />),   
        fullRegi: () => (<PproFullRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C22" mainTitle="전체" mainRole="입고" subsTitle="상세" subsRole="현황" />),   
        partRegi: () => (<PproPartRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C23" mainTitle="부분" mainRole="입고" subsTitle="상세" subsRole="현황" />),   
        wareCanc: () => (<PproWareCanc acce={acce} gubu={gubu} cata={cata} catb={catb} code="C24" mainTitle="입고" mainRole="취소" />),   
        wareNist: () => (<PproWareNist acce={acce} gubu={gubu} cata={cata} catb={catb} code="C25" mainTitle="미입고" mainRole="현황" />),   
    }

    return comp;
};

const catbMwar = ({ acce, gubu, cata }) => {
    const catb = "mware";
    const comp = {
        wareList: () => (<MproWareList acce={acce} gubu={gubu} cata={cata} catb={catb} code="C41" mainTitle="입고" mainRole="현황" />),   
        fullRegi: () => (<MproFullRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C42" mainTitle="전체" mainRole="입고" subsTitle="상세" subsRole="현황" />),   
        partRegi: () => (<MproPartRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C43" mainTitle="부분" mainRole="입고" subsTitle="상세" subsRole="현황" />),   
        wareCanc: () => (<MproWareCanc acce={acce} gubu={gubu} cata={cata} catb={catb} code="C44" mainTitle="입고" mainRole="취소" />),   
        wareNist: () => (<MproWareNist acce={acce} gubu={gubu} cata={cata} catb={catb} code="C45" mainTitle="미입고" mainRole="현황" />),   
    }

    return comp;
};


const catbForw = ({ acce, gubu, cata }) => {
    const catb = "forward";
    const comp = {
        infoStat: () => (<ForwInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C51" mainTitle="출고" mainRole="현황" />),   
        infoRegi: () => (<ForwInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C52" mainTitle="출고" mainRole="등록" />),   
        infoCanc: () => (<ForwInfoCanc acce={acce} gubu={gubu} cata={cata} catb={catb} code="C53" mainTitle="출고" mainRole="취소" />),   
    }

    return comp;
};

const catbStoc = ({ acce, gubu, cata }) => {
    const catb = "stock";
    const comp = {
        matlStat: () => (<StocMatlStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C61" mainTitle="재고" mainRole="현황" />),        
        itemStat: () => (<StocItemStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C63" mainTitle="제품" mainRole="현황" />),    
    }

    return comp;
};

const catbAdju = ({ acce, gubu, cata }) => {
    const catb = "stock/adjust";
    const comp = {
        infoStat: () => (<StocAdjuStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C71" mainTitle="재고조정" mainRole="현황" />),      
        infoRegi: () => (<StocAdjuRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C72" mainTitle="재고조정" mainRole="등록" />),      
        infoRepo: () => (<StocAdjuRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="C73" mainTitle="재고조사표" mainRole="" />),      
    }

    return comp;
};

const catbProc = ({ acce, gubu, cata }) => {
    const catb = "proclose";
    const comp = {
        infoStat: () => (<ProcloseStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C81" mainTitle="마감" mainRole="현황" subsTitle="상세" subsRole="현황" />),           
        infoRegi: () => (<ProcloseRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C82" mainTitle="마감" mainRole="등록" />),           
        infoCanc: () => (<ProcloseCanc acce={acce} gubu={gubu} cata={cata} catb={catb} code="C83" mainTitle="마감" mainRole="취소" />),           
    }

    return comp;
};


export const cataMat = ({ acce, gubu }) => {
    const cata = "mat";
    const comp = {
        catbPwar: catbPwar({ acce: acce, gubu: gubu, cata: cata }),
        catbMwar: catbMwar({ acce: acce, gubu: gubu, cata: cata }),
        catbForw: catbForw({ acce: acce, gubu: gubu, cata: cata }),
        catbStoc: catbStoc({ acce: acce, gubu: gubu, cata: cata }),
        catbAdju: catbAdju({ acce: acce, gubu: gubu, cata: cata }),
        catbProc: catbProc({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}