import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getPursSelcItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/purs/select/itemgubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPproClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/pro/ppro/register/client`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPproDamd = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/ppro/register/damdname`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPproPays = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/ppro/register/payscond`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPproClosGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/ppro/register/closgubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.clos_gubu);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPproDateGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/ppro/register/dategubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.date_gubu);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/client`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproDamd = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/damdname`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproPays = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/payscond`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproTran = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/trancond`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproClosGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/closgubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.clos_gubu);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMproDateGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/pro/mpro/register/dategubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.date_gubu);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const pro = {
    purs: {
        selc: getPursSelcItemGubu
    },
    ppro: {
        clie: getPproClie,
        damd: getPproDamd,
        pays: getPproPays,
        clos: getPproClosGubu,
        date: getPproDateGubu,
    },
    mpro: {
        clie: getMproClie,
        damd: getMproDamd,
        pays: getMproPays,
        tran: getMproTran,
        clos: getMproClosGubu,
        date: getMproDateGubu,
    },
}