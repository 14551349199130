import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    work_numb: '',
    item_code: '',
    item_name: '',
    orde_qtys: 0,
    star_date: fwUtil.date.getsText.date(),
    clos_date: fwUtil.date.getsText.pDate(-30),
    item_memo: '',    
};

const setWork = set.mes.mfg.work;

export function OrdeItemRegiInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const itemGridRef = useRef(null);
    const [itemData, setItemData] = useState([]);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const itemKeyExpr = 'item_code';
    // 그리드 팝업
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 팝업 조회
    const itemHandleSearch = useCallback(async () => {
        const sendData = { itemCode: "" };
        const res = await setWork.item(sendData);
        itemGridUpdate(res);
    }, [itemGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.sKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                ...defaultData,
                work_numb: selectedRowKey,
            };
            
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            ordeQtys: data.orde_qtys || 0,
            starDate: data.star_date ? fwUtil.date.convTime.fmDate(data.star_date) : fwUtil.date.getsText.date(),
            closDate: data.clos_date ? fwUtil.date.convTime.fmDate(data.clos_date) : fwUtil.date.getsText.pDate(-30),
            itemMemo: data.item_memo || '',               
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 팝업 더블 클릭
    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name, orde_qtys: e.data.mode_qtys });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_enam", caption: "계획년월" })}
                {DeciCol({ dataField: "ypla_qtys", caption: "계획수량" })}
                {DeciCol({ dataField: "work_qtys", caption: "생산수량" })}
                {DeciCol({ dataField: "mode_qtys", caption: "지시수량" })}
            </ScrollGrid>
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'제품 선택'}
                visible={itemPopupVisible}
                shown={itemPopupOnShowing}
                hiding={itemPopupHiding}
            >
                {itemGrid}
            </PopupGrid>
            <PopupForm
                title={'작업지시 품목 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: "작업지시번호", disabled: true })}
                    </GroupItem>
                    {FormGrid({ value: data.item_code, onValueChange: updateField('item_code'), onClick: () => setItemPopupVisible(true), label: "제품번호", required: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: "제품명" })}
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormNumb({ value: data.orde_qtys, onValueChange: updateField('orde_qtys'), label: "지시수량", length: [12, 2] })}
                    </GroupItem>                    
                    {FormDate({ value: data.star_date, onValueChange: updateField('star_date'), label: "시작일자" })}
                    {FormDate({ value: data.clos_date, onValueChange: updateField('clos_date'), label: "완료일자" })}
                </GroupItem>
                {FormMemo({ value: data.item_memo, onValueChange: updateField('item_memo'), label: "비고" })}
            </PopupForm>
        </>
    );
}