export function convertShipDate(data) {
    const result = {};
    if (!data) {
        return;
    }
    data.forEach(item => {
        const dateKey = item.ship_date;

        if (!result[dateKey]) {
            result[dateKey] = {
                ship_date: dateKey,
                ship_qtys: 0,
                ship_pric: 0,
                ship_kwon: 0
            };
        }

        result[dateKey].ship_qtys += item.ship_qtys;
        result[dateKey].ship_pric += item.ship_pric;
        result[dateKey].ship_kwon += item.ship_kwon;
    });

    return Object.values(result);
}