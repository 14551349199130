export function UserInfoPublDetail() {
    return (
        <></>
    )
}
/*
import React, { useEffect, useState, useCallback } from 'react';
import { GroupItem } from 'devextreme-react/form';
import { FormDate, FormImag, FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { useScreenSize } from '../../../../../utils/media-query';
import { PanelHeader } from '../../../../script/components/detail-panel/detail-panel-header/PanelHeader';
import { PanelBody } from '../../../../script/components/detail-panel/detail-panel-body/PanelBody';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    user_idno: '',
    user_name: '',
    user_enam: '',
    tele_numb: '',
    cell_numb: '',
    faxs_numb: '',
    posi_code: '',
    posi_name: '',
    dept_code: '',
    dept_name: '',
    mail_idno: '',
    regi_date: fwUtil.date.getsText.date(),
    resi_date: null,
    pict_imag: null,
    sign_imag: null,
};

const setUser = set.mes.sys;

export function UserInfoPublDetail(props) {
    const { 
        gridRef, keyData, refresh,
        tar, upd, 
        isPanelOpened, panelOpendChange 
    } = props;
    
    const { isLarge, isMedium } = useScreenSize();
    // 패널 상태값
    const [isPinned, setIsPinned] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 부서, 직위 리스트
    const [deptList, setDeptList] = useState([]);
    const [posiList, setPosiList] = useState([]);
    const deptChange = useCallback((e) => setDeptList(e), []);
    const posiChange = useCallback((e) => setPosiList(e), []);

    useEffect(() => {
        if (isPanelOpened && upd) {
            const fetchData = async () => {
                try {
                    const [deptResult, posiResult] = await Promise.all([setUser.dept(), setUser.posi()]);
                    deptChange(deptResult);
                    posiChange(posiResult);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        }
    }, [isPanelOpened, upd, deptChange, posiChange]);
    
    const changePanelPinned = useCallback(() => {
        gridRef.current?.instance.updateDimensions();
    }, [gridRef]);

    // 핀
    useEffect(() => {
        changePanelPinned();
    }, [isPinned, changePanelPinned]);

    const onPinClick = useCallback(() => {
        setIsPinned(!isPinned);
        gridRef.current?.instance.updateDimensions();
    }, [isPinned, gridRef]);

    // 패널 닫기
    const onClosePanelClick = useCallback(() => {
        panelOpendChange();
        setIsPinned(false);
    }, [panelOpendChange]);

    // 수정 상태 전환
    const toggleEditHandler = useCallback(() => {
        setIsEditing(!isEditing);
    }, [isEditing]);

    // 선택 데이터 조회
    useEffect(() => {
        const fetchData = async () => {
            if (keyData) {
                const sendData = { userIdno: keyData };
                const res = await fwUtil.apis.search(tar.api, sendData);
                const data = {
                    user_idno: res.user_idno || '',
                    user_name: res.user_name || '',
                    user_enam: res.user_enam || '',
                    tele_numb: res.tele_numb || '',
                    cell_numb: res.cell_numb || '',
                    faxs_numb: res.faxs_numb || '',
                    posi_code: res.posi_code || '',
                    posi_name: res.posi_name || '',
                    dept_code: res.dept_code || '',
                    dept_name: res.dept_name || '',
                    mail_idno: res.mail_idno || '',
                    regi_date: res.regi_date || fwUtil.date.getsText.date(),
                    resi_date: res.resi_date || null,
                    pict_imag: res.pict_imag || null,
                    sign_imag: res.sign_imag || null,
                };
                dataUpdate(data);   
            }
        };
        fetchData();
    }, [keyData, tar, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        if (field === 'dept_name') {
            const selectedDept = deptList.find(list => list.dept_name === value);
            if (selectedDept) {
                dataUpdate({ ...data, [field]: value, dept_code: selectedDept.dept_code });
            };
            return;
        };

        if (field === 'posi_name') {
            const selectedPosi = posiList.find(list => list.posi_name === value);
            if (selectedPosi) {
                dataUpdate({ ...data, [field]: value, posi_code: selectedPosi.posi_code });
            };
            return;
        };        

        dataUpdate({ ...data, [field]: value });
    };

    // 저장 버튼 클릭
    const onSaveClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
            setIsEditing(!isEditing);    
        }
    };

    // 수정
    const onSubmit = useCallback(async () => {
        const postData = new FormData();

        postData.append('userIdno', data.user_idno || "");
        postData.append('userName', data.user_name || "");
        postData.append('userEnam', data.user_enam || "");
        postData.append('teleNumb', data.tele_numb || "");
        postData.append('cellNumb', data.cell_numb || "");
        postData.append('faxsNumb', data.faxs_numb || "");
        postData.append('posiCode', data.posi_code || "");
        postData.append('posiName', data.posi_name || "");
        postData.append('deptCode', data.dept_code || "");
        postData.append('deptName', data.dept_name || "");
        postData.append('mailIdno', data.mail_idno || null);
        postData.append('regiDate', data.regi_date || null);
        postData.append('resiDate', data.resi_date || null);
        postData.append('pictImag', data.pict_imag || null);
        postData.append('signImag', data.sign_imag || null);

        fwUtil.apis.submit(postData, upd?.api, refresh);
    }, [data, upd, refresh]);

    return (
        <div id='fw-mes-sys-user-detail-panel' className={fwUtil.conv.class({ 'fw-detail-panel': true, 'open': isPanelOpened, 'pin': isPinned && (isLarge || isMedium) })}>
            <div className='fw-panel-wrapper'>
                <PanelHeader
                    title={data.user_idno}
                    status={{
                        info: data.resi_date ? 'status status-red' : 'status status-green',
                        message: data.resi_date ? '퇴사' : '재직중',
                    }}
                    isPinned={isPinned}
                    onPinClick={onPinClick}
                    onClosePanelClick={onClosePanelClick}
                    isLarge={isLarge}
                    isMedium={isMedium}
                />
                <PanelBody
                    isEditing={isEditing}
                    toggleEditHandler={toggleEditHandler}
                    onSaveClick={onSaveClick}
                    upd={upd}
                >
                    <GroupItem colCount={2} cssClass='fw-image-row'>
                        {FormImag({ value: fwUtil.conv.to.buffToBlob(data.pict_imag), onValueChange: updateField('pict_imag'), isEditing: !isEditing, label: '사용자 이미지', labelHide: true, size: 140 })}
                        <GroupItem>
                            {FormText({ value: data.user_name, onValueChange: updateField('user_name'), isEditing: !isEditing, label: '성명[한글]', required: true })}
                            {FormText({ value: data.user_enam, onValueChange: updateField('user_enam'), isEditing: !isEditing, label: '성명[영문]' })}
                            {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), isEditing: !isEditing, label: '이메일', rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}                                                                                                                                                                                                                                               
                        </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass='contact-fields-group single-row'>
                        {FormSelc({ value: data.dept_name, onValueChange: updateField('dept_name'), isEditing: !isEditing, label: '부서', horizontalLabel: true, items: deptList, displayExpr: 'dept_name', valueExpr: 'dept_name' })}
                        {FormSelc({ value: data.posi_name, onValueChange: updateField('posi_name'), isEditing: !isEditing, label: '직급', horizontalLabel: true, items: posiList, displayExpr: 'posi_name', valueExpr: 'posi_name' })}
                        {FormText({ value: data.cell_numb, onValueChange: updateField('cell_numb'), isEditing: !isEditing, label: '휴대폰', horizontalLabel: true, mask: '000-0000-0000' })}
                        {FormText({ value: data.tele_numb, onValueChange: updateField('tele_numb'), isEditing: !isEditing, label: '전화', horizontalLabel: true, mask: '000-000-0000' })}
                        {FormText({ value: data.faxs_numb, onValueChange: updateField('faxs_numb'), isEditing: !isEditing, label: '팩스', horizontalLabel: true, mask: '000-000-0000' })}
                        {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), isEditing: !isEditing, label: '입사일', horizontalLabel: true })}
                        {FormDate({ value: data.resi_date, onValueChange: updateField('resi_date'), isEditing: !isEditing, label: '퇴사일', horizontalLabel: true })}
                        <GroupItem colCount={2}>
                            {FormImag({ value: fwUtil.conv.to.buffToBlob(data.sign_imag), onValueChange: updateField('sign_imag'), isEditing: !isEditing, label: '서명이미지', verticalLabel: true, size: 140, allowDrawing: true })}
                        </GroupItem>
                    </GroupItem>
                </PanelBody>
            </div>
        </div>
    )
};
*/