// DesiInfoRegi - 설계 정보
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { DesiInfoRegiInsert } from './InfoRegi-insert';
import { DesiInfoRegiUpdate } from './InfoRegi-update';
import { DesiInfoRegiFileMn } from './InfoRegi-fileMn';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        "item_code": "IC001",
        "item_name": "제품A",
        "publ_dept": "발행처1",
        "dist_dept": "배포부서1",
        "mana_dept": "관리부서1",
        "enab_yesn": "Y",
        "rece_date": "2024-06-10",
        "desi_memo": "비고 내용1"
    },
    {
        "item_code": "IC002",
        "item_name": "제품B",
        "publ_dept": "발행처2",
        "dist_dept": "배포부서2",
        "mana_dept": "관리부서2",
        "enab_yesn": "N",
        "rece_date": "2024-06-11",
        "desi_memo": "비고 내용2"
    },
    {
        "item_code": "IC003",
        "item_name": "제품C",
        "publ_dept": "발행처3",
        "dist_dept": "배포부서3",
        "mana_dept": "관리부서3",
        "enab_yesn": "Y",
        "rece_date": "2024-06-12",
        "desi_memo": "비고 내용3"
    },
    {
        "item_code": "IC004",
        "item_name": "제품D",
        "publ_dept": "발행처4",
        "dist_dept": "배포부서4",
        "mana_dept": "관리부서4",
        "enab_yesn": "N",
        "rece_date": "2024-06-13",
        "desi_memo": "비고 내용4"
    }
]

export default function DesiInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const gridSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/search`;       // 메인 조회 api
    const gridTargetApi = `${acce}/${gubu}/${cata}/${catb}/register/target`;       // 메인 타겟 api
    const gridInsertApi = `${acce}/${gubu}/${cata}/${catb}/register/insert`;       // 메인 정보 api
    const gridUpdateApi = `${acce}/${gubu}/${cata}/${catb}/register/update`;       // 메인 수정 api
    const gridDeleteApi = `${acce}/${gubu}/${cata}/${catb}/register/delete`;       // 메인 삭제 api
    const fileSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/file/search`;  // 파일 조회 api
    const fileInsertApi = `${acce}/${gubu}/${cata}/${catb}/register/file/insert`;  // 파일 등록 api
    const fileUpdateApi = `${acce}/${gubu}/${cata}/${catb}/register/file/update`;  // 파일 수정 api
    const fileDeleteApi = `${acce}/${gubu}/${cata}/${catb}/register/file/delete`;  // 파일 삭제 api      
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'item_code';
    const deleKey = (data) => {
        return { itemCode: data }
    };    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 정보 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    
    // 파일관리 팝업창
    const [fileManagePopupVisible, setFileManagePopupVisible] = useState(false);
    const fileManagePopupHide = useCallback(() => { setFileManagePopupVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(gridSearchApi, sendData, gridDataUpdate, gridRef);
        gridDataUpdate(tempData);
    }, [itemCode, itemName, gridDataUpdate, gridRef, gridSearchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(gridRef, gridDeleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, gridDeleteApi]);


    // 정보/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
            if (act === 'file') {
                setFileManagePopupVisible(true);
                return;
            }              
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'folder', text: '파일관리', onClick: () => handleActionClick('file') },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, handleSearch, handleActionClick]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "publ_dept", caption: "설계부서" })}
                {CommCol({ dataField: "dist_dept", caption: "배포부서" })}
                {CommCol({ dataField: "mana_dept", caption: "관리부서" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {DateCol({ dataField: "rece_date", caption: "작성일자" })}
                {CommCol({ dataField: "desi_memo", caption: "비고" })}
            </PagingGrid>
            <DesiInfoRegiFileMn
                mainGrid={gridRef}
                searchApi={fileSearchApi}
                insertApi={fileInsertApi}
                updateApi={fileUpdateApi}
                deleteApi={fileDeleteApi}
                visible={fileManagePopupVisible}
                hiding={fileManagePopupHide}
            />            
            <DesiInfoRegiInsert
                refresh={handleSearch}
                insertApi={gridInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <DesiInfoRegiUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={gridTargetApi}
                updateApi={gridUpdateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 설계 항목을 삭제하시겠습니까?'}
            />             
        </div>
    );
};