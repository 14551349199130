import { MortDetailCuriItem } from "./Mort-detail-curi";
import { MortDetailEtcsItem } from "./Mort-detail-etcs";
import "./Mort-detail.scss";

export function MortDetailItem(props) {
    const { data, number } = props;
    const remoCart = data.remo_cart === 0 ? false : true;

    return (
        <div className="fw-mort-detail-item-container fw-flex-box">
            <div className="mort-detail-cart-wrapper">
                <div className={'mort-line-name'}>{data.line_name}</div>
                <div className={`mort-line ${remoCart ? "active" : "passive"}`}>
                    <svg width="62" height="35" viewBox="0 0 62 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_114_2397)">
                            <path d="M0 0H61.711V22.26C61.711 24.8889 59.511 27.02 56.7972 27.02H4.91375C2.19996 27.02 0 24.8889 0 22.26V0Z" fill="#566A9D" />
                            <path d="M42.345 26.3203H0.722611C0.323524 26.3203 0 26.6337 0 27.0203V29.4003C0 29.7869 0.323524 30.1003 0.722611 30.1003H42.345C42.7441 30.1003 43.0676 29.7869 43.0676 29.4003V27.0203C43.0676 26.6337 42.7441 26.3203 42.345 26.3203Z" fill="#838383" />
                            <path d="M61.2774 27.0195H19.655C19.2559 27.0195 18.9324 27.3329 18.9324 27.7195V28.6995C18.9324 29.0861 19.2559 29.3995 19.655 29.3995H61.2774C61.6764 29.3995 62 29.0861 62 28.6995V27.7195C62 27.3329 61.6764 27.0195 61.2774 27.0195Z" fill="#838383" />
                            <path d="M6.93706 35.0002C9.73067 35.0002 11.9953 32.8064 11.9953 30.1002C11.9953 27.394 9.73067 25.2002 6.93706 25.2002C4.14345 25.2002 1.87878 27.394 1.87878 30.1002C1.87878 32.8064 4.14345 35.0002 6.93706 35.0002Z" fill="#333333" />
                            <path d="M6.93704 32.4797C8.29394 32.4797 9.39392 31.4142 9.39392 30.0997C9.39392 28.7853 8.29394 27.7197 6.93704 27.7197C5.58014 27.7197 4.48016 28.7853 4.48016 30.0997C4.48016 31.4142 5.58014 32.4797 6.93704 32.4797Z" fill="#F8F6F4" />
                            <path d="M17.9208 35.0002C20.7144 35.0002 22.979 32.8064 22.979 30.1002C22.979 27.394 20.7144 25.2002 17.9208 25.2002C15.1272 25.2002 12.8625 27.394 12.8625 30.1002C12.8625 32.8064 15.1272 35.0002 17.9208 35.0002Z" fill="#333333" />
                            <path d="M17.9207 32.4797C19.2776 32.4797 20.3776 31.4142 20.3776 30.0997C20.3776 28.7853 19.2776 27.7197 17.9207 27.7197C16.5638 27.7197 15.4639 28.7853 15.4639 30.0997C15.4639 31.4142 16.5638 32.4797 17.9207 32.4797Z" fill="#F8F6F4" />
                            <path d="M28.9044 35.0002C31.698 35.0002 33.9627 32.8064 33.9627 30.1002C33.9627 27.394 31.698 25.2002 28.9044 25.2002C26.1108 25.2002 23.8461 27.394 23.8461 30.1002C23.8461 32.8064 26.1108 35.0002 28.9044 35.0002Z" fill="#333333" />
                            <path d="M28.9044 32.4797C30.2613 32.4797 31.3613 31.4142 31.3613 30.0997C31.3613 28.7853 30.2613 27.7197 28.9044 27.7197C27.5476 27.7197 26.4476 28.7853 26.4476 30.0997C26.4476 31.4142 27.5476 32.4797 28.9044 32.4797Z" fill="#F8F6F4" />
                            <path d="M55.641 35.0002C58.4346 35.0002 60.6993 32.8064 60.6993 30.1002C60.6993 27.394 58.4346 25.2002 55.641 25.2002C52.8474 25.2002 50.5828 27.394 50.5828 30.1002C50.5828 32.8064 52.8474 35.0002 55.641 35.0002Z" fill="#333333" />
                            <path d="M55.641 32.4797C56.9979 32.4797 58.0979 31.4142 58.0979 30.0997C58.0979 28.7853 56.9979 27.7197 55.641 27.7197C54.2841 27.7197 53.1841 28.7853 53.1841 30.0997C53.1841 31.4142 54.2841 32.4797 55.641 32.4797Z" fill="#F8F6F4" />
                            <path d="M57.014 5.04004H7.73194C7.21312 5.04004 6.79254 5.44746 6.79254 5.95004C6.79254 6.45262 7.21312 6.86004 7.73194 6.86004H57.014C57.5328 6.86004 57.9534 6.45262 57.9534 5.95004C57.9534 5.44746 57.5328 5.04004 57.014 5.04004Z" fill="#F8F6F4" />
                        </g>
                        <defs>
                            <clipPath id="clip0_114_2397">
                                <rect width="62" height="35" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="fw-temp-curing" />
            <MortDetailCuriItem data={data} number={number} />
            <MortDetailEtcsItem data={data} />
        </div>
    )
}