const defaMortSearch = (data) => {
    const modiData = data.map(item => {
        const moltQtys = Math.floor(Math.random() * 100) + 1;
        const curiTemp = Math.floor(Math.random() * 100) + 1;
        const remoOper = Math.random() < 0.5;
        const remoMidd = Math.random() < 0.5;
        const steaOper = Math.random() < 0.5;

        return {
            ...item, molt_qtys: moltQtys, curi_temp: curiTemp, remo_oper: remoOper, remo_midd: remoMidd, stea_oper: steaOper
        };
    });

    return modiData;
};

function convertMortData(data) {
    const main = {
        mort_date: data.mort_date,
        mort_qtys: data.mort_qtys,
        emer_stat: data.emer_stat
    };
    const subs = [];
    const lineNames = ['1호기', '2호기', '3호기', '4호기', '5호기', '6호기', '7호기', '8호기'];
    
    for (let i = 0; i < 8; i++) {
        const line = {
            line_numb: i + 1,
            line_name: lineNames[i],
            remo_midd: data[`mota_midd`], 
            mold_vibr: data[`mota_vibr`], 
            remo_cart: data[`mota_cart`], 
            molt_qtys: data[`mota_qtys`], 
            curi_temp: data[`mota_temp`]
        };
        
        line.remo_midd = data[`mot${String.fromCharCode(97 + i)}_midd`];
        line.mold_vibr = data[`mot${String.fromCharCode(97 + i)}_vibr`];
        line.remo_cart = data[`mot${String.fromCharCode(97 + i)}_cart`];
        line.molt_qtys = data[`mot${String.fromCharCode(97 + i)}_qtys`];
        line.curi_temp = data[`mot${String.fromCharCode(97 + i)}_temp`];

        subs.push(line);
    }

    return { main: main, subs: subs };
}

export const moniFunc = {
    convertMortData: (data) => convertMortData(data),
    defaMortSearch: (data) => defaMortSearch(data),
};

// data
const defaultMainMort = { mort_date: '날짜 정보를 불러올 수 없습니다', erro_stat: false, molt_qtys: 0 };
const defaultSubsMort = [
    { line_numb: 1, line_name: '1호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 2, line_name: '2호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 3, line_name: '3호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 4, line_name: '4호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 5, line_name: '5호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 6, line_name: '6호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 7, line_name: '7호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
    { line_numb: 8, line_name: '8호기', remo_midd: false, mold_vibr: false, remo_cart: false, molt_qtys: 0, curi_temp: 0 },
];

const defaMixe = {
    date: "2024-07-24",
    time: "07:50",
    Bnum: 1,
    Snum: 43,
    type: "25-21-80",
    Cnum: 7437,
    m3: 6.00,
    mos1: 0.6,
    mos2: 0.5,
    s1g: 4.9,
    s2g: 5.0,
    g1: 2360,
    g2: 0,
    s1: 806,
    s2: 806,
    c1: 124,
    c2: 75,
    c3: 20,
    w1: 320,
    w2: 146,
    w3: 86,
    ad1: 0.00,
    ad2: 6.08,
    ad3: 0.00,
};

export const moniData = {
    defaultMainMort: defaultMainMort,
    defaultSubsMort: defaultSubsMort,
    defaMixe: defaMixe,
};