// ShipInfoChan - 납품 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ShipInfoChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/search`;  // 서브 조회 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/update`;  // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/delete`;  // 서브 삭제 api       
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'ship_numb';
    const subsKey = ['ship_numb', 'ship_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 수정 경고창
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, null, null);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { shipNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, null, null);
        }  else {
            subsGridDataUpdate([]);
        }
    }, [subsGridDataUpdate, mainGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        subsGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const subsSelectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = subsSelectedData.map((item) => ({
            shipDate: mainSelectedData[0].ship_date || fwUtil.date.getsText.date(),
            shipSeri: item.ship_seri,
            ordeNumb: item.orde_numb,
            itemCode: item.item_code,
            shipQtys: item.ship_qtys,
            shipPric: item.ship_pric,
            shipKwon: item.ship_kwon,
            taxsKwon: item.taxs_kwon,
            shipRemk: item.ship_remk,
        }));
        const postData = { shipNumb: mainSelectedData[0].ship_numb, setaMaps: convertdData }
        await fwUtil.apis.submit(subsUpdateApi, postData, subsHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        subsGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsUpdateAlertHide();
    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, subsUpdateAlertHide, subsUpdateApi]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        subsGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const subsSelectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = subsSelectedData.map((item) => ({
            shipDate: mainSelectedData[0].ship_date || fwUtil.date.getsText.date(),
            shipSeri: item.ship_seri,
            ordeNumb: item.orde_numb,
            itemCode: item.item_code,
            shipQtys: item.ship_qtys,
            shipPric: item.ship_pric,
            shipKwon: item.ship_kwon,
            taxsKwon: item.taxs_kwon,
            shipRemk: item.ship_remk,
        }));
        const postData = { shipNumb: mainSelectedData[0].ship_numb, setaMaps: convertdData }
        await fwUtil.apis.submit(subsDeleteApi, postData, subsHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        subsGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsDeleteAlertHide();
    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, subsDeleteAlertHide, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객사명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, clieNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);
        if (subsSelectedRowKey) {
            switch (act) {
                case'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        }

    }, [subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}          
                search={mainHandleSearch}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "납품번호" })}
                {DateCol({ dataField: "ship_date", caption: "납품일자" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DeciCol({ dataField: "item_coun", caption: "품목수" })}
                {DeciCol({ dataField: "ship_kwon", caption: "공급가액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_memo", caption: "비고" })}                
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "ship_numb", caption: "납품번호", allowEditing: false })}
                {CommCol({ dataField: "ship_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호", allowEditing: false })}
                {CommCol({ dataField: "item_code", caption: "제품번호", allowEditing: false })}
                {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                {CommCol({ dataField: "item_spec", caption: "규격", allowEditing: false })}
                {DeciCol({ dataField: "ship_qtys", caption: "납품수량", check: true, maxLength: 10 })}
                {DeciCol({ dataField: "ship_pric", caption: "납품단가", check: true, maxLength: 10 })}
                {DeciCol({ dataField: "ship_kwon", caption: "납품금액", check: true, maxLength: 10 })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액", check: true, maxLength: 10 })}
                {CommCol({ dataField: "ship_remk", caption: "특기사항", check: true })}
            </SubsGrid>  
            <UpdateAlert
                grid={subsGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 납품를 수정하시겠습니까?'}
            />                     
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 납품를 삭제하시겠습니까?'}
            />                      
        </div>
    );
};