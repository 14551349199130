// MatlInfoStat - 자재 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, NumbCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function MatlInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'matl_code';          
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [matlCode, setMatlCode] = useState("");
    const [matlName, setMatlName] = useState("");  
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);  

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);
    
    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, matlCode: matlCode, matlName: matlName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
    }, [dateValue, duraYesn, matlCode, matlName, gridDataUpdate, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '자재코드', value: matlCode, valueChange: matlCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [matlCode, matlCodeChange, matlName, matlNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "사양(가로*세로*두께)" })}
                {NumbCol({ dataField: "matl_leng", caption: "길이" })}
                {CommCol({ dataField: "matl_quli", caption: "재질" })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위" })}
                {CommCol({ dataField: "stoc_unit", caption: "저장단위" })}
                {CommCol({ dataField: "matl_gubu", caption: "품목계정" })}
                {DeciCol({ dataField: "safe_qtys", caption: "안전재고수량" })}
                {DeciCol({ dataField: "unit_weig", caption: "단위중량(m당)" })}
                {DeciCol({ dataField: "qtys_weig", caption: "단위중량(개당)" })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "matl_memo", caption: "비고" })}
            </PagingGrid>        
        </div>
    );
};