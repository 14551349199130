import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    clos_date: fwUtil.date.getsText.date(),
    proc_memo: "",
};

const procDataSource = fwUtil.grid.get.dataSource();

export function ProcloseRegiInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 발주 그리드
    const procRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            procDataSource.store().insert({ init: true }).then(() => procDataSource.reload());
            const procSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const proc of procSelected) {
                const newData = {
                    clie_code: proc.clie_code || '',
                    clie_name: proc.clie_name || '',
                    ware_date: proc.ware_date || '',
                    matl_code: proc.matl_code || '',
                    matl_name: proc.matl_name || '',
                    matl_spec: proc.matl_spec || '',
                    ware_qtys: proc.ware_qtys || 0.00,
                    ware_pric: proc.ware_pric || 0.00,
                    ware_cwon: proc.ware_cwon || 0.00,
                    ware_numb: proc.ware_numb || '',
                    work_numb: proc.work_numb || '',
                    stoc_loca: proc.stoc_loca || '',
                };
                procDataSource.store().insert(newData);
            }

            procDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const procData = procRef?.current?.instance?.getDataSource()?.items();
        const postProcData = procData.map((item) => ({
            clieCode: item.clie_code || '',
            clieName: item.clie_name || '',
            wareDate: item.ware_date || '',
            matlCode: item.matl_code || '',
            matlName: item.matl_name || '',
            matlSpec: item.matl_spec || '',
            wareQtys: item.ware_qtys || 0.00,
            warePric: item.ware_pric || 0.00,
            wareCwon: item.ware_cwon || 0.00,
            wareNumb: item.ware_numb || '',
            workNumb: item.work_numb || '',
            stocLoca: item.stoc_loca || '',
        }));
        const postData = {
            closDate: data.clos_date || fwUtil.date.getsText.date(),
            procMemo: data.proc_memo || '',
            setaMaps: postProcData || [],
        };

        const allHiding = () => {
            alertHide();
            hiding();
        }
        await fwUtil.apis.action(insertApi, postData, refresh, allHiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding, alertHide, procRef]);

    return (
        <>
            <PopupForm
                title={'매입마감 등록'}
                type={'마감'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={() => setAlertVisible(true)}
                width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : '90%'}
                additionalChild={
                    <div className='fw-additional-child-box fw-flex-box col'>
                        <AddingGrid
                            title={'매입목록'}
                            gridRef={procRef}
                            gridDataSource={procDataSource}
                        >
                            {CommCol({ dataField: "clie_name", caption: "업체명" })}
                            {DateCol({ dataField: "ware_date", caption: "입고일자" })}
                            {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                            {CommCol({ dataField: "matl_name", caption: "자재명" })}
                            {CommCol({ dataField: "matl_spec", caption: "규격" })}
                            {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                            {DeciCol({ dataField: "ware_pric", caption: "입고중량" })}
                            {DeciCol({ dataField: "ware_cwon", caption: "입고금액" })}
                            {CommCol({ dataField: "ware_numb", caption: "입고번호" })}
                            {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                            {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                        </AddingGrid>
                    </div>
                }
            >
                <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4} >
                    {FormDate({ value: data.clos_date, onValueChange: updateField('clos_date'), label: '확정일자' })}
                </GroupItem>
                {FormMemo({ value: data.proc_memo, onValueChange: updateField('proc_memo'), label: '비고' })}
            </PopupForm>
            <PopupWarn
                visible={alertVisible}
                hiding={alertHide}
                confirm={onSubmit}
                message={'해당 건들을 매입마감 처리 하시겠습니까?'}
            />
        </>
    );
}