import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommArgs } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

export function DashProdCard(props) {
    const { loading, data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
        };
    };

    return (
        <DashCard
            title={"전체 생산 현황"}
            addClass={'prod'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommArgs({ field: "prod_mont", categories: ["01","02","03","04","05","06","07","08","09","10","11","12"] })}
                {BarsSeri({ valueField: "mont_qtys", caption: "생산", color: "#4EACFF", args: "prod_mont" })}
            </CommChart>
        </DashCard>
    )
}