
import YardHomeIco from '../../../../../assets/yard/yard-ico-home.png';
import YardFactoryIco from '../../../../../assets/yard/yard-ico-factory.png';
import YardAIco from '../../../../../assets/yard/yard-ico-a.png';
import YardBIco from '../../../../../assets/yard/yard-ico-b.png';
import YardCIco from '../../../../../assets/yard/yard-ico-c.png';
import YardDIco from '../../../../../assets/yard/yard-ico-d.png';
import YardEIco from '../../../../../assets/yard/yard-ico-e.png';
import YardInfoStatPanelGrid from './InfoStat-panel-grid';

export function YardInfoStatPanel(props) {
    const { data } = props;

    return (
        <div className="yard-panel fw-flex-box row a-center">
            <YardInfoStatPanelGrid  data={data} yardName={'A:야적장'} />
            <YardInfoStatPanelGrid  data={data} yardName={'B:야적장'} />
            <YardInfoStatPanelGrid  data={data} yardName={'C:야적장'} />
            <YardInfoStatPanelGrid  data={data} yardName={'D:야적장'} />
            <YardInfoStatPanelGrid  data={data} yardName={'E:야적장'} />
            <div className='yard-icon-guider'>
                <div className='guider-header'>
                    <i className='dx-icon-map'></i>
                    <div className='guider-message'>위치 안내</div>
                </div>
                <div className='guider-body'>
                <div className='guider-item home'>
                    <div className='icon-wrapper'>
                        <img src={YardHomeIco} alt='사무실' />
                    </div>
                    <div className='icon-title'>사무실</div>
                </div>
                <div className='guider-item factory'>
                    <div className='icon-wrapper'>
                        <img src={YardFactoryIco} alt='작업장' />
                    </div>
                    <div className='icon-title'>작업장</div>
                </div>          
                <div className='guider-item yard-A'>
                    <div className='icon-wrapper'>
                        <img src={YardAIco} alt='야적장 A' />
                    </div>
                    <div className='icon-title'>야적장 A</div>
                </div>  
                <div className='guider-item yard-B'>
                    <div className='icon-wrapper'>
                        <img src={YardBIco} alt='야적장 B' />
                    </div>
                    <div className='icon-title'>야적장 B</div>
                </div>  
                <div className='guider-item yard-C'>
                    <div className='icon-wrapper'>
                        <img src={YardCIco} alt='야적장 C' />
                    </div>
                    <div className='icon-title'>야적장 C</div>
                </div>  
                <div className='guider-item yard-D'>
                    <div className='icon-wrapper'>
                        <img src={YardDIco} alt='야적장 D' />
                    </div>
                    <div className='icon-title'>야적장 D</div>
                </div>  
                <div className='guider-item yard-E'>
                    <div className='icon-wrapper'>
                        <img src={YardEIco} alt='야적장 E' />
                    </div>
                    <div className='icon-title'>야적장 E</div>
                </div>                                                                                                  
                </div>          
            </div>
        </div>
    )
}