// AserInfoRegi - A/S 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { AserInfoRegiInsert } from './InfoRegi-insert';
import { AserInfoRegiUpdate } from './InfoRegi-update';
import { fwUtil } from '../../../../script/util';                                              
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        "mana_numb": "CSAR20240001",
        "clie_code": "CLIE1001",
        "clie_name": "홍길동",
        "item_code": "ITEM0001",
        "item_name": "제품A",
        "cont_date": "2024-06-01",
        "mana_memo": "첫 번째 계약"
    },
    {
        "mana_numb": "CSAR20240002",
        "clie_code": "CLIE1002",
        "clie_name": "김철수",
        "item_code": "ITEM0002",
        "item_name": "제품B",
        "cont_date": "2024-06-02",
        "mana_memo": "두 번째 계약"
    },
    {
        "mana_numb": "CSAR20240003",
        "clie_code": "CLIE1003",
        "clie_name": "이영희",
        "item_code": "ITEM0003",
        "item_name": "제품C",
        "cont_date": "2024-06-03",
        "mana_memo": "세 번째 계약"
    },
    {
        "mana_numb": "CSAR20240004",
        "clie_code": "CLIE1004",
        "clie_name": "박민수",
        "item_code": "ITEM0004",
        "item_name": "제품D",
        "cont_date": "2024-06-04",
        "mana_memo": "네 번째 계약"
    },
    {
        "mana_numb": "CSAR20240005",
        "clie_code": "CLIE1005",
        "clie_name": "최유리",
        "item_code": "ITEM0005",
        "item_name": "제품E",
        "cont_date": "2024-06-05",
        "mana_memo": "다섯 번째 계약"
    }
];

export default function AserInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/regist/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/regist/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/regist/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/delete`;  // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'mana_numb';
    const deleKey = (data) => {
        return { manaNumb: data }
    };         
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [manaNumb, setAserNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const manaNumbChange = useCallback((e) => { setAserNumb(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { manaNumb: manaNumb, clieName: clieName };
        //await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
        gridDataUpdate(tempData);
    }, [manaNumb, clieName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [manaNumb, manaNumbChange, clieName, clieNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />            
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "cont_date", caption: "계약일자" })}
                {CommCol({ dataField: "mana_memo", caption: "비고" })}
            </PagingGrid>
            <AserInfoRegiInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <AserInfoRegiUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 A/S 항목을 삭제하시겠습니까?'}
            />             
        </div>
    );
};