import SalePursDash from "./sale-purs/Dash";
import OrdeInfoDash from "./order/Dash";
import ShipInfoDash from "./ship/Dash";
import ProdInfoDash from "./prod/Dash";
import MatlStocDash from "./matl-stoc/Dash";
import ItemStocDash from "./item-stoc/Dash";

const catbSalePurs = ({ acce, gubu, cata }) => {
    const catb = "salepurs";
    const comp = {
        dashStat: () => (<SalePursDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q1" title="매출/매입 현황" />),
    }

    return comp;
};

const catbOrde = ({ acce, gubu, cata }) => {
    const catb = "order";
    const comp = {
        dashStat: () => (<OrdeInfoDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q21" title="수주 현황" />),
    }

    return comp;
};

const catbShip = ({ acce, gubu, cata }) => {
    const catb = "ship";
    const comp = {
        dashStat: () => (<ShipInfoDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q22" title="납품 현황" />),
    }

    return comp;
};

const catbProd = ({ acce, gubu, cata }) => {
    const catb = "product";
    const comp = {
        dashStat: () => (<ProdInfoDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q3" title="생산 현황" />),
    }

    return comp;
};

const catbStoc = ({ acce, gubu, cata }) => {
    const catb = "stock";
    const comp = {
        matlStat: () => (<MatlStocDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q4" title="자재재고 현황" />),
        itemStat: () => (<ItemStocDash acce={acce} gubu={gubu} cata={cata} catb={catb} code="Q5" title="제품재고 현황" />),        
    }

    return comp;
};

export const cataEls = ({ acce, gubu }) => {
    const cata = "els"
    const comp = {
        catbSalePurs: catbSalePurs({ acce: acce, gubu: gubu, cata: cata }),
        catbOrde: catbOrde({ acce: acce, gubu: gubu, cata: cata }),
        catbShip: catbShip({ acce: acce, gubu: gubu, cata: cata }),
        catbProd: catbProd({ acce: acce, gubu: gubu, cata: cata }),
        catbStoc: catbStoc({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}