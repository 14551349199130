import AserInfoRegi from "./aser/InfoRegi";
import AserCompRegi from "./comp/InfoRegi";
import AserPartRegi from "./part/InfoRegi";
import AserAccoStat from "./acco/InfoStat";
import AserAccoRegi from "./acco/InfoRegi";
import AserAccoChan from "./acco/InfoChan";

const catbAser = ({ acce, gubu, cata }) => {
    const catb = "aser";
    const comp = {
        infoRegi: () => (<AserInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N1" mainTitle="A/S" mainRole="관리" />),
    }

    return comp;
};

const catbComp = ({ acce, gubu, cata }) => {
    const catb = "comp";
    const comp = {
        infoRegi: () => (<AserCompRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N2" mainTitle="운영" mainRole="점검" subsTitle="운영" subsRole="상세" />),
    }

    return comp;
};

const catbPart = ({ acce, gubu, cata }) => {
    const catb = "part";
    const comp = {
        infoRegi: () => (<AserPartRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N3" mainTitle="소모부품" mainRole="관리" />),
    }

    return comp;
};

const catbAcco = ({ acce, gubu, cata }) => {
    const catb = "acco";
    const comp = {
        infoStat: () => (<AserAccoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="N41" mainTitle="정산" mainRole="현황" />),
        infoRegi: () => (<AserAccoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N42" mainTitle="정산" mainRole="관리" />),
        infoChan: () => (<AserAccoChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="N43" mainTitle="정산" mainRole="변경" />),
    }

    return comp;
};

export const cataBiz = ({ acce, gubu }) => {
    const cata = "biz"
    const comp = {
        catbAser: catbAser({ acce: acce, gubu: gubu, cata: cata }),
        catbComp: catbComp({ acce: acce, gubu: gubu, cata: cata }),
        catbPart: catbPart({ acce: acce, gubu: gubu, cata: cata }),
        catbAcco: catbAcco({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}