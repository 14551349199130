import React, { useCallback, useState } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, { GroupItem } from 'devextreme-react/form';
import { FileManager, Resizable, TabPanel } from 'devextreme-react';
import { Column, ContextMenu, Details, FileSelectionItem, Item, ItemView, Permissions, Toolbar } from 'devextreme-react/cjs/file-manager';
import { Button, Popup, ScrollView, ValidationGroup } from 'devextreme-react';
import { LicenseComp, licenseTabData, PopupToolbar } from './popup-item';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { FormPass } from '../form/form-item';
import { fwUtil } from '../../util';
import './popup.scss';
import { useScreenSize } from '../../../../utils/media-query';

// 알림 다이얼로그
const PopupAler = (props) => {
    const {
        grid,
        visible, hiding, confirm,
        message,
    } = props;

    const [alertData, setAlertData] = useState([]);

    const onShowing = () => {
        if (grid) {
            const selectedData = fwUtil.grid.get.sKey(grid);
            if (selectedData) {
                setAlertData(fwUtil.data.alerts(selectedData));
            } else {
                setAlertData([]);
                hiding();
            }
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-alert-popup' }}
            onShowing={onShowing}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-flex-box col">
                {alertData && alertData.map((item, index) => (
                    <span className="alert-key" key={index}>
                        {typeof item === 'object'
                            ? Object.values(item).map((v, i) => <span key={i}>{v} </span>)
                            : item
                        }
                    </span>
                ))}
                <span className="alert-message">{message}</span>
                <div className="alert-button-wrapper fw-flex-box row">
                    <Button
                        text="확인"
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    <Button
                        text="취소"
                        className="fw-cancel-button"
                        stylingMode="outlined"
                        onClick={hiding}
                    />
                </div>
            </div>
        </Popup>
    );
};

// 경고 다이얼로그
const PopupWarn = (props) => {
    const {
        warning,
        visible, hiding, confirm, confirmText,
        message, closeHide
    } = props;

    const confirmBtnText = confirmText || "확인";
    const hideOnOutsideClick = fwUtil.conv.tern(true, !closeHide);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-warning-popup' }}
            hideOnOutsideClick={hideOnOutsideClick}
        >
            <div className="fw-popup-content fw-flex-box col">
                <span className="warning-key">
                    {warning}
                </span>
                <span className="warning-message">{message}</span>
                <div className="warning-button-wrapper fw-flex-box row">
                    <Button
                        text={confirmBtnText}
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    {!closeHide &&
                        <Button
                            text="취소"
                            className="fw-cancel-button"
                            stylingMode="outlined"
                            onClick={hiding}
                        />
                    }
                </div>
            </div>
        </Popup>
    );
};

// 주소 다이얼로그
const PopupAddr = (props) => {
    const {
        visible, hiding, confirm
    } = props;
    const oncomplete = (data) => {
        hiding();
        confirm(data);
    };

    if (!visible) {
        return;
    }

    return (
        <>
            <Popup
                title='주소찾기'
                visible={visible}
                onHiding={hiding}
                width={'auto'}
                height={'auto'}
                showCloseButton={true}
                wrapperAttr={{ class: 'fw-daum-popup' }}
                hideOnOutsideClick
            >
                <div className="fw-popup-content">
                    <DaumPostcodeEmbed
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        onComplete={oncomplete}
                    />
                </div>
            </Popup>
        </>
    );
};

// 비밀번호 변경 다이얼로그
const PopupPass = (props) => {
    const {
        width,
        data, updateField,
        visible, showing, hiding,
    } = props;

    const passApi = '/privat/mes/sys/pass/set/change'

    const onSubmit = useCallback(async () => {
        //비밀번호 변경 로직
        const postData = {
            currPass: data.curr_pass || '',
            passWord: data.pass_word || '',
        }
        const res = await fwUtil.apis.submit(passApi, postData, null, 'status');
        if (res) {
            hiding();
        }
    }, [data, hiding]);

    // 동작 버튼 클릭
    const confirm = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            title='비밀번호 변경'
            visible={visible}
            onShowing={showing}
            onHiding={hiding}
            width={width}
            height={'auto'}
            maxWidth={'100%'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-pass-popup' }}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-flex-box col">
                <ValidationGroup>
                    <Form>
                        <GroupItem cssClass='fw-current-password-wrapper'>
                            {FormPass({ value: data.curr_pass, onValueChange: updateField('curr_pass'), label: '현재 비밀번호', required: true })}
                        </GroupItem>
                        {FormPass({ value: data.pass_word, onValueChange: updateField('pass_word'), label: '비밀번호', required: true })}
                        {FormPass({ value: data.conf_pass, onValueChange: updateField('conf_pass'), label: '비밀번호 확인', required: true, confirm: true, password: data.pass_word })}
                    </Form>
                </ValidationGroup>
                <PopupToolbar
                    type={'수정'}
                    onSubmit={confirm}
                    onClose={hiding}
                />
            </div>
        </Popup>
    );
};

const PopupForm = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, additionalChild, warning
    } = props;

    const { isXSmall, isSmall } = useScreenSize();

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);
    const height = isXSmall || isSmall ? '100%' : 'auto';
    const maxHeight = isXSmall || isSmall ? '100%' : '95%';

    // 동작 버튼 클릭
    const onActionClick = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={height}
            maxHeight={maxHeight}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup>
                    <div className={'fw-popup-content fw-form-popup fw-flex-box col'}>
                        <div className={`fw-popup-form fw-flex-box col`}>
                            <Form>
                                {children}
                            </Form>
                            <div className={'fw-additional-child-wrapper'}>
                                {additionalChild}
                            </div>
                        </div>
                        {warning &&
                            <div className={'fw-popup-warning-message'}>
                                {warning}
                            </div>
                        }
                        {onSubmit &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupGrid = (props) => {
    const {
        loading,
        title, type, width, maxHeight,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children,
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={maxHeight}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <div className={'fw-popup-content fw-grid-popup fw-flex-box col'}>
                {children}
                {type &&
                    <PopupToolbar
                        type={type}
                        onSubmit={onActionClick}
                        onClose={hiding}
                    />
                }
            </div>
        </Popup>
    )
};

const PopupFile = (props) => {
    const {
        title, width, loading, setLoading,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
        fileManagerRef, fileSystemProvider, allowedFileExtensions, rootFileName,
        onFileUploading, onItemDeleting, onItemRenaming
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(false, renderDefer);

    const onShown = useCallback(async () => {
        shown && shown();
        await fileManagerRef?.current?.instance?.repaint();
        await setLoading(false);
    }, [shown, fileManagerRef, setLoading]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={onShown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible />
                </div>
            }
            <div className='fw-file-manager-wrapper'>
                <FileManager
                    ref={fileManagerRef}
                    fileSystemProvider={fileSystemProvider}
                    allowedFileExtensions={allowedFileExtensions}
                    rootFolderName={rootFileName}
                    onFileUploading={onFileUploading}
                    onItemDeleting={onItemDeleting}
                    onItemRenaming={onItemRenaming}
                >
                    <Permissions
                        delete
                        rename
                        upload
                        download
                    />
                    <ItemView showParentFolder={false}>
                        <Details>
                            <Column dataField="thumbnail" />
                            <Column dataField="name" caption='파일명' />
                            <Column dataField="dateModified" caption='수정일자' />
                            <Column dataField="size" caption='크기' />
                        </Details>
                    </ItemView>
                    <Toolbar>
                        <Item name="upload" text='업로드' options={{ hint: "Shift 또는 Ctrl로 다중선택" }} />
                        <Item name="refresh" options={{ hint: "새로고침" }} />
                        <Item
                            name="separator"
                            location="after"
                        />
                        <Item name="switchView" />
                        <FileSelectionItem name="rename" text='이름변경' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="delete" text='삭제' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="download" text='다운로드' />
                        <FileSelectionItem name="clearSelection" text='선택헤제' />
                    </Toolbar>
                    <ContextMenu>
                        <Item name="rename" text='이름변경' beginGroup />
                        <Item name="delete" text='삭제' />
                        <Item name="download" text='다운로드' />
                    </ContextMenu>
                </FileManager>
            </div>
        </Popup>
    )
};

const PopupRepo = (props) => {
    const {
        title, width, loading, pdfUrl,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
            wrapperAttr={{ class: 'fw-report-popup' }}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <Resizable
                id='pdfView'
                minWidth={300}
                minHeight={400}
                keepAspectRatio={false}
            >
                {pdfUrl ? (
                    <object data={pdfUrl} type="application/pdf" className='fw-pdf-object'>
                        <div className='pdf-erro-container'>
                            <i className='dx-icon-warning' />
                            <p className='pdf-erro-mes'>PDF파일을 보시려면 PDF 뷰어가 필요합니다.</p>
                        </div>
                    </object>
                ) : (
                    <div className='pdf-erro-container'>
                        <p>
                            PDF 파일을 불러오는 중입니다.<br />
                            응답이 없으면 다시 시도해주세요.
                        </p>
                    </div>
                )}
            </Resizable>
        </Popup>
    )
};

const PopupLice = (props) => {
    const {
        fullSize, loading,
        visible, hiding,
        outSideClick, closeButton, renderDefer,
    } = props;

    const width = fullSize ? '100%' : 500;
    const height = fullSize ? '100%' : 'auto';
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    const itemTitleRender = (item) => <span>{item.titlName || '항목없음'}</span>;

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            title={'앱 정보'}
            width={width}
            height={height}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
            wrapperAttr={{ class: 'fw-app-info-popup' }}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <div className='app-info-public'>
                <div className='company-logo'></div>
                <div className='app-info'>
                    <div className='app-title'>SEAGI - SYSTEM</div>
                    <div className='app-detail'>세기개발 MES-APS 통합시스템</div>
                </div>
            </div>
            <TabPanel
                dataSource={licenseTabData}
                itemTitleRender={itemTitleRender}
                itemComponent={LicenseComp}
                animationEnabled
                swipeEnabled
            />
        </Popup>
    )
};

export {
    PopupAler,
    PopupWarn,
    PopupAddr,
    PopupPass,
    PopupForm,
    PopupGrid,
    PopupFile,
    PopupRepo,
    PopupLice,
};