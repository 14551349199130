import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getEquiGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/equ/equi/register/gubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMainEqui = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/equ/maint/register/equicode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;

    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMainClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/equ/maint/register/vendor/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPremDamd = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/equ/prem/premdamd/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getCaliEqui = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/equ/calib/register/equicode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getCaliClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/equ/calib/register/vendor/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const equ = {
    equi: {
        gubu: getEquiGubu
    },
    main: {
        equi: getMainEqui,
        clie: getMainClie,
    },
    prem: {
        damd: getPremDamd,
    },
    cali: {
        equi: getCaliEqui,
        clie: getCaliClie,
    },
}