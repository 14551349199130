import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { fwUtil } from '../focuswin/script/util';

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [_userMenu, setMenu] = useState([]);

  useEffect(() => {
    (async function () {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
        setMenu(userResult.userMenu);
      };
      setLoading(false);
    })();
  }, []);

  const getMenu = useCallback(() => _userMenu, [_userMenu]);

  const getDetailAuth = useCallback((gubu, code) => {
    const userAuthData = _userMenu.filter(item => item.menu_gubu === gubu && item.menu_code === code);
    if (!userAuthData || userAuthData.length === 0) {
      return [{ auth_acce: false }]
    }
    return userAuthData;
  }, [_userMenu]);

  const authCheck = async (setLoading, setAuthList, gubu, code, pass) => {
    const authData = await getDetailAuth(gubu, code)[0];
    if (!authData.auth_acce) {
      if (pass) {
        const tempAuthData = {
          auth_inse: 0,
          auth_upda: 0,
          auth_dele: 0,
          auth_uplo: 0,
          auth_dnlo: 0,
        }
        setAuthList && setAuthList(tempAuthData);
        setLoading(false);
        return;
      }
        navigate('/home');
        fwUtil.aler.toast.erro('잘못된 접근입니다');
        return;
    }
    setAuthList && setAuthList(authData);
    setLoading(false);
  };  

  // 로그인
  const signIn = useCallback(async (userIdno, passWord) => {
    const result = await sendSignInRequest(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      setMenu(result.userMenu);
    }
    return result;
  }, []);

  // 로그아웃
  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
      sessionStorage.removeItem('gubu');
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading, getMenu, authCheck }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
