// PlanInfoRegi - 생산계획 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid, } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PlanInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/header/search`; // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/search`; // 서브 조회 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/register/update`;        // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/register/delete`;        // 서브 삭제 api
    // 시스템    
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const subsKey = ['ypla_year', 'ypla_mont'];
    const subsDeleKey = (data) => {
        return { 
            yplaYear: data.ypla_year, 
            clieCode: data.clie_code, 
            itemCode: data.item_code 
        }
    };
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);    
    // 검색 조건
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 수정 팝업창
    const [updateAlertVisible, setUpdateAlertVisible] = useState(false);
    const updateAlertHide = useCallback(() => { setUpdateAlertVisible(false); }, []);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { itemName: itemName, itemCode: itemCode };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate);
    }, [itemName, itemCode, mainSearchApi, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.sData(mainGridRef)?.item_code;
        if (keyData) {
            const sendData = { itemCode: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객명', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, mainHandleSearch]);

    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        const selctedData = fwUtil.grid.get.sData(subsGridRef);
        if (selctedData) {
            const sendData = {
                yplaYear: selctedData.ypla_year || '',
                yplaRevi: selctedData.ypla_revi || '',
                itemCode: selctedData.item_code || '',
                yplaMont: selctedData.ypla_mont || '',
                yplaQtys: selctedData.ypla_qtys || 0,
                yplaRemk: selctedData.ypla_remk || '',
                yplaMemo: selctedData.ypla_memo || '',                
            }
            await fwUtil.apis.submit(subsUpdateApi, sendData);
        } else {
            fwUtil.aler.toast.a1Erro();
        };
        updateAlertHide();
        subsHandleSearch();
    }, [subsGridRef, subsUpdateApi, updateAlertHide, subsHandleSearch]);    

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(subsDeleteApi, subsGridRef, subsDeleKey, subsHandleSearch);
        deleteAlertHide();
    }, [subsDeleteApi, subsGridRef, subsHandleSearch, deleteAlertHide]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdateAlertVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {DeciCol({ dataField: "deli_qtys", caption: "납품수량" })}
                {DeciCol({ dataField: "mode_qtys", caption: "미납수량" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
            </MainGrid>   
            <UpdateAlert 
                grid={subsGridRef}
                visible={updateAlertVisible}
                hiding={updateAlertHide}
                confirm={onUpdateClick}
                message={'해당 생산계획을 수정하시겠습니까?'}
            />
            <DeleteAlert 
                grid={mainGridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 생산계획을 삭제하시겠습니까?'}
            />       
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                edit
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: true }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: 'ypla_year', caption: '생산년도', allowEditing: false })}
                {CommCol({ dataField: 'ypla_mont', caption: '생산월', allowEditing: false })}
                {DeciCol({ dataField: 'ypla_qtys', caption: '계획수량', check: true })}
                {CommCol({ dataField: 'ypla_remk', caption: '특기사항', check: true })}
                {CommCol({ dataField: 'ypla_memo', caption: '비고', check: true })}                
            </SubsGrid>                                    
        </div>
    );
};