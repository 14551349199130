export function MortDetailEtcsItem(props) {
    const { data } = props;
    const remoMiddStat = data.remo_midd === 0 ? false : true;
    const moldVibrStat = data.mold_vibr === 0 ? false : true;
    const remoCartStat = data.remo_cart === 0 ? false : true;
    const mortQtys = data.molt_qtys || 0;

    return (
        <div className="mort-detail-etcs-wrapper fw-flex-box col">
            <div className="mort-stat-list fw-flex-box-col">
                <div className={`stat-remo-oper ${remoMiddStat ? "active" : "passive"}`} title={remoMiddStat ? "리모트 중간대차 제어권 사용중" : "리모트 중간대차 제어권 미사용"}>
                    <span>중간대차</span>
                </div>
                <div className={`stat-remo-midd ${moldVibrStat ? "active" : "passive"}`} title={moldVibrStat ? "제조라인 몰드진동 가동중" : "제조라인 몰드진동 미가동"}>
                    <span>몰드진동</span>
                </div>
                <div className={`stat-stea-oper ${remoCartStat ? "active" : "passive"}`} title={remoCartStat ? "리모트 몰탈투입기 대차 제어권 사용중" : "리모트 몰탈투입기 대차 제어권 미사용"}>
                    <span>몰탈투입기</span>
                </div>
            </div>
            <div className="mort-qtys">
                <div className="mort-qtys-label">몰탈투입수량</div>
                <div className="mort-qtys-input">{mortQtys}</div>
            </div>
        </div>
    )
}