import React from "react";
import { ScrollView } from "devextreme-react";
import { MixerDetailItem } from "./item/Moni-detail-item";
import "./infoDash-mixers.scss";
import { MixeGaugeItem } from "./item/Moni-gauge-item";

function convertSettingValue(data) {
    const strData = String(data);

    if (/^[0-9]{2}$/.test(strData)) {
        return `${strData[0]}/${strData[1]}`;
    } else {
        return '-/-';
    }
}

export function EquiMonitorDashMixers(dashData) {

    if (!dashData) {
        return (
            <div className="fw-page-tab-body">
                <div className="erro-message">
                    표시할 데이터가 없습니다
                </div>
            </div>
        )
    }

    return (
        <div className="fw-page-tab-body">
            <ScrollView>
                <div id="fw-equ-moni-page" className="fw-flex-box col mixer">
                    <div className="mixers-gauge-wrapper">
                        <MixeGaugeItem
                            type={{ en: 'gravel', kr: '자갈' }}
                            value={[{ sets: dashData.data_0gs1, acts: dashData.data_0gg1 }, { sets: dashData.data_0gs2, acts: dashData.data_0gg2 }]}
                            scale={{ start: 0, end: 10000, tick: 1000 }}
                            color={'#A9A9A9'}
                        />
                        <MixeGaugeItem
                            type={{ en: 'stone', kr: '석분' }}
                            value={[{ sets: dashData.data_0ss1, acts: dashData.data_0sg1 }]}
                            scale={{ start: 0, end: 10000, tick: 1000 }}
                            color={'#DCDCDC'}
                        />
                        <MixeGaugeItem
                            type={{ en: 'cement', kr: '시멘트' }}
                            value={[{ sets: dashData.data_0cs1, acts: dashData.data_0cg1 }, { sets: dashData.data_0cs2, acts: dashData.data_0cg2 }]}
                            scale={{ start: 0, end: 2000, tick: 200 }}
                            color={'#B0BEC5'}
                        />
                        <MixeGaugeItem
                            type={{ en: 'water', kr: '물' }}
                            value={[{ sets: dashData.data_0ws1, acts: dashData.data_0wg1 }, { sets: dashData.data_0ws2, acts: dashData.data_0wg2 }]}
                            scale={{ start: 0, end: 1000, tick: 100 }}
                            color={'#00BFFF'}
                        />
                        <MixeGaugeItem
                            type={{ en: 'admixture', kr: '혼합재' }}
                            value={[{ sets: dashData.data_ads1, acts: dashData.data_adg1 }, { sets: dashData.data_ads2, acts: dashData.data_adg2 }]}
                            scale={{ start: 0, end: 20, tick: 2 }}
                            color={'#800080'}
                        />
                    </div>
                    <div className="mixers-detail-wrapper">
                        <MixerDetailItem label={"날짜"} value={dashData.data_date || '-'} />
                        <MixerDetailItem label={"시간"} value={dashData.data_time || '-'} />
                        <MixerDetailItem label={"배합코드"} value={dashData.data_code || '-'} />
                        <MixerDetailItem label={"배합규격"} value={dashData.data_desc || '-'} />
                        <MixerDetailItem label={"생산루베수"} value={dashData.data_roub || '-'} />
                        <MixerDetailItem label={"계량/설정횟수"} value={convertSettingValue(dashData.data_sett)} />
                        <MixerDetailItem label={"차량번호"} value={dashData.data_cars || '-'} />
                    </div>
                </div>
            </ScrollView>
        </div>
    )
}