import {
    CircularGauge,
    Scale as CircleScale,
    Label as CircleLabel,
    RangeContainer as CircleRangeContainer,
    Range as CircleRange,
    Tooltip as CircleTooltip,
    Geometry as CircleGeometry,
} from 'devextreme-react/circular-gauge';
import {
    LinearGauge,
    Scale as LineScale,
} from 'devextreme-react/linear-gauge';
import './gauge-item.scss'

const CircleGauge = (props) => {
    const {
        value, id,
        width, height,
        start, end, range,
        weight, customCenter, indicator,
        angle, label, rangeColor,
        customizeTooltip,
    } = props;

    return (
        <div
            className='fw-circle-gauge-wrapper'
        >
            <CircularGauge
                value={value || 0}
                width={width}
                height={height}
                id={`${id}-gauge`}
                centerRender={customCenter}
            >
                <CircleScale
                    startValue={start}
                    endValue={end}
                    tick={false}
                >
                    <CircleLabel useRangeColors visible={label} />
                </CircleScale>
                {angle && angle.length &&
                    <CircleGeometry
                        startAngle={angle[0]}
                        endAngle={angle[1]}
                    />
                }
                {indicator && indicator}
                <CircleRangeContainer palette="Material" width={weight} backgroundColor={rangeColor ? rangeColor : '#808080'}>
                    {range && range.length > 0 && range.map((item, index) => (
                        <CircleRange
                            key={index}
                            startValue={item.start}
                            endValue={item.end}
                        />
                    ))}
                </CircleRangeContainer>
                <CircleTooltip
                    enabled
                    customizeTooltip={customizeTooltip}
                />
            </CircularGauge>
        </div>
    )
};

const LineGauge = (props) => {
    const { value, subValue, scale, children, width, height } = props;

    return (
        <LinearGauge
            value={value || 0}
            subvalues={subValue}
            geometry={{
                orientation: 'vertical',
            }}
            size={{
                width: width,
                height: height
            }}
        >
            <LineScale
                startValue={scale?.start || 0}
                endValue={scale?.end}
                tickInterval={scale?.tick}
            >
            </LineScale>
            {children}
        </LinearGauge>
    )
}

export {
    CircleGauge,
    LineGauge
};