// ForwInfoRegi - 출고 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as InsertAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { set } from '../../../../../api/set';

const setMat = set.mes.mat;

export default function ForwInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/regist/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/regist/insert`;  // 등록 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['matl_code', 'stoc_loca'];      
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [cateName, setCateName] = useState("선택하세요");
    const [stocLoca, setStocLoca] = useState("선택하세요");
    const [matlName, setMatlName] = useState("");
    const [cateNameList, setCateNameList] = useState("");
    const [stocLocaList, setStocLocaList] = useState("");    
    const cateNameChange = useCallback((e) => { setCateName(e) }, []);
    const stocLocaChange = useCallback((e) => { setStocLoca(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const cateNameListChange = useCallback((e) => { setCateNameList(e) }, []);
    const stocLocaListChange = useCallback((e) => { setStocLocaList(e) }, []);
    // 등록 팝업창
    const [insertAlertVisible, setInsertAlertVisible] = useState(false);
    const insertAlertHide = useCallback(() => { setInsertAlertVisible(false); }, []);   

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [cateResult, stocResult] = await Promise.all([setMat.forw.cateList(), setMat.forw.stocLoca()]);
                if (cateResult && cateResult.length > 0) {
                    cateNameListChange(cateResult);
                    cateNameChange(cateResult[0]);
                }                
                if (stocResult && stocResult.length > 0) {
                    stocLocaListChange(stocResult);
                    stocLocaChange(stocResult[0]);
                }                            
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [cateNameChange, stocLocaChange, cateNameListChange, stocLocaListChange]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { cateName: cateName, stocLoca: stocLoca, matlName: matlName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
    }, [cateName, stocLoca, matlName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '븐류', value: cateName, valueChange: cateNameChange, search: handleSearch, items: cateNameList },
        { type: 'selc', locate: 'before', caption: '저장위치', value: stocLoca, valueChange: stocLocaChange, search: handleSearch, items: stocLocaList },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [cateName, stocLoca, matlName, cateNameChange, stocLocaChange, matlNameChange, handleSearch, cateNameList, stocLocaList]);

    // 등록 경고 확인 클릭
    const onInsertClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        console.log(selectedData)
        if (!selectedData || selectedData.length < 1) {
            fwUtil.aler.toast.nsWarn();
            return;
        }
        const convertdData = selectedData.map((item) => ({
            matlCode: item.matl_code || '',
            stocLoca: item.stoc_loca || '',
            forwDate: fwUtil.date.getsText.date(),
            forwQtys: item.forw_qtys || 0.00,
            forwWeig: item.forw_weig || 0.000,
            forwMemo: item.forw_memo || '',            
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(insertApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        insertAlertHide();
    }, [gridRef, handleSearch, insertAlertHide, insertApi]);

    const onInsertBtnsClick = useCallback(() => {
        const selectedData = fwUtil.grid.get.mData(gridRef);
        if (!selectedData || selectedData.length < 1) {
            fwUtil.aler.toast.nsWarn();
            return;
        }
        setInsertAlertVisible(true);
    }, []);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                ins={{ onClick: onInsertBtnsClick, disabled: authList.auth_inse === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재명", allowEditing: false })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치", allowEditing: false })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량", allowEditing: false })}
                {DeciCol({ dataField: "stoc_weig", caption: "재고중량", allowEditing: false })}
                {DeciCol({ dataField: "forw_qtys", caption: "불출수량", check: true })}
                {DeciCol({ dataField: "forw_weig", caption: "불출중량", check: true })}
                {CommCol({ dataField: "forw_memo", caption: "비고", check: true })}
            </PagingGrid>
            <InsertAlert
                grid={gridRef}
                visible={insertAlertVisible}
                hiding={insertAlertHide}
                confirm={onInsertClick}
                message={'해당 항목을 등록하시겠습니까?'}
            />           
        </div>
    );
}; 