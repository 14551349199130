export function convertOrderDate(data) {
    const result = {};
    if (!data) {
        return;
    }
    data.forEach(item => {
        const dateKey = item.orde_date;

        if (!result[dateKey]) {
            result[dateKey] = {
                orde_date: dateKey,
                orde_qtys: 0,
                orde_pric: 0,
                orde_kwon: 0
            };
        }

        result[dateKey].orde_qtys += item.orde_qtys;
        result[dateKey].orde_pric += item.orde_pric;
        result[dateKey].orde_kwon += item.orde_kwon;
    });

    return Object.values(result);
}