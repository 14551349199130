// ForwInfoStat - 출고 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { set } from '../../../../../api/set';

const setMat = set.mes.mat;

export default function ForwInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'forw_numb';       
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [stocLoca, setStocLoca] = useState("선택하세요");    
    const [forwNumb, setForwNumb] = useState("");    
    const [matlName, setMatlName] = useState("");    
    const [stocLocaList, setStocLocaList] = useState("");   
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const stocLocaChange = useCallback((e) => { setStocLoca(e) }, []);
    const forwNumbChange = useCallback((e) => { setForwNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const stocLocaListChange = useCallback((e) => { setStocLocaList(e) }, []);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [stocResult] = await Promise.all([setMat.forw.cateList(), setMat.forw.stocLoca()]);
                if (stocResult && stocResult.length > 0) {
                    stocLocaListChange(stocResult);
                    stocLocaChange(stocResult[0]);
                }                            
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [stocLocaChange, stocLocaListChange]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, stocLoca: stocLoca, forwNumb: forwNumb, matlName: matlName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
    }, [dateValue, duraYesn, stocLoca, forwNumb, matlName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '저장위치', value: stocLoca, valueChange: stocLocaChange, search: handleSearch, items: stocLocaList },
        { type: 'text', locate: 'before', caption: '출고번호', value: forwNumb, valueChange: forwNumbChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [stocLoca, stocLocaChange, forwNumb, forwNumbChange, matlName, matlNameChange, handleSearch, stocLocaList]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}      
                toolbar={{ default: true, items: toolbarItems }}
                exp={{ disabled: authList.auth_dnlo === 0 }}     
                selection={'single'}
            >
                {CommCol({ dataField: "forw_numb", caption: "출고번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {DeciCol({ dataField: "forw_qtys", caption: "출고수량" })}
                {DeciCol({ dataField: "forw_weig", caption: "출고중량" })}
                {DeciCol({ dataField: "forw_pric", caption: "출고단가" })}
                {DeciCol({ dataField: "forw_kwon", caption: "출고금액" })}
                {DateCol({ dataField: "forw_date", caption: "출고일자" })}
                {CommCol({ dataField: "forw_memo", caption: "비고" })}              
            </PagingGrid>
        </div>
    );
};