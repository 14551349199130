import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashItemGridCard } from "./Dash-grid";
import { DashItemCharCard } from "./Dash-char";
import { fwUtil } from "../../../../script/util";
import { convChartData } from "./Dash-util";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import "./dash.scss";

export default function ItemStocDash(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/item/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const [charData, setCharData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    const charDataUpdate = useCallback((e) => setCharData(e), []);
    // 검색 조건
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { itemCode: itemCode, itemName: itemName };
        const res = await fwUtil.apis.search(searchApi, sendData, null, null, null);
        const convChart = convChartData(res);
        gridDataUpdate(res);
        charDataUpdate(convChart);
        setPageLoading(false);
    }, [itemCode, itemName, searchApi, gridDataUpdate, charDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col item-stoc">
            <FwAppLoader loading={loading} /> 
            <CommDash
                title={title}
                dashRef={dashRef}
                search={handleSearch}
                exp={{ fileName: "제품재고 대쉬보드", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <DashItemGridCard loading={pageLoading} data={gridData} />
                <DashItemCharCard loading={pageLoading} data={charData} />
            </CommDash>
        </div>
    )
}