// CodeInfoStat - 공통코드 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { CodeInfoStatMainInsert } from './InfoStat-main-insert';
import { CodeInfoStatMainUpdate } from './InfoStat-main-update';
import { CodeInfoStatSubsInsert } from './InfoStat-subs-insert';
import { CodeInfoStatSubsUpdate } from './InfoStat-subs-update';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const setCom = set.mes.com.code;

export default function CodeInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/main/search`; // 메인 조회 api
    const mainTargetApi = `${acce}/${gubu}/${cata}/${catb}/main/target`; // 메인 타겟 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/main/insert`; // 메인 등록 api
    const mainUpdateApi = `${acce}/${gubu}/${cata}/${catb}/main/update`; // 메인 수정 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/main/delete`; // 메인 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/subs/search`; // 서브 조회 api
    const subsTargetApi = `${acce}/${gubu}/${cata}/${catb}/subs/target`; // 서브 타겟 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/subs/insert`; // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/subs/update`; // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/subs/delete`; // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'main_code';               
    const subsKey = ['main_code', 'subs_code'];
    const mainDeleKey = (data) => { return { mainCode: data } };
    const subsDeleKey = (data) => { return { mainCode: data.main_code, subsCode: data.subs_code } };
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);    
    // 검색 조건
    const [codeGubu, setCodeGubu] = useState("");    
    const [gubuList, setGubuList] = useState([]);    
    const codeGubuChange = useCallback((e) => { setCodeGubu(e) }, []);
    const gubuListChange = useCallback((e) => { setGubuList(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult] = await Promise.all([setCom.gubu()]);
                if (gubuResult && gubuResult.length > 0) {
                    gubuListChange(gubuResult);
                    codeGubuChange(gubuResult[0]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuListChange, codeGubuChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { bizsGubu: codeGubu };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [codeGubu, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        const sendData = { mainCode: key };
        await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        key && subsHandleSearch(key);
    }, [subsHandleSearch]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
        if (type === 'main') {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(mainDeleteApi, mainGridRef , mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
        } else {
            const gridInstance = subsGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(subsDeleteApi, subsGridRef, subsDeleKey, subsHandleSearch);
            }
            subsDeleteAlertHide();
        }

    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, subsDeleteAlertHide, mainDeleteApi, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '사업구분', value: codeGubu, valueChange: codeGubuChange, search: mainHandleSearch, items: gubuList },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [codeGubu, codeGubuChange, mainHandleSearch, gubuList]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('상세코드를 선택 후 시도해주세요.');
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('관리코드를 선택 후 시도해주세요.');
            return;
        }

        if (type === 'main') {
            handleMainAction();
        } else if (type === 'subs') {
            handleSubsAction();
        }

    }, [mainGridRef, subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_gubu", caption: "업무분류", width: 100 })}
                {CommCol({ dataField: "main_code", caption: "관리코드", width: 100, allowFiltering: false })}
                {CommCol({ dataField: "main_name", caption: "관리코드명", allowFiltering: false })}
                {BoolCol({ dataField: "main_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "main_memo", caption: "비고", allowFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "user_idno", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_idno", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}                                                        
            </MainGrid>
            <CodeInfoStatMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <CodeInfoStatMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 관리코드를 삭제하시겠습니까?'}
            />
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "subs_code", caption: "상세코드" })}
                {CommCol({ dataField: "subs_name", caption: "상세코드명" })}
                {BoolCol({ dataField: "subs_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "subs_memo", caption: "비고" })}
                {CommCol({ dataField: "user_idno", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_idno", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}
            </SubsGrid>
            <CodeInfoStatSubsInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <CodeInfoStatSubsUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                targetApi={subsTargetApi}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={() => onDeleteClick('subs')}
                message={'해당 상세코드를 삭제하시겠습니까?'}
            />                      
        </div>
    );
};