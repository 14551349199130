import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    item_code: '',
    item_name: '',
    publ_dept: '',
    dist_dept: '',
    mana_dept: '',
    enab_yesn: true,
    rece_date: fwUtil.date.getsText.date(),
    desi_memo: '',    
}

export function DesiInfoRegiUpdate(props) {
    const {
        mainGrid, refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                item_code: seleData.item_code || '',
                item_name: seleData.item_name || '',
                publ_dept: seleData.publ_dept || '',
                dist_dept: seleData.dist_dept || '',
                mana_dept: seleData.mana_dept || '',
                enab_yesn: seleData.enab_yesn || true,
                rece_date: seleData.rece_date || fwUtil.date.getsText.date(),
                desi_memo: seleData.desi_memo || '',                    
            };

            dataUpdate(resData);            
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {      
            itemCode : data.item_code || '',
            itemName : data.item_name || '',
            publDept : data.publ_dept || '',
            distDept : data.dist_dept || '',
            manaDept : data.mana_dept || '',
            enabYesn : data.enab_yesn || false,
            receDate : data.rece_date ||fwUtil.date.getsText.date(),
            desiMemo : data.desi_memo || '',            
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>         
        <PopupForm
            title={'설계 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}                
                <GroupItem colSpan={isXSmall ? 1 : 2}>
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', disabled: true })} 
                </GroupItem>
                {FormDate({ value: data.rece_date, onValueChange: updateField('rece_date'), label: '작성일자' })}
                {FormText({ value: data.publ_dept, onValueChange: updateField('publ_dept'), label: '설계부서' })}
                {FormText({ value: data.dist_dept, onValueChange: updateField('dist_dept'), label: '배포부서' })}
                {FormText({ value: data.mana_dept, onValueChange: updateField('mana_dept'), label: '관리부서' })}
            </GroupItem>
            {FormMemo({ value: data.desi_memo, onValueChange: updateField('part_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}