import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    matl_code: '',
    matl_name: '',
    item_gubu: '',
    stoc_loca: '',
    orig_qtys: 0.00,
    adju_qtys: 0.00,
    orig_weig: 0.000,
    adju_weig: 0.000,
    orig_pric: 0.00,
    adju_pric: 0.00,
    adju_memo: '',
    adju_date: fwUtil.date.getsText.date(),    
};

const setMat = set.mes.mat;

const matlKeyExpr = ['matl_code', 'item_gubu'];

export function StocAdjuRegiInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 선택 항목
    const [stocList, setStocList] = useState([]);
    const stocListChange = useCallback((e) => setStocList(e), []);
    // 팝업 그리드
    const matlGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const [matlGubu, setMatlGubu] = useState('');
    const [matlGubuList, setMatlGubuList] = useState([]);
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const matlGubuChange = useCallback((e) => setMatlGubu(e), []);
    const matlGubuListChange = useCallback((e) => setMatlGubuList(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);

    // 팝업 조회
    const matlHandleSearch = useCallback(async () => {
        const sendData = { matlCode: matlCode, matlName: matlName, itemGubu: matlGubu };
        const res = await setMat.adju.matl.list(sendData);
        matlGridUpdate(res);
    }, [matlCode, matlName, matlGubu, matlGridUpdate]);

    // 그리드 추가 툴바 아이템
    const matlToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '제품구분', value: matlGubu, valueChange: matlGubuChange, search: matlHandleSearch, items: matlGubuList },
        { type: 'text', locate: 'before', caption: '구성품번', value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch },
        { type: 'text', locate: 'before', caption: '구성품명', value: matlName, valueChange: matlNameChange, search: matlHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: matlHandleSearch }
    ], [matlCode, matlCodeChange, matlName, matlNameChange, matlGubu, matlGubuChange, matlGubuList, matlHandleSearch]);

    useEffect(() => {
        matlGubu && matlHandleSearch();
    }, [matlGubu, matlHandleSearch]);

    // 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    // 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'matl_code': e.data.matl_code, 'matl_name': e.data.matl_name, 'item_gubu': e.data.item_gubu });
        matlPopupHiding();
    }, [data, dataUpdate, matlPopupHiding]);

    const fetchSet = useCallback(async () => {
        try {
            const [stocResult, gubuResult] = await Promise.all([setMat.adju.stoc(), setMat.adju.matl.gubu()]);
            stocListChange(stocResult);
            if (gubuResult && gubuResult.length > 0) {
                matlGubuListChange(gubuResult);
                matlGubuChange(gubuResult[0]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro()
            hiding();
        }
    }, [stocListChange, matlGubuChange, matlGubuListChange, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchSet();
        dataUpdate(defaultData);
    }, [fetchSet, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            itemGubu: data.item_gubu || '',
            stocLoca: data.stoc_loca || '',
            origQtys: data.orig_qtys || 0.00,
            adjuQtys: data.adju_qtys || 0.00,
            origWeig: data.orig_weig || 0.000,
            adjuWeig: data.adju_weig || 0.000,
            origPric: data.orig_pric || 0.00,
            adjuPric: data.adju_pric || 0.00,
            adjuMemo: data.adju_memo || '',
            adjuDate: data.adju_date || fwUtil.date.getsText.date(),
        }

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    const matlGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'자재'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, items: matlToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "품번" })}
                {CommCol({ dataField: "matl_name", caption: "품명" })}
                {CommCol({ dataField: "item_gubu", caption: "구분" })}
            </ScrollGrid>
        )
    }, [matlData, matlGridRef, matlOnRowDblClick, matlToolbarItems, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'자재번호 설정'}
                visible={matlPopupVisible}
                shown={matlPopupOnShowing}
                hiding={matlPopupHiding}
            >
                {matlGrid}
            </PopupGrid>
            <PopupForm
                title={'재고조정'}
                type={'조정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall ? '100%' : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormGrid({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '자재번호', onClick: () => setMatlPopupVisible(true), required: true })}
                    {FormSelc({ value: data.stoc_loca, onValueChange: updateField('stoc_loca'), label: '저장위치(야적장)', items: stocList, required: true })}
                    {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '자재명', disabled: true })}
                    {FormText({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '품목분류', disabled: true })}
                    {FormNumb({ value: data.orig_qtys, onValueChange: updateField('orig_qtys'), label: '조정전수량', length: [12, 2] })}
                    {FormNumb({ value: data.adju_qtys, onValueChange: updateField('adju_qtys'), label: '조정후수량', length: [12, 2] })}
                    {data.item_gubu !== '완제품' && FormNumb({ value: data.orig_weig, onValueChange: updateField('orig_weig'), label: '조정전중량', length: [12, 3] })}
                    {data.item_gubu !== '완제품' && FormNumb({ value: data.adju_weig, onValueChange: updateField('adju_weig'), label: '조정후중량', length: [12, 3] })}
                    {FormNumb({ value: data.orig_pric, onValueChange: updateField('orig_pric'), label: '조정전단가', length: [15, 2] })}
                    {FormNumb({ value: data.adju_pric, onValueChange: updateField('adju_pric'), label: '조정후단가', length: [15, 2] })}
                    {FormText({ value: data.adju_memo, onValueChange: updateField('adju_memo'), label: '조정사유' })}
                    {FormDate({ value: data.adju_date, onValueChange: updateField('adju_date'), label: '조정일자' })}
                </GroupItem>
            </PopupForm>
        </>
    );
}