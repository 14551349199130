// CalibInfoHist - 검교정 이력
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function CalibInfoHist(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/report/header/search`;  // 메인 조회 api
    const mainReportApi = `${acce}/${gubu}/${cata}/${catb}/report/creat/pdf`;      // 메인 보고서 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/report/detail/search`;  // 서브 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'main_numb';
    const subsKey = ['main_numb', 'cali_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 관리대장 출력 팝업
    const [reportPopupVisible, setReportPopupVisible] = useState(false);
    const reportPopupHide = useCallback(() => setReportPopupVisible(false), []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { mainNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    const onReportClick = useCallback(() => {
        const selectedRow = fwUtil.grid.get.sKey(mainGridRef);
        if (selectedRow) {
            setReportPopupVisible(true);
        } else {
            fwUtil.aler.toast.nsWarn();
        }

    }, []);   

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '설비명', value: equiName, valueChange: equiNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
        { type: 'btns', locate: 'after', icon: 'textdocument', text: '관리대장', onClick: onReportClick, disabled: authList.auth_dnlo === 0 },
    ], [equiName, equiNameChange, onReportClick, authList, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />            
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}  
                exp={{ disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: true, items: toolbarItems }} 
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "equi_spec", caption: "규격 및 사양" })}
                {CommCol({ dataField: "menu_comp", caption: "제작회사" })}
                {DateCol({ dataField: "reci_date", caption: "설치일자" })}
                {CommCol({ dataField: "uses_dept", caption: "사용부서" })}
                {CommCol({ dataField: "main_name", caption: "담당자(정)" })}
                {CommCol({ dataField: "subs_name", caption: "담당자(부)" })}
                {CommCol({ dataField: "equi_memo", caption: "비고" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "cali_seri", caption: "검교정순번" })}
                {DateCol({ dataField: "cali_date", caption: "교정일자" })}
                {CommCol({ dataField: "clie_code", caption: "교정기관코드" })}
                {CommCol({ dataField: "clie_name", caption: "교정기관명" })}
                {CommCol({ dataField: "cali_numb", caption: "교정번호" })}
                {DateCol({ dataField: "cali_ndat", caption: "차기교정일" })}
                {CommCol({ dataField: "cali_desc", caption: "교정내용" })}
                {CommCol({ dataField: "cali_memo", caption: "비고" })}
            </SubsGrid>                     
        </div>
    );
};