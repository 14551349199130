import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    matl_code: '',
    matl_name: '',
    puro_unit: '',
    stoc_unit: '',
    safe_qtys: '',
    enab_yesn: true,
    regi_date: fwUtil.date.getsText.date(),
    unit_weig: '',
    qtys_weig: '',
    matl_memo: '',
};

export function MatlInfoCreaUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { matlCode: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                matl_code: res.matl_code || '',
                matl_name: res.matl_name || '',
                matl_spec: res.matl_spec || '',
                matl_leng: res.matl_leng || 0,
                matl_quli: res.matl_quli || '',
                puro_unit: res.puro_unit || '',
                stoc_unit: res.stoc_unit || '',
                matl_gubu: res.matl_gubu || '',
                safe_qtys: res.safe_qtys || 0.000,
                unit_weig: res.unit_weig || 0.000,
                qtys_weig: res.qtys_weig || 0.000,
                regi_date: res.regi_date || null,
                enab_yesn: res.enab_yesn === 1 ? true : false,
                matl_memo: res.matl_memo || '',
            };

            dataUpdate(resData);
        }
    }, [dataUpdate, hiding, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            cataCode: '',
            catbCode: '',
            catcCode: '',
            catdCode: '',
            cataName: '',
            catbName: '',
            catcName: '',
            catdName: '',
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            puroUnit: data.puro_unit || '',
            stocUnit: data.stoc_unit || '',
            safeQtys: data.safe_qtys || 0.000,
            unitWeig: data.unit_weig || 0.000,
            qtysWeig: data.qtys_weig || 0.000,
            enabYesn: data.enab_yesn || false,
            regiDate: data.regi_date || null,
            matlMemo: data.matl_memo || ''
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'자재 생성'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            shown={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '자재코드', required: true, disabled: true })}                                                
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '자재명', required: true, disabled: true })}                                                                                              
                {FormText({ value: data.puro_unit, onValueChange: updateField('puro_unit'), label: '구매단위', required: true })}
                {FormText({ value: data.stoc_unit, onValueChange: updateField('stoc_unit'), label: '저장단위', required: true })}
                {FormNumb({ value: data.unit_weig, onValueChange: updateField('unit_weig'), label: '단위중량(m당)', length: [15, 3] })}
                {FormNumb({ value: data.qtys_weig, onValueChange: updateField('qtys_weig'), label: '단위중량(개당)', length: [15, 3] })}
                {FormNumb({ value: data.safe_qtys, onValueChange: updateField('safe_qtys'), label: '안전재고', length: [15, 3] })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자', required: true })}                
            </GroupItem>
            {FormMemo({ value: data.matl_memo, onValueChange: updateField('matl_memo'), label: '비고' })}
        </PopupForm>        
    );
}