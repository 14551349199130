import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/item/register/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getItemGubu.error === ', error);
        return [];
    }
};

const getItemList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getItemList.error === ', error);
        return [];
    }
};

export const tec = {
    item: {
        gubu: getItemGubu,
        list: getItemList
    },
}