import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashItemCard } from "./Dash-item";
import { DashProdCard } from "./Dash-prod";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import "./dash.scss";

const yearList = fwUtil.date.getsList.year(3, -3);

export default function ProdInfoDash(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/header/search`;  // 상단 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/detail/search`;  // 하단 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [mainDashData, setMainDashData] = useState([]);
    const [subsDashData, setSUbsDashData] = useState([]);
    const mainDashDataUpdate = useCallback((e) => setMainDashData(e), []);
    const subsDashDataUpdate = useCallback((e) => setSUbsDashData(e), []);
    // 검색 조건
    const [yplaYear, setYplaYear] = useState(fwUtil.date.getsText.year);  
    const [itemName, setItemName] = useState("");
    const yplaYearChange = useCallback((e) => { setYplaYear(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { yplaYear: yplaYear, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainDashDataUpdate);
        await fwUtil.apis.search(subsSearchApi, sendData, subsDashDataUpdate);
        setPageLoading(false);
    }, [yplaYear, itemName, mainSearchApi, subsSearchApi, mainDashDataUpdate, subsDashDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '생산년도', value: yplaYear, valueChange: yplaYearChange, search: handleSearch, items: yearList },        
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
    ], [yplaYear, itemName, yplaYearChange, itemNameChange, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col prod">
            <FwAppLoader loading={loading} />                  
            <CommDash
                title={title}
                dashRef={dashRef}                
                search={handleSearch}                
                exp={{ fileName: "생산현황 대쉬보드", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <DashItemCard loading={pageLoading} data={mainDashData} />
                <DashProdCard loading={pageLoading} data={subsDashData} />
            </CommDash>
        </div>
    )
}