// MproWareNist - 미입고 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { fwUtil } from '../../../../script/util';

export default function MproWareNist(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/notin/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'mpro_numb';    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [prosNumb, setProsNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [workNumb, setWorkNumb] = useState("");
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { workNumb: workNumb, prosNumb: prosNumb, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [workNumb, prosNumb, clieName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "지시번호", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(gridRef, key, isSelected);
    }, [gridRef]);

    

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "mpro_numb", caption: "발주번호" })} 
                {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })} 
                {DateCol({ dataField: "mpro_date", caption: "발주일자" })} 
                {CommCol({ dataField: "mdel_date", caption: "납품요구일" })} 
                {DeciCol({ dataField: "mpro_qtys", caption: "발주수량" })} 
                {DeciCol({ dataField: "rece_qtys", caption: "입고수량" })} 
                {DeciCol({ dataField: "nwar_qtys", caption: "미입고량", check: true })} 
                {CommCol({ dataField: "damd_name", caption: "발주담당" })} 
                {CommCol({ dataField: "reqe_plac", caption: "수요처" })} 
                {CommCol({ dataField: "mpro_remk", caption: "참고사항" })} 
                {CommCol({ dataField: "mpro_stat", caption: "진행상태" })} 
            </PagingGrid>        
        </div>
    );
};