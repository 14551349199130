// ShipQcisChan - 출하검사 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ShipQcisChanUpdate } from './QcisChan-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempMain = [
    {
        "insp_numb": "202308001",
        "ship_numb": "SH202308001",
        "clie_code": "HS1102",
        "clie_name": "영풍전자",
        "matl_code": "MTL4592",
        "matl_name": "강철 프레임",
        "insp_date": "2023-08-01",
        "lots_qtys": 500,
        "insp_memo": "정상"
    },
    {
        "insp_numb": "202308002",
        "ship_numb": "SH202308002",
        "clie_code": "SS2203",
        "clie_name": "영동연합",
        "matl_code": "MTL7843",
        "matl_name": "알루미늄 하우징",
        "insp_date": "2023-08-02",
        "lots_qtys": 200,
        "insp_memo": "경미한 결함"
    },
]

const tempSubs = [
    {
        "insp_numb": "202308001",
        "insp_seri": 1,
        "insp_cate": "치수 검사",
        "insp_spec": "100mm ± 0.5mm",
        "insp_math": "캘리퍼스 측정",
        "insp_resu": "정상",
        "item_remk": "무결함",
        "work_name": "김철수",
        "item_memo": ""
    },
    {
        "insp_numb": "202308001",
        "insp_seri": 2,
        "insp_cate": "강도 검사",
        "insp_spec": "1500MPa 이상",
        "insp_math": "압축 시험기",
        "insp_resu": "정상",
        "item_remk": "무결함",
        "work_name": "이영희",
        "item_memo": ""
    },
]

export default function ShipQcisChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/header/search`;   // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/search`;   // 서브 조회 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/change/inspect/update`;  // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/change/inspect/delete`;  // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'insp_numb';
    const subsKey = ['insp_numb', 'insp_seri'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [shipNumb, setShipNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);    
    const shipNumbChange = useCallback((e) => { setShipNumb(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 수정 팝업창
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, shipNumb: shipNumb };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
        mainGridDataUpdate(tempMain);
    }, [dateValue, duraYesn, shipNumb, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { inspNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
            subsGridRef?.current?.instance?.deselectAll();
        }
        subsGridDataUpdate(tempSubs);
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const mainGridInstance = mainGridRef?.current?.instance;
        const subsGridInstance = subsGridRef?.current?.instance;
        if (mainGridInstance && subsGridInstance) {
            mainGridInstance.beginCustomLoading();
            subsGridInstance.beginCustomLoading();
            const MainKey = fwUtil.grid.get.sKey(mainGridRef);
            const selectedSubsData = subsGridInstance.getSelectedRowsData() || [];
            const convertdData = selectedSubsData.map((item) => ({
                inspSeri: item.insp_seri || '',
            }));
            const postData = { inspNumb: MainKey, setaMaps: convertdData }
            await fwUtil.apis.submit(subsDeleteApi, postData, subsHandleSearch);
            mainGridInstance.current.instance.endCustomLoading();
            subsGridInstance.current.instance.endCustomLoading();
            mainHandleSearch();
            subsDeleteAlertHide();
        }
    }, [subsGridRef, subsHandleSearch, subsDeleteAlertHide, mainHandleSearch, subsDeleteApi]);    

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '출하번호', value: shipNumb, valueChange: shipNumbChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [shipNumb, shipNumbChange, mainHandleSearch]);

    // 수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleSubsAction = () => {
            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('상세 데이터를 선택 후 시도해주세요.');
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('메인 데이터를 선택 후 시도해주세요.');
            return;
        }

        handleSubsAction();

    }, [mainGridRef, subsGridRef]);
    

    

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}      
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}            
                onRowClick={mainGridClick}           
                search={mainHandleSearch}
                exp={{ disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "matl_code", caption: "제품번호" })}
                {CommCol({ dataField: "matl_name", caption: "제품명" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {DeciCol({ dataField: "lots_qtys", caption: "검사수량" })}
                {CommCol({ dataField: "insp_memo", caption: "비고" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}                
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "insp_seri", caption: "순번" })}
                {CommCol({ dataField: "insp_cate", caption: "검사항목" })}
                {CommCol({ dataField: "insp_spec", caption: "기준(규격)" })}
                {CommCol({ dataField: "insp_math", caption: "검사방법" })}
                {CommCol({ dataField: "insp_resu", caption: "검사결과" })}
                {CommCol({ dataField: "item_remk", caption: "특기사항" })}
                {CommCol({ dataField: "work_idno", caption: "작업자ID" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}
            </SubsGrid>         
            <ShipQcisChanUpdate
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                refresh={subsHandleSearch}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}         
            />             
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />              
        </div>
    );
};