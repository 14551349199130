import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

export function DashMatlCharCard(props) {
    const { loading, data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
        };
    };

    return (
        <DashCard
            title={"재적 현황"}
            addClass={'matl-char'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                palette={"Soft Blue"}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommAxis({ name: "pric", caption: "금액(원)", position: "left", start: 0 })}
                {CommAxis({ name: "qtys", caption: "수량(건)", position: "right", start: 0 })}
                {BarsSeri({ axis: "qtys", valueField: "stoc_qtys", caption: "총 재고량", args: "stoc_loca" })}
                {SpinSeri({ axis: "pric", valueField: "stoc_kwon", caption: "총 금액", args: "stoc_loca" })}
            </CommChart>
        </DashCard>
    )
}