import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getAserClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/equ/maint/register/vendor/list`; // 임시 api
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getAserItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/bomitem/list`; // 임시 api
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getAserItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/alist`; // 임시 api
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getCompItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/bomitem/list`; // 임시 api
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getCompItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/alist`; // 임시 api
        const data = await fwUtil.apis.search(api);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPartMatl = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/client/vehicle/matlcode`; // 임시 api
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPartItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/bomitem/list`; // 임시 api
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPartMatlGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/alist`; // 임시 api
        const data = await fwUtil.apis.search(api);
        return data;               
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getPartItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/alist`; // 임시 api
        const data = await fwUtil.apis.search(api);
        return data;        
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const biz = {
    aser: {
        info: {
            clie: getAserClie,
            item: {
                info: getAserItem,
                gubu: getAserItemGubu,
            }
        },
        comp: {
            item: {
                info: getCompItem,
                gubu: getCompItemGubu,
            }
        },
        part: {
            matl: {
                info: getPartMatl,
                gubu: getPartMatlGubu,
            },
            item: {
                info: getPartItem,
                gubu: getPartItemGubu,
            }
        },
    },
}