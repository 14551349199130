import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getPlanClie = async (sendData) => {
    try {
        // 현재 수주 쪽 고객 정보 조회 API 사용 중입니다.
        const api = `privat${mesGubu}/sys/set/order/client/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPlanClie.error === ', error);
        return [];
    }
};

const getOrdeItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/mfg/work/item/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);

        return data;
    }
    catch (error) {
        console.log('getOrdeItem.error === ', error);
        return [];
    }
};

const getProdRoutList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/mfg/prod/register/routnumb/list`;
        const data = await fwUtil.apis.search(api, sendData);

        return data;
    }
    catch (error) {
        console.log('getProdRoutList.error === ', error);
        return [];
    }
};

const getProdEquiList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/mfg/prod/register/equinumb/list`;
        const data = await fwUtil.apis.search(api, sendData);
        
        return data;
    }
    catch (error) {
        console.log('getProdEquiList.error === ', error);
        return [];
    }
};

const getProdNameList = async () => {
    try {
        //const api = `privat${mesGubu}/undefined`;
        //const data = await fwUtil.apis.search(api);
        const data = ["홍길동 [CS120001]", "김철수 [CS120002]", "차연정 [CS120003]", "반기문 [CS120004]",];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getYardItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/mfg/yard/status/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        return data;
    }
    catch (error) {
        console.log('getYardItemGubu.error === ', error);
        return [];
    }
};

const getProdStocLoca = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/stock/adjustment/stocloca/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getProdStocLoca.error === ', error);
        return [];
    }
};

export const mfg = {
    work: {
        clie: getPlanClie,
        item: getOrdeItem,
    },
    prod: {
        rout: getProdRoutList,
        equi: getProdEquiList,
        name: getProdNameList,
        stoc: getProdStocLoca,
    },
    yard: {
        item: {
            gubu: getYardItemGubu,
        } 
    },
}