import YardMapImg from '../../../../assets/yard/yard-map.png'
import YardHomeMarker from '../../../../assets/yard/yard-marker-home.png'
import YardFactoryMarker from '../../../../assets/yard/yard-marker-factory.png'
import YardAMarker from '../../../../assets/yard/yard-marker-a.png'
import YardBMarker from '../../../../assets/yard/yard-marker-b.png'
import YardCMarker from '../../../../assets/yard/yard-marker-c.png'
import YardDMarker from '../../../../assets/yard/yard-marker-d.png'
import YardEMarker from '../../../../assets/yard/yard-marker-e.png'

export function YardInfoStatMap({ setLoading }) {

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <div className="yard-map fw-flex-box col a-center">
            <div className="yard-map-container">
                <img className="yard-map-img" src={YardMapImg} alt="세기개발 전경도" onLoad={handleImageLoad} />            
                <img className="yard-map-ico home" src={YardHomeMarker} alt="세기개발 사무실" />            
                <img className="yard-map-ico factory" src={YardFactoryMarker} alt="세기개발 작업장" />         
                <img className="yard-map-ico yard-a" src={YardAMarker} alt="야적장 A" />
                <img className="yard-map-ico yard-b" src={YardBMarker} alt="야적장 B" />         
                <img className="yard-map-ico yard-c" src={YardCMarker} alt="야적장 C" />         
                <img className="yard-map-ico yard-d" src={YardDMarker} alt="야적장 D" />         
                <img className="yard-map-ico yard-e" src={YardEMarker} alt="야적장 E" />         
            </div>
        </div>
    )
}