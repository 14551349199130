// PursInfoRegi - 요청 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as InsertAlert } from '../../../../script/components/popup/popup';
import { PursInfoRegiSelect } from './InfoRegi-select';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PursInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/register/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/register/insert`;  // 등록 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/register/delete`;  // 삭제 api
    const selectApi = `${acce}/${gubu}/${cata}/${catb}/select/search`;    // 요청 품목 조회 api
    const selinsApi = `${acce}/${gubu}/${cata}/${catb}/select/insert`;    // 요청 품목 등록 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['matl_code', 'matl_seri'];    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [matlName, setMatlName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    // 등록 알림창
    const [insertAlertVisible, setInsertAlertVisible] = useState(false);
    const insertAlertHide = useCallback(() => { setInsertAlertVisible(false); }, []);
    // 삭제 알림창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);    
    // 요청 품목 팝업창
    const [selectPopupVisible, setSelectPopupVisible] = useState(false);
    const selectPopupHide = useCallback(() => { setSelectPopupVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, matlName: matlName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, matlName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 등록 경고 확인 클릭
    const onInsertClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            matlCode: item.matl_code || '',
            matlSeri: item.matl_seri || '',
            itemGubu: item.item_gubu || '',
            stocQtys: item.stoc_qtys || 0.000,
            requQtys: item.requ_qtys || 0.000,
            prosUnit: item.pros_unit || '',
            prosPric: item.pros_pric || 0.00,
            recePlac: item.rece_plac || '',
            deliDate: item.deli_date || null,
            pursMemo: item.purs_memo || '',
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(insertApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        insertAlertHide();
    }, [gridRef, handleSearch, insertAlertHide, insertApi]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            matlCode: item.matl_code || '',
            matlSeri: item.matl_seri || '',
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(deleteApi, postData , handleSearch);
        gridRef.current.instance.endCustomLoading();
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 등록/삭제 버튼 클릭
    const handleActionClick = useCallback(async (type) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);
        if (selectedRowKey) {
            if (type === 'ins') {
                setInsertAlertVisible(true);
                return;
            }
            if (type === 'del') {
                setDeleteAlertVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'add', text: '자재추가', hidden: false, disabled: authList.auth_inse === 0, onClick: () => setSelectPopupVisible(true) },
        { type: 'btns', locate: 'after', icon: 'upload', text: '등록', hidden: false, disabled: authList.auth_inse === 0, onClick: () => handleActionClick('ins') },
    ], [matlName, matlNameChange, handleSearch, authList, setSelectPopupVisible, handleActionClick]);
    
    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                {CommCol({ dataField: "matl_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재명", allowEditing: false })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량", allowEditing: false })}
                {DeciCol({ dataField: "requ_qtys", caption: "요청수량", check: true, maxLength: 15 })}
                {CommCol({ dataField: "pros_unit", caption: "발주단위", check: true })}
                {CommCol({ dataField: "rece_plac", caption: "입고처", check: true })}
                {CommCol({ dataField: "purs_memo", caption: "비고", check: true })}
            </PagingGrid>
            <PursInfoRegiSelect
                refresh={handleSearch}
                selectApi={selectApi}
                insertApi={selinsApi}
                visible={selectPopupVisible}
                hiding={selectPopupHide}
            />  
            <InsertAlert 
                grid={gridRef}
                visible={insertAlertVisible}
                hiding={insertAlertHide}
                confirm={onInsertClick}
                message={'해당 요청를 등록하시겠습니까?'}
            />  
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 요청을 삭제하시겠습니까?'}
            />                       
        </div>
    );
};