// OrdeInfoUplo - 수주 업로드
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { ScrollGrid as MainGrid, ScrollGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as InsertAlert } from '../../../../script/components/popup/popup';
import { OrdeInfoUploUpload } from './InfoUplo-upload';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const gubuList = ['신규', '변경', '전체'];      
const contentHeight = fwUtil.height.window - fwUtil.height.header - fwUtil.height.footer - 0;
const mainGridHeight = contentHeight * 0.6;
const subsGridHeight = contentHeight * 0.4;

export default function OrdeInfoUplo(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/upload/header/search`;   // 메인 조회 api
    const mainUploadApi = `${acce}/${gubu}/${cata}/${catb}/upload/excel/datasave`;  // 메인 업로드 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/upload/header/insert`;   // 메인 등록 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/upload/header/delete`;   // 메인 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/upload/detail/search`;   // 서브 조회 api   
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainKey = ['seri_numb', 'orde_numb'];
    const subsKey = 'orde_numb';    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [chckGubu, setChckGubu] = useState("전체");      
    const chckGubuChange = useCallback((e) => { setChckGubu(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertAlertVisible, setMainInsertAlertVisible] = useState(false);
    const mainInsertAlertHide = useCallback(() => { setMainInsertAlertVisible(false); }, []);
    // 업로드 팝업창
    const [mainUploadPopupVisible, setMainUploadPopupVisible] = useState(false);
    const mainUploadPopupHide = useCallback(() => { setMainUploadPopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { chckGubu: chckGubu };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [chckGubu, mainGridDataUpdate, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { ordeNumb: key.orde_numb };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 등록/삭제 경고 확인 클릭
    const onConfirmClick = useCallback(async (api) => {
        const selectedRowKeys = mainGridRef?.current?.instance?.getSelectedRowKeys();
        if (selectedRowKeys) {
            mainGridRef.current.instance.deselectAll();
            const convertedData = await selectedRowKeys.map((item)=> ({
                seriNumb: item.seri_numb
            }))
            const sendData = { setaMaps: convertedData }
            await fwUtil.apis.submit(api, sendData, mainHandleSearch);
            subsHandleSearch();
        } else {
            fwUtil.aler.toast.nsWarn();
        }

        mainDeleteAlertHide();
        mainInsertAlertHide();

    }, [mainGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, mainInsertAlertHide]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '구분', value: chckGubu, valueChange: chckGubuChange, search: mainHandleSearch, items: gubuList },
        { type: 'btns', locate: 'after', text: '업로드', icon: 'upload', onClick: () => setMainUploadPopupVisible(true), disabled: authList.auth_upda === 0 },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [chckGubu, chckGubuChange, mainHandleSearch, setMainUploadPopupVisible, authList]);
    
    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'ins':
                    setMainInsertAlertVisible(true);
                    break;                
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.nsWarn();
            return;
        }
        handleMainAction();
    }, [mainGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: false, items: toolbarItems }}
                selection={'multiple'}
                height={mainGridHeight}
            >
                {DeciCol({ dataField: "seri_numb", caption: "일련번호", allowResizing: false, width: 1 })}
                {CommCol({ dataField: "chck_gubu", caption: "구분", width: 80 })}
                {CommCol({ dataField: "chck_remk", caption: "변경내역", width: 105 })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호", width: 105 })}
                {CommCol({ dataField: "item_code", caption: "제품번호", width: 105 })}
                {CommCol({ dataField: "cite_code", caption: "고객품목번호", width: 130 })}
                {CommCol({ dataField: "cite_name", caption: "고객제품명", width: 115 })}
                {CommCol({ dataField: "cite_spec", caption: "교객품목규격", width: 130 })}
                {CommCol({ dataField: "clie_orde", caption: "고객발주번호", width: 130 })}
                {DateCol({ dataField: "deli_date", caption: "납기일자", width: 105 })}
                {DeciCol({ dataField: "orde_qtys", caption: "발주수량", width: 105, excel: true })}
                {CommCol({ dataField: "orde_unit", caption: "발주단위", width: 105 })}
                {CommCol({ dataField: "clie_code", caption: "고객코드", allowResizing: false, width: 1 })}
                {CommCol({ dataField: "clie_name", caption: "고객사명", width: 105 })}                                                                                   
            </MainGrid>
            <OrdeInfoUploUpload
                refresh={mainHandleSearch}
                insertApi={mainUploadApi}
                visible={mainUploadPopupVisible}
                hiding={mainUploadPopupHide}
            />  
            <InsertAlert
                visible={mainInsertAlertVisible}
                hiding={mainInsertAlertHide}
                confirm={() => onConfirmClick(mainInsertApi)}
                message={'선택된 항목을 등록하시겠습니까?'}
            />                      
            <DeleteAlert
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onConfirmClick(mainDeleteApi)}
                message={'선택된 항목을 삭제하시겠습니까?'}
            />   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                toolbar={{ default: false }}
                selection={'single'}
                height={subsGridHeight}
            >
                    {CommCol({ dataField: "orde_numb", caption: "수주번호" })} 
                    {CommCol({ dataField: "clie_code", caption: "고객코드" })}                           
                    {CommCol({ dataField: "clie_name", caption: "고객명" })}                                                        
                    {CommCol({ dataField: "cite_code", caption: "고객품목번호" })}                     
                    {CommCol({ dataField: "cite_name", caption: "고객품명" })}                           
                    {CommCol({ dataField: "cite_spec", caption: "고객규격" })}       
                    {CommCol({ dataField: "clie_orde", caption: "오더번호" })}                           
                    {CommCol({ dataField: "orde_line", caption: "라인번호" })}
                    {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                    {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                    {DeciCol({ dataField: "orde_qtys", caption: "수주수량" })}
                    {DeciCol({ dataField: "orde_pric", caption: "수주단가" })}                           
                    {DeciCol({ dataField: "orde_kwon", caption: "수주금액" })}
                    {DeciCol({ dataField: "deli_qtys", caption: "납품수량" })}
                    {CommCol({ dataField: "orde_memo", caption: "비고" })}
                    {BoolCol({ dataField: "deli_yesn", caption: "납품완료유무" })}   
            </SubsGrid>                          
        </div>
    );
};