import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashItemCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"제품별 현황"}
            addClass={'item'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={"item_code"}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "plan_qtys", caption: "계획수량" })}                
                {DeciCol({ dataField: "year_qtys", caption: "생산수량", check: true })}
                {DeciCol({ dataField: "plan_kwon", caption: "계획매출", suffix: "원" })}                
                {DeciCol({ dataField: "item_kwon", caption: "생산금액", suffix: "원", check: true })}
            </ScrollGrid>
        </DashCard>
    )
}